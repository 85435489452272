var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { map } from 'rxjs/operators';
import { Group } from '../../groups/models/group';
import { User } from '../../users/models/user';
import { ApiService } from '../../api/services/api.service';
import { API_URL } from '../../../app.constants';
import { HttpService } from '../../api/services/http.service';
const till = {
    firstName: 'Till',
    lastName: ' Lindemann',
    id: 'till',
};
const james = {
    firstName: 'James',
    lastName: ' Hetfield',
    id: 'james',
};
export class GroupsService {
    constructor(apiService, httpService) {
        this.apiService = apiService;
        this.httpService = httpService;
    }
    get url() {
        return `${API_URL}/1.0/groups`;
    }
    getGroups() {
        return this.apiService.get(`${this.url}`).pipe(map(r => this.mapDataGroup(r)));
    }
    getUsers(id) {
        return this.apiService.get(`${this.url}/${id}/users`).pipe(map(this.mapDataUser));
    }
    getGroup(id) {
        return this.apiService.get(`${this.url}/${id}`).pipe(map(r => this.mapDataGroup(r)), map(list => list.pop()));
    }
    getResponseGroups(group_id) {
        return this.apiService.get(`${this.url}/${group_id}/response`).pipe(map(r => this.sortResponseGroups(this.mapResponeGroup(r))));
    }
    getResponseGroup(group_id, response_group_id) {
        return this.apiService.get(`${this.url}/${group_id}/response/${response_group_id}`).pipe(map(r => this.mapResponeGroup(r)[0]));
    }
    searchGroups(query) {
        return this.apiService.get(`${this.url}?query=${query}`).pipe(map(r => this.mapDataGroup(r)));
    }
    orderResponseGroups(group_id, responsGroups) {
        return __awaiter(this, void 0, void 0, function* () {
            const idList = responsGroups.map(group => group.id).join(',');
            yield this.httpService.post(`${this.url}/${group_id}/response-order?order=${idList}`).toPromise();
            return this.getResponseGroupsAndMembers(group_id);
        });
    }
    removeResponseGroupUser(responsGroups, groupId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.removeUserFromAllResponseGroups(groupId, responsGroups, userId);
            return this.getResponseGroupsAndMembers(groupId);
        });
    }
    addResponseGroupUser(responsGroups, groupId, responseGroupId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.removeUserFromAllResponseGroups(groupId, responsGroups, userId);
            yield this.addResponseGroupMember(groupId, responseGroupId, userId);
            return this.getResponseGroupsAndMembers(groupId);
        });
    }
    removeUserFromAllResponseGroups(groupId, responsGroups, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userGroups = responsGroups
                .filter(responseGroup => responseGroup.members.find(member => member.id === userId) != null);
            for (let i = 0; i < userGroups.length; i++) {
                const group = userGroups[i];
                yield this.removeResponseGroupMember(groupId, group.id, userId);
            }
        });
    }
    getResponseGroupsAndMembers(group_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const responseGroups = yield this.getResponseGroups(group_id).toPromise();
            if (responseGroups.length > 0) {
                const memberDictionary = yield this.getReponseMembers(group_id, responseGroups.map(r => r.id));
                responseGroups.forEach(responseGroup => {
                    responseGroup.members = memberDictionary[responseGroup.id] || [];
                });
            }
            return this.sortResponseGroups(responseGroups);
        });
    }
    getReponseMembers(group_id, response_group_ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const idList = response_group_ids.join(',');
            const groupMembers = yield this.httpService.get(`${this.url}/${group_id}/response-members?response_ids=${idList}`).pipe(map(response => this.mapResponseGroupsMembersDictionary(response)))
                .toPromise();
            return groupMembers;
        });
    }
    createResponseGroup(group_id, response_group, groups = null) {
        return __awaiter(this, void 0, void 0, function* () {
            const fields = {
                name: response_group.name,
                delay: response_group.delay,
                order: response_group.escalationOrder
            };
            const response = yield this.httpService.post(`${this.url}/${group_id}/response`, fields).pipe(map(r => this.mapResponeGroup(r['data'])))
                .toPromise();
            if (groups) {
                const newGroup = response[0];
                groups.push(newGroup);
                return this.sortResponseGroups(groups);
            }
            else {
                return yield this.getResponseGroupsAndMembers(group_id);
            }
        });
    }
    updateResponseGroup(group_id, response_group) {
        return __awaiter(this, void 0, void 0, function* () {
            const fields = {
                name: response_group.name,
                delay: response_group.delay,
                order: response_group.escalationOrder
            };
            yield this.httpService.patch(`${this.url}/${group_id}/response/${response_group.id}`, fields)
                .toPromise();
            return this.getResponseGroupsAndMembers(group_id);
        });
    }
    deleteResponseGroup(group_id, response_group_id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.httpService.delete(`${this.url}/${group_id}/response/${response_group_id}`)
                .toPromise();
            return this.getResponseGroupsAndMembers(group_id);
        });
    }
    addResponseGroupMember(group_id, response_group_id, user_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService
                .post(`${this.url}/${group_id}/response/${response_group_id}/members`, { user_id })
                .toPromise();
        });
    }
    removeResponseGroupMember(group_id, response_group_id, user_id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService
                .delete(`${this.url}/${group_id}/response/${response_group_id}/members/${user_id}`)
                .toPromise();
        });
    }
    createGroup(group) {
        return this.apiService.post(`${this.url}`, group.toParamObject()).pipe(map(r => this.mapDataGroup(r)));
    }
    addUser(group_id, user_id, user_type, level) {
        return this.apiService.post(`${this.url}/${group_id}/users`, { user_id, user_type, level });
    }
    updateGroup(group) {
        return this.apiService.patch(`${this.url}/${group.id}`, group.toParamObject()).pipe(map(r => this.mapDataGroup(r)));
    }
    deleteUser(group_id, user_id) {
        return this.apiService.delete(`${this.url}/${group_id}/users/${user_id}`);
    }
    deleteGroup(id) {
        return this.apiService.delete(`${this.url}/${id}`).pipe(map(r => this.mapDataGroup(r)));
    }
    mapResponeGroup(data) {
        const groups = [];
        if (data.responsegroups && data.responsegroups instanceof Array) {
            return data.responsegroups.map(r => ({
                id: r.Id,
                name: r.Name,
                delay: r.Delay,
                escalationOrder: r.EscalationOrder,
                members: this.mapDataUser({ users: r.Users })
            }));
        }
        else if (data.groups) {
            return [{
                    id: data.responsegroups.Id,
                    name: data.responsegroups.Name,
                    delay: data.responsegroups.Delay,
                    escalationOrder: data.responsegroups.EscalationOrder,
                    members: this.mapDataUser({ users: data.Users })
                }];
        }
        else {
            return [];
        }
    }
    mapDataGroup(data) {
        if (data.groups && data.groups instanceof Array) {
            return data.groups.map(obj => new Group().fromJson(obj));
        }
        else if (data.groups) {
            return [new Group().fromJson(data.groups)];
        }
        else {
            return [];
        }
    }
    mapDataUser(data) {
        if (data.users && data.users instanceof Array) {
            return data.users.map(obj => new User().fromJson(obj));
        }
        else if (data.users) {
            return [new User().fromJson(data.users)];
        }
        else {
            return [];
        }
    }
    mapResponseGroupsMembersDictionary(data) {
        const response = {};
        if (data && data.data.groups) {
            Object.keys(data.data.groups)
                .forEach(id => {
                response[id] = this.mapDataUser({ users: data.data.groups[id] }) || [];
            });
        }
        return response;
    }
    sortResponseGroups(groups) {
        return groups.sort((a, b) => {
            if (a.escalationOrder > b.escalationOrder) {
                return 1;
            }
            else if (a.escalationOrder < b.escalationOrder) {
                return -1;
            }
            else {
                return 0;
            }
        });
    }
}
