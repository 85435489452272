
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

import { API_URL } from '../../../app.constants';

import { ApiService } from '../../api/services/api.service';
import { RiskIndex } from '../../common/models/risk-index.interface';
import { RiskSummary, parse as parseRiskSummary } from '../../common/models/risk-summary';

@Injectable()
export class RiskIndexesService {

  constructor(
    private apiService: ApiService
  ) { }

  get url(): string {
    return `${API_URL}/${this.version}/riskindexes`;
  }

  get version(): string {
    return '1.0';
  }

  getCovid19(): Observable<any> {
    return this.apiService.get(this.url + '/covid19').pipe(
      map((data) => data['covid19']));
  }

  getIndexes(): Observable<RiskIndex[]> {
    return this.apiService.get(this.url).pipe(
      map((data) => data['articles'].map(parseRiskSummary)));
  }

  listIndexes(): Observable<any> {
    return this.apiService.get(`${this.url}?count=250`).pipe(
      map((data) => data['riskindices']));
  }

  getIndex(country_code): Observable<RiskSummary> {
    return this.apiService.get(`${this.url}/index?country_code=${country_code.toUpperCase()}`).pipe(
      map((data) => data['riskindices']));
  }

  updateIndex(index: any) {
    return this.apiService.patch(`${this.url}/${index.id}`, index).pipe(
    map((data) => data['riskindices']));
  }

  getFactbook(country_code): Observable<any> {
    return this.apiService.get(`${this.url}/factbook?country_code=${country_code.toUpperCase()}`).pipe(
        map((data) => data['factbook']));
  }

  getInform(country_code): Observable<any> {
    return this.apiService.get(`${this.url}/inform?country_code=${country_code.toUpperCase()}`).pipe(
        map((data) => data['inform']));
  }

  notifyViaEmail(country_code: string, email_addresses: string) {
    const params = { emails: email_addresses };
    return this.apiService.post(`${this.url}/notify?country_code=${country_code.toUpperCase()}`, params).pipe(
      map((data) => data['riskindices']));
  }
}
