import { Component, Input, OnInit } from '@angular/core';

interface Link {
  route: string;
  label: string;
  separator?: boolean;
  role?: string;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.sass']
})
export class UserMenuComponent implements OnInit {
  @Input()
  user: any;
  public links: Link[] = [];

  constructor() { }

  ngOnInit() {
    this.loadLinks();
  }

  loadLinks () {
    this.links = [
    {
      label: 'My Profile',
      route: `/users/view/${this.user.id}`,
      role: 'AppUser'
    },
    {
      label: '',
      route: '',
      separator: true,
      role: 'AppUser'
    },
    {
      label: 'People',
      route: '/users',
      role: 'CrisisManager'
    },
    {
      label: 'Groups',
      route: '/groups',
      role: 'CrisisManager'
    },
    {
      label: 'Places',
      route: '/places',
      role: 'CrisisManager'
    },
    {
      label: 'Customers',
      route: '/customers',
      role: 'CrisisManager'
    },
    /*{
      label: 'Travel Benefits',
      route: '/insurance'
    },
    {
      label: 'Risks',
      route: '/risks'
    },*/
    {
      label: 'Locations',
      route: '/locations',
      role: 'CrisisManager'
    },
    {
      label: '',
      route: '',
      separator: true,
      role: 'AppUser'
    },
    {
      label: 'My Account',
      route: '/settings',
      role: 'AppUser'
    },
    {
      label: '',
      route: '',
      separator: true,
      role: 'AppUser'
    },
    {
      label: 'Help & Support',
      route: '/support',
      role: 'AppUser'
    },
    {
      label: '',
      route: '',
      separator: true,
      role: 'AppUser'
    },
    {
      label: 'Log out',
        route: '/auth/logout',
        role: 'AppUser'
    },
  ];
  }


}
