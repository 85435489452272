var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { RiskIndexesService } from 'app/risk-indexes/services/risk-indexes.service';
import { DrawerService } from '../services/drawer.service';
export class CountryListComponent {
    constructor(riskIndexesService, drawerService) {
        this.riskIndexesService = riskIndexesService;
        this.drawerService = drawerService;
        this.loading = true;
        this.filterText = '';
        this.comparisons = [];
        this.comparisonsLink = '';
        this.trendLink = '';
    }
    ngOnInit() {
        this.loadIndexes();
    }
    loadIndexes() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.riskIndexesService.listIndexes().subscribe(indexes => {
                this.riskIndexes = indexes
                    .sort((a, b) => a.Country.localeCompare(b.Country))
                    .filter(d => { return d.Id !== 'WW'; });
                this.filter(null);
                this.loading = false;
            });
        });
    }
    loadDrawer(code) {
        this.drawerService.openDrawer(code);
    }
    calculateRiskLevel(level) {
        if (level > 9.0) {
            return 'critical';
        }
        if (level > 7.0) {
            return 'high';
        }
        if (level > 5.0) {
            return 'medium';
        }
        else {
            return 'lower';
        }
    }
    toggleComparison(code) {
        const index = this.comparisons.indexOf(code);
        this.comparisonsLink = '';
        this.trendLink = '';
        if (index > -1) {
            this.comparisons.splice(index, 1);
        }
        else {
            if (this.comparisons.length > 5) {
                alert('You can compare a maximum of 6 countries at a time');
                return;
            }
            this.comparisons.push(code);
        }
        this.comparisons.forEach(ctry => {
            this.comparisonsLink += ctry;
            this.trendLink += ctry + ',';
        });
    }
    detectActive(code) {
        if (this.comparisons.indexOf(code) > -1) {
            return true;
        }
    }
    detectLevel(title) {
        if (!title) {
            return 'lower';
        }
        if (title.match('Level 2')) {
            return 'medium';
        }
        if (title.match('Level 3')) {
            return 'high';
        }
        if (title.match('Level 4')) {
            return 'critical';
        }
        return 'lower';
    }
    filter(event) {
        const text = this.filterText.trim().toLowerCase();
        if (text) {
            this.filteredRiskIndexes = this.riskIndexes.filter(d => {
                return (d.Country).toLowerCase().indexOf(text) >= 0;
            });
        }
        else {
            this.filteredRiskIndexes = this.riskIndexes;
        }
    }
    trimAdvisory(advisory) {
        if (!advisory) {
            return;
        }
        const s1 = advisory.substring(advisory.indexOf('Level'));
        return s1.trim();
    }
}
