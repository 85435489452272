import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../api/services/api.service';
import { SessionService } from '../../session/services/session.service';
import { ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import { RTDService } from './rtd.service';

const PULL_TIME = 30000;

@Injectable()
export class BadgeCountsService {
  get version(): string {
    return '1.0';
  }

  counts: ReplaySubject<BadgeCounts> = new ReplaySubject<BadgeCounts>();
  private state: BadgeCounterSessionState;

  constructor(
    private apiService: ApiService,
    private sessionService: SessionService,
    private rtdService: RTDService) {

    // setInterval(() => this.pull(), PULL_TIME);
    this.sessionService.getCurrentUser().subscribe(() => this.pull());
    this.pull();

    this.rtdService.reportsCreate$.subscribe(() => this.incrementState(1, 0, 0, 0));
    this.rtdService.issuesCreate$.subscribe(() => this.incrementState(0, 1, 0, 0));
    this.rtdService.notificationsCreate$.subscribe(() => this.incrementState(0, 0, 1, 0));
    this.rtdService.tripsCreate$.subscribe(() => this.incrementState(0, 0, 0, 1));
  }

  private get url(): string {
    return `${environment.API_POLLING_URL}/${this.version}/ui/counts`;
  }

  public resetReports(): void {
    const state = this.getCurrentCounterState();
    if (!state || !state.count) {
      return;
    }
    state.count.reports = 0;
    this.setCurrentCounterState(state);
    this.compareAndEmitState(state);
  }

  public resetIssues(): void {
    const state = this.getCurrentCounterState();
    if (!state || !state.count) {
      return;
    }
    state.count.issues = 0;
    this.setCurrentCounterState(state);
    this.compareAndEmitState(state);
  }

  public resetNotifications():void {
    const state = this.getCurrentCounterState();
    if (!state || !state.count) {
      return;
    }
    state.count.notifications = 0;
    this.setCurrentCounterState(state);
    this.compareAndEmitState(state);
  }

  public resetTrips(): void {
    const state = this.getCurrentCounterState();
    if (!state || !state.count) {
      return;
    }
    state.count.trips = 0;
    this.setCurrentCounterState(state);
    this.compareAndEmitState(state);
  }

  private async incrementState(reports: number, issues: number, notifications: number, trips: number) {
    const state = this.getCurrentCounterState();

    state.count.reports += reports;
    state.count.issues += issues;
    state.count.notifications += notifications;
    state.count.trips += trips;
    state.lastPulled =  new Date().getTime();

    this.setCurrentCounterState(state);
    this.compareAndEmitState(state);
  }

  private async pull() {
    if (!this.sessionService.getCurrentUserId()) {
      return;
    }

    const state = await this.getState();

    this.compareAndEmitState(state);
  }

  private compareAndEmitState(state: BadgeCounterSessionState) {
    if (!this.state || !this.compareStates(state, this.state)) {
      this.state = state;
      this.counts.next(this.state.count);
    }
  }

  private compareStates(a: BadgeCounterSessionState, b: BadgeCounterSessionState): boolean {
    try {
      const jsonA = JSON.stringify(a.count);
      const jsonB = JSON.stringify(b.count);

      return jsonA === jsonB;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private async getState(): Promise<BadgeCounterSessionState> {
    const now = new Date().getTime();
    const state = this.getCurrentCounterState();


    if (now - state.lastPulled < PULL_TIME) {
      return state;
    } else {
      let sinceDate = new Date();

      if (state.lastPulled) {
        sinceDate.setTime(state.lastPulled);
      } else {
        sinceDate = moment(sinceDate).add(-1, 'day').toDate();
      }


      state.lastPulled = now;
      this.setCurrentCounterState(state);
      const counts = await this.getCounts(sinceDate);

      counts.issues += state.count.issues;
      counts.notifications += state.count.notifications;
      counts.reports += state.count.reports;
      counts.trips += state.count.trips;

      const newState = {
        lastPulled: now,
        count: counts
      };

      this.setCurrentCounterState(newState);
      return newState;
    }
  }

  private async getCounts(since: Date): Promise<BadgeCounts> {

    const request: BatchCountsRequest = {
      user_id: this.sessionService.getCurrentUserId(),
      since_reports: since.toISOString(),
      since_issues: since.toISOString(),
      since_notifications: since.toISOString(),
      since_trips: since.toISOString()
    };

    const userId = this.sessionService.getCurrentUserId();
    const date = '02/23/2018';
    const data = await this.apiService.get(`${this.url}`, request).toPromise();

    return {
      issues: data.issues.Issues.count,
      notifications: data.notifications.Notifications.count,
      reports: data.reports.Reports.count,
      trips: data.trips ? data.trips.Trips.count : 0, // TODO: Sometimes the API response does not contain trips info
    };
  }

  getCurrentCounterState(): BadgeCounterSessionState {
    const state = localStorage.getItem('navigo:BadgeCounts');
    if (!state) {
      return this.nullState();
    }

    try {
      return JSON.parse(state);
    } catch (err) {
      console.error(err);
      return this.nullState();
    }
  }

  setCurrentCounterState(state: BadgeCounterSessionState) {
    const json = JSON.stringify(state);
    localStorage.setItem('navigo:BadgeCounts', json);
  }

  private nullState(): BadgeCounterSessionState {
    return {
      lastPulled: 0,
      count: {
        issues: 0,
        notifications: 0,
        reports: 0,
        trips: 0
      }
    };
  }
}

export class BadgeCounterSessionState {
  lastPulled: number;
  count: BadgeCounts;
}

export interface BatchCountsRequest {
  user_id: string;
  since_reports: string;
  since_issues: string;
  since_notifications: string;
  since_trips: string;
}

export interface BadgeCounts {
  issues: number;
  notifications: number;
  reports: number;
  trips: number;
}
