// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApplicationLayoutModule, EmptyLayoutModule } from './layout';

// 3rd party
import { ToastaModule } from 'ngx-toasta';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NavigoServicesClientModule } from '@navigo/navigo-services-client';

// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';

// Dependencies
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './header/header.module';
import { LayoutModule } from './layout/layout.module';
import { SessionModule } from './session/session.module';
import { AdvisoryModule } from './advisory/advisory.module';
import { DrawerService } from './advisory/components/services/drawer.service';
import { CarRentalReservationRowComponent } from './reservation-extensions/car-rental/car-rental-reservation-row/car-rental-reservation-row.component';

@NgModule({
  providers: [
    DrawerService
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
    CarRentalReservationRowComponent,
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    // 3rd party
    ToastaModule.forRoot(),
    NgbModule,
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    // MomentModule,
    // Dependencies
    SharedModule.forRoot(),
    SessionModule.forRoot(),
    AdvisoryModule.forRoot(),

    AppRoutingModule,
    // Used in AppComponent
    LayoutModule,
    HeaderModule,
    NavigoServicesClientModule,
    ApplicationLayoutModule,
    EmptyLayoutModule 
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
