import { Component, Input } from '@angular/core';
import { User } from '../../data/user/user.interface';
import { BadgeCountsService, BadgeCounts } from '../shared/services/badge-counts.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent {

  @Input() user: User;

  counts: BadgeCounts;

  reportsCount = 0;
  issuesCount = 0;
  notificationsCount = 0;
  tripsCount = 0;

  constructor(private badgeCountsService: BadgeCountsService) {
    this.badgeCountsService.counts.subscribe(counts => {
      this.reportsCount = counts.reports;
      this.notificationsCount = counts.notifications;
      this.issuesCount = counts.issues;
      this.tripsCount = counts.trips;
    });
  }

}
