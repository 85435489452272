
import {of as observableOf,  Observable } from 'rxjs';

import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


// Models
// import { GeocodingResponse } from 'app/core/models/geocoding-response.interface';

export interface GeocodingResponse {

}

// Config
import { environment } from 'environments/environment';
@Injectable()
export class GeocodingService {
  private cachedReverse: {[latlng: string]: GeocodingResponse|Observable<GeocodingResponse>} = {};
  private cachedGeocoding: {[address: string]: GeocodingResponse|Observable<GeocodingResponse>} = {};
  private get url(): string {
    return 'https://maps.googleapis.com/maps/api/geocode/json'
  }
  private get key(): string {
    return environment.GOOGLE_MAPS_API;
  }
  constructor(
    private http: HttpClient
  ) {
  }
  public geocode(address: string): Observable<GeocodingResponse> {
    if (this.cachedGeocoding[address]) {
      if (this.cachedGeocoding[address] instanceof Observable) {
        return this.cachedGeocoding[address] as Observable<GeocodingResponse>;
      }
      return observableOf(this.cachedGeocoding[address] as GeocodingResponse);
    }
    const params = <any>{
      address,
      key: this.key,
    };
    return this.cachedGeocoding[address] = this.http
      .get<GeocodingResponse>(this.url, {params}).pipe(
      tap(response => this.cachedGeocoding[address] = response)); // Cache on return
  }
  public reverse(lat: number, lng: number): Observable<GeocodingResponse> {
    const latlng = [lat, lng].join(',');
    const cachedResponse = this.cachedReverse[latlng];

    if (cachedResponse instanceof Observable) {
        return cachedResponse as Observable<GeocodingResponse>;
    } else if (cachedResponse) {
      return observableOf(cachedResponse as GeocodingResponse);
    }

    if(!lat && !lng && lat != 0 && lng != 0) {
      return new Observable(o => o.next({results: []}))
    }

    return this.cachedReverse[latlng] = this.get(this.url + `?latlng=${latlng}&key=${this.key}`).pipe(
      tap(response => {
        this.cachedReverse[latlng] = response;
        return response;
      })); // Cache on return
  }

  get(url: string) {
    return new Observable(o => {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
          if (xhttp.readyState === 4 && xhttp.status === 200) {
            try {
              o.next(JSON.parse(xhttp.responseText));
            } catch(err) {
              console.error('Could not JSON parse:' + xhttp.responseText);
            }
            o.complete();
          }
      };
      xhttp.open('GET', url, true);
      xhttp.send();
    });

  }
}
