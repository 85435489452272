import { GatewayResponse } from './gateway-response';

export interface Transaction {
  id: string;
  amount: number;
  action: string;
  currency: string;
  successful: boolean;
  created: Date;
  gatewayReference: string;
  gatewayResponse: GatewayResponse;
  gatewayResponseCode: string;
}

export class Transaction {
  constructor(
  ) {}

  id: string;
  amount: number;
  action: string;
  currency: string;
  successful: boolean;
  created: Date;
  gatewayReference: string;
  gatewayResponse: GatewayResponse;
  gatewayResponseCode: string;

  toParamObject(): any {
    return {
      id: this.id,
      amount: this.amount,
      action: this.action,
      currency: this.currency,
      successful: this.successful,
      created: this.created,
      gatewayReference: this.gatewayReference,
      gatewayResponse: this.gatewayResponse,
      gatewayResponseCode: this.gatewayResponseCode
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.amount = data.Amount;
    this.action = data.Action;
    this.currency = data.Currency;
    this.successful = data.Successful;
    this.created = data.Created;
    this.gatewayReference = data.GatewayReference;
    this.gatewayResponse =  new GatewayResponse().fromJson(data.GatewayResponse);
    this.gatewayResponseCode = data.GatewayResponseCode;
    return this;
  }

}
