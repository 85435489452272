import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-image-lightbox',
  templateUrl: './image-lightbox.component.html',
  styleUrls: ['./image-lightbox.component.sass']
})
export class ImageLightboxComponent implements OnInit {

  imageUrl: string;

  constructor(private ref: NgbActiveModal) { }

  ngOnInit() {
  }

  setImageUrl(url: string) {
    this.imageUrl = url;
  }

  close() {
    this.ref.close();
  }

}
