import { Injectable } from '@angular/core';
import { SessionService } from '../../session/services/session.service';

interface Credentials {
  email: string;
  password: string;
}

@Injectable()
export class AuthService {

  constructor(
    private sessionService: SessionService
  ) { }

  async login(credentials: Credentials): Promise<string> {

    return await this.sessionService.login();
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

}
