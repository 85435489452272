import { ServiceObject } from './service-object';
import { dateFromJson, fromJson, initFromObject } from 'util/from-json';
import { Point } from 'geojson';
import { User } from '../../users/models/user';

export class Place extends ServiceObject {

  id: string;
  active: boolean;
  title: string;
  contactUserId: string;
  customerId: string;
  geoNameId: number;
  addressStreet1: string;
  addressStreet2: string;
  addressCity: string;
  addressProvince: string;
  addressCountryCode: string;
  addressPostalCode: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  description: string;
  expats: number;
  localWorkers: number;
  geoJson: Point;
  modified: Date;
  created: Date;
  user: User;
  type: string;

  toParamObject(): any {
    return {
      id: this.id,
      active: this.active,
      title: this.title,
      contact_user_id: this.contactUserId,
      customer_id: this.customerId,
      geo_name_id: this.geoNameId,
      address_street_1: this.addressStreet1,
      address_street_2: this.addressStreet2,
      address_city: this.addressCity,
      address_province: this.addressProvince,
      address_country_code: this.addressCountryCode,
      address_postal_code: this.addressPostalCode,
      contact_name: this.contactName,
      contact_phone: this.contactPhone,
      contact_email: this.contactEmail,
      expats: this.expats,
      local_workers: this.localWorkers,
      geo_json: JSON.stringify( this.geoJson),
      created: this.created,
      modofied: this.modified,
      latitude: this.geoJson ? this.geoJson.coordinates[0] : null,
      longitude: this.geoJson ? this.geoJson.coordinates[1] : null,
      type: this.type
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.active = data.Active;
    this.title = data.Title;
    this.customerId = data.CustomerId;
    this.geoNameId = data.GeoNameId;
    this.addressStreet1 = data.AddressStreet1;
    this.addressStreet2 = data.AddressStreet2 || '';
    this.addressCity = data.AddressCity;
    this.addressProvince = data.AddressProvince;
    this.addressCountryCode = data.AddressCountryCode;
    this.addressPostalCode = data.AddressPostalCode;
    this.contactName = data.ContactName;
    this.contactPhone = data.ContactPhone;
    this.contactEmail = data.ContactEmail;
    this.expats = data.Expats;
    this.localWorkers = data.LocalWorkers;
    this.geoJson = data.GeoJson ? JSON.parse(data.GeoJson) : null;
    this.modified = dateFromJson(data.Modified);
    this.created = dateFromJson(data.Created);
    this.contactUserId = data.ContactUserId;
    this.user = data.User;
    this.type = data.Type;
    return this;
  }
}
