<div *ngIf="showDrawer" class="Overlay {{ showDrawer ? '' : 'overlayHidden'}}" (click)="hideDrawer()" ></div>

<div  class="Drawer {{ showDrawer ? '' : 'inactive'}}" >
  <div *ngIf="showDrawer" class="DrawerContent">
    <div class="threat-bar {{ indexResult ? detectLevel(indexResult.StateDepTitle) : 'lower' }}" style="padding: 15px; color: #fff; font-size: 1.3em">
      <b><span>{{ indexResult?.StateDepTitle ? indexResult?.StateDepTitle : indexResult?.Country }}</span> {{ !!informResult?.RiskClass ? '- ' + informResult?.RiskClass + ' Risk' : '' }}</b>&nbsp;
      <a style="padding: 5px; color: #fff; font-size: 12px; float:right; cursor: pointer" (click)="hideDrawer()" ><span class="ion-close rspace"></span>Close</a>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2 col-md-2">
          <h3>Contents</h3>
          <p><a (click)="toggleVisible('Advisory')">Travel Advisory</a><span *ngIf="checkVisible('Advisory')" class="pull-right ion-arrow-left-c"></span></p>
          <p><a (click)="toggleVisible('CdcAdvisory')">Disease Advisory</a><span *ngIf="checkVisible('CdcAdvisory')" class="pull-right ion-arrow-left-c"></span></p>
          <p><a (click)="toggleVisible('News')">News</a><span *ngIf="checkVisible('News')" class="pull-right ion-arrow-left-c"></span></p>
          <hr>
          <p *ngIf="factbookResult" ><a (click)="toggleVisible('introduction')" class="capitalize">Introduction</a><span *ngIf="checkVisible('introduction')" class="pull-right ion-arrow-left-c"></span></p>
          <ng-container *ngIf="factbookResult?.data">
            <ng-container *ngFor="let key of objectKeys(factbookResult.data)">
              <p *ngIf="key !== 'name' && key !== 'introduction'"><a (click)="toggleVisible(key)" class="capitalize">{{ rD(key) }}</a><span *ngIf="checkVisible(key)" class="pull-right ion-arrow-left-c"></span></p>
            </ng-container>
          </ng-container>
          <div *ngIf="!loaded" class="text-center">
            <img src="/assets/navigo-loading.gif"/>
          </div>
          <hr>
          <div *ngIf="factbookResult?.data">
            <p *ngIf="factbookResult?.data?.geography?.land_boundaries?.border_countries?.length > 0"><b>Border Countries</b></p>
            <div *ngFor="let borderCountry of factbookResult.data.geography.land_boundaries.border_countries">
              <a (click)="getCountryCode(borderCountry.country)">{{borderCountry.country }}</a>
            </div>
          </div>

        </div>
        <div class="col-sm-5 col-md-6">
          <h2 *ngIf="!loaded">Loading...</h2>
          <h2 *ngIf="loaded">{{ indexResult?.Country ? indexResult?.Country  : factbookResult?.data?.name }} <span *ngIf="CountryCode" class="pull-right {{ CountryCode !== 'WW' ? 'flag-icon flag-icon-' + CountryCode.toLowerCase() : 'ion-earth' }}"></span></h2>

          <ng-container *ngIf="checkVisible('News')">
            <h3>News</h3>
            <input [(ngModel)]="articlesSearchText" name="articlesSearchText" (ngModelChange)="filterArticles($event)" type="text" class="form-control" placeholder="Search...">
            <table style="margin-top:10px; width: 100%">
              <tr *ngIf="loadingArticles"><td colspan="2" class="text-center"><img src="/assets/navigo-loading.gif"/></td></tr>
              <ng-container *ngIf="!loadingArticles">
                <tr *ngFor="let article of filteredArticles">
                  <td>
                    <a href="{{ article.url }}">
                      <img src="{{!!article.imageUrl ? article.imageUrl : 'https://via.placeholder.com/75?text=No+Image'}}" onError="this.src='https://via.placeholder.com/75?text=No+Image'" class="thumbnail" style="max-width:75px; margin-right: 10px"/>
                    </a>
                  </td>
                  <td>
                    <p>
                      <a href="{{ article.url }}" target="_BLANK">{{article.title}}</a><br/>
                      <small>{{ article.date | date }}</small>
                    </p>
                  </td>
                </tr>
              </ng-container>
            </table>
          </ng-container>

          <ng-container *ngIf="checkVisible('Advisory') && indexResult" >
            <div *ngIf="indexResult" [innerHTML]="indexResult.StateDepDescription"></div>

            <p class="text-center"><b>Forward this Advisory</b></p>
            <div class="input-group">
              <input [ngModel]="advisoryEmails" name="advisoryEmails" (ngModelChange)="setAdvisoryEmails($event)" type="text" class="form-control" placeholder="Enter emails separated by commas..."/>
              <span class="input-group-btn">
                <button (click)="sendAdvisoryEmails()" class="btn btn-default">Email</button>
              </span>
            </div>
            <div style="width:100%; height: 35px"></div>

          </ng-container>
          <ng-container *ngIf="checkVisible('CdcAdvisory') && indexResult" >
            <div *ngIf="indexResult.DiseaseOutbreakAlert && indexResult.DiseaseOutbreakAlert !== 'null'" class="alert alert-danger">
              {{ indexResult.DiseaseOutbreakAlert }}
            </div>
            <h4 *ngIf="indexResult.HealthNoticeLevel">Disease {{ indexResult.HealthNoticeLevel }}</h4>
            <div>
              <div *ngFor="let concept of diseaseConcepts" class="threat">{{ concept }}</div>
            </div>
            <div *ngIf="indexResult.HealthSummary" [innerHTML]="splitAdvice(indexResult.HealthSummary)"></div>
            <p style="margin-top: 20px"><em>Information sourced from the Centers for Disease Control and Prevention</em></p>
          </ng-container>
          <ng-container *ngIf="factbookResult?.data">
            <ng-container *ngFor="let k of objectKeys(factbookResult.data)">
                <ng-container *ngIf="k !== 'name' && checkVisible(k)">
                  <h3 class="capitalize">{{rD(k)}}</h3>
                  <ng-container *ngFor="let k1 of objectKeys(factbookResult.data[k])">
                      <div *ngIf="k1 !== 'note'">
                        <h4 class="capitalize">{{rD(k1)}}</h4>
                        <!-- is string -->
                        <p *ngIf="isString(factbookResult.data[k][k1])">{{factbookResult.data[k][k1]}}</p>
                        <!-- is object -->
                        <ng-container *ngIf="!isString(factbookResult.data[k][k1])">

                          <!-- loop through the array or object -->
                          <ng-container *ngFor="let k2 of objectKeys(factbookResult.data[k][k1])">
                            <p *ngIf="!isNumber(k2) && !!factbookResult.data[k][k1][k2] && factbookResult.data[k][k1][k2] !== ''" style="margin-top: 10px; margin-bottom: 3px">
                              <b class="capitalize">{{rD(k2)}}</b>
                            </p>
                            <ng-container *ngIf="!isNumber(k2) && factbookResult.data[k][k1][k2]?.units">
                              {{factbookResult.data[k][k1][k2]?.value + ' ' + factbookResult.data[k][k1][k2]?.units }}
                              <span *ngIf="factbookResult.data[k][k1][k2]?.date" > ({{ factbookResult.data[k][k1][k2]?.date }})</span>
                            </ng-container>

                            <!-- is string -->
                            <p *ngIf="isString(factbookResult.data[k][k1][k2]) && k2 !== 'units' && k2 !== 'value'">{{ factbookResult.data[k][k1][k2] }}</p>

                            <!-- if this is another sub object -->
                            <ng-container *ngIf="!isString(factbookResult.data[k][k1][k2])">
                              <ng-container *ngFor="let k3 of objectKeys(factbookResult.data[k][k1][k2])">
                                <!-- if the key is a number, we are in an array -->
                                <ng-container *ngIf="isNumber(k3)" >
                                  <ng-container *ngIf="isString(factbookResult.data[k][k1][k2][k3])">
                                    <div class="capitalize info-block">{{factbookResult.data[k][k1][k2][k3]}}</div>
                                  </ng-container>
                                </ng-container>
                                <!-- if the result contains units, then show the units -->
                                <ng-container *ngIf="!isNumber(k3) && factbookResult.data[k][k1][k2][k3]?.units">
                                  {{factbookResult.data[k][k1][k2][k3]?.value + ' ' + factbookResult.data[k][k1][k2][k3]?.units }}
                                  <span *ngIf="factbookResult.data[k][k1][k2][k3]?.date" > ({{ factbookResult.data[k][k1][k2][k3]?.date }})</span>
                                </ng-container>

                                <div *ngIf="!isNumber(k3) && k3 !== 'units' && k3 !== 'value'" style="padding-left: 10px;">
                                  <em *ngIf="!isNumber(k3) && k3 !== 'name' && factbookResult.data[k][k1][k2][k3]" class="capitalize">{{ rD(k3) }}:</em>
                                  <span *ngIf="isString(factbookResult.data[k][k1][k2][k3])"> {{factbookResult.data[k][k1][k2][k3]}}<br/></span>
                                  <ng-container *ngIf="factbookResult.data[k][k1][k2][k3]?.units">
                                    {{factbookResult.data[k][k1][k2][k3]?.value + factbookResult.data[k][k1][k2][k3]?.units }}
                                    <span *ngIf="factbookResult.data[k][k1][k2][k3]?.date" > ({{ factbookResult.data[k][k1][k2][k3]?.date }})</span>
                                  </ng-container>
                                  <span *ngIf="!isString(factbookResult.data[k][k1][k2][k3]) && !factbookResult.data[k][k1][k2][k3]?.units">
                                    <ul>
                                      <li *ngFor="let k4 of objectKeys(factbookResult.data[k][k1][k2][k3])">
                                        <ng-container *ngIf="factbookResult.data[k][k1][k2][k3][k4]?.units">
                                          {{factbookResult.data[k][k1][k2][k3][k4]?.value + factbookResult.data[k][k1][k2][k3][k4]?.units }}
                                          <span *ngIf="factbookResult.data[k][k1][k2][k3][k4]?.date" > ({{ factbookResult.data[k][k1][k2][k3][k4]?.date }})</span>
                                        </ng-container>
                                        <span *ngIf="isString(factbookResult.data[k][k1][k2][k3][k4])">
                                          <span *ngIf="!isNumber(k4) && k4 !== 'name' && k4 !=='units' && k4 !== 'value'">{{ rD(k4) }}:</span> {{factbookResult.data[k][k1][k2][k3][k4]}}
                                        </span>
                                        <span *ngIf="!isString(factbookResult.data[k][k1][k2][k3][k4])">
                                          <ng-container *ngFor="let k5 of objectKeys(factbookResult.data[k][k1][k2][k3][k4])">
                                              <ng-container *ngIf="factbookResult.data[k][k1][k2][k3][k4][k5]?.units">
                                                {{factbookResult.data[k][k1][k2][k3][k4][k5]?.value + factbookResult.data[k][k1][k2][k3][k4][k5]?.units }}
                                                <span *ngIf="factbookResult.data[k][k1][k2][k3][k4][k5]?.date" > ({{ factbookResult.data[k][k1][k2][k3][k4][k5]?.date }})</span>
                                                {{rD(k5)}}
                                                <br>
                                              </ng-container>
                                              <span *ngIf="isString(factbookResult.data[k][k1][k2][k3][k4][k5])">
                                                <span *ngIf="!isNumber(k5) && k5 !== 'country_of_origin' && k5 !== 'name' && k5 !=='units' && k5 !== 'value'">{{ rD(k5) }}:</span> {{factbookResult.data[k][k1][k2][k3][k4][k5]}}
                                              </span>
                                          </ng-container>
                                          <pre class="hidden">
                                            {{factbookResult.data[k][k1][k2][k3][k4] | json }}
                                          </pre>
                                        </span>
                                      </li>
                                    </ul>
                                  </span>

                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                      </div>
                  </ng-container>
                </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="checkVisible('introduction') && indexResult && events$ | async as events">
            <h4>{{indexResult?.Country}} Calendar <a routerLink="/calendar/add/{{CountryCode}}" (click)="hideDrawer()" class="btn btn-default btn-xs pull-right"><span class="ion-plus"></span></a></h4>
            <div *ngFor="let event of events">
                <article>
                  <p class="title">
                    <i class="icon fa fa-calendar level-{{ event['riskLevel'] }} rspace" title="The estimated risk level for this event is {{ event['riskLevel'] }}"></i>
                    <a routerLink="/calendar/events/view/{{event.id}}" (click)="hideDrawer()" title="{{event.title}} is on {{event.startTime | date}}.
                    DESCRIPTION: {{event.description}}">{{event.title}}</a>
                    <a routerLink="/calendar/edit/{{ event.id }}" (click)="hideDrawer()" class="pull-right" title="Edit"><span class="ion-edit"></span></a>
                  </p>
                  <p>{{event.description}}</p>
                  <p *ngIf="!isSingleDayEvent(event)" class="date text-center">{{event.startTime | date}} - {{event.endTime | date}}</p>
                  <p *ngIf="isSingleDayEvent(event)"class="date text-center">{{event.startTime | date}}</p>
                </article>
            </div>
            <div *ngIf="!!events || events.length === 0" class="text-center">
              <em>No calendar events for {{ indexResult?.Country }}. <a routerLink="/calendar/add/{{CountryCode}}" (click)="hideDrawer()">Add a calendar event</a>.</em>

            </div>
          </ng-container>

        <pre *ngIf="factbookResult && false">{{factbookResult.data.geography.land_boundaries.border_countries | json}}</pre>
        </div>

        <div class="col-sm-5 col-md-4">
          <img src="/assets/images/factbook/{{CountryCode}}_001_large.jpg" class="img-responsive thumbnail" style="margin-top:20px"/>
          <div *ngIf="!loaded" class="text-center">
            <img src="/assets/navigo-loading.gif"/>
          </div>
          <div *ngIf="!!informResult">
            <!-- <a (click)="hideDrawer()" routerLink="/intel/impact-trends" [queryParams]="{ countries : CountryCode }" class="btn btn-default btn-md full-width">{{ indexResult?.Country }} Trends <span class="ion-arrow-graph-up-right"></span></a> -->
            <a (click)="hideDrawer()" routerLink="/countries"  class="btn btn-default btn-md full-width">All Countries <span class="ion-earth"></span></a>

            <h3>Overall Risks</h3>
            <span *ngIf="informResult?.HazardAndExposure > 0">
              <b class="gray-title">HAZARD & EXPOSURE</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.HazardAndExposure) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.HazardAndExposure / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.HazardAndExposure / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.Vulnerability > 0">
              <b class="gray-title">VULNERABILITY</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Vulnerability) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Vulnerability / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Vulnerability / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.LackOfCopingCapacity > 0">
              <b class="gray-title">LACK OF COPING CAPACITY</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.LackOfCopingCapacity) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.LackOfCopingCapacity / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.LackOfCopingCapacity / 10}}%</span>
                </div>
              </div>
            </span>

            <span *ngIf="informResult?.Human > 0">
              <b class="gray-title">HUMAN CONFLICT LEVEL</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Human) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Human / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Human / 10}}%</span>
                </div>
              </div>
            </span>

            <span *ngIf="indexResult?.RoadDeathsPer100k">
              <b class="gray-title" [title]="'Driving fatality rate is ' + indexResult?.RoadDeathsPer100k + ' per 100k population'" >DRIVING FATALITY RATE</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(indexResult.RoadDeathsPer100k / 3.7) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * (indexResult.RoadDeathsPer100k / 37) + '%'">
                  <span class="sr-only">{{ (indexResult.RoadDeathsPer100k / 3.7) * 100 }}%</span>
                </div>
              </div>
            </span>

            <h3>Natural Disaster Risk</h3>
            <span *ngIf="informResult?.Flood > 0">
              <b class="gray-title">FLOODING</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Flood) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Flood / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Flood / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.Drought > 0">
              <b class="gray-title">DROUGHT</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Drought) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Drought / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Drought / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.Earthquake > 0">
              <b class="gray-title">EARTHQUAKES</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Earthquake) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Earthquake / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Earthquake / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.Tsunami > 0">
              <b class="gray-title">TSUNAMI</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Tsunami) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Tsunami / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.Tsunami / 10}}%</span>
                </div>
              </div>
            </span>
            <span *ngIf="informResult?.TropicalCyclone > 0">
              <b class="gray-title">HURRICANES</b>
              <div class="progress">
                <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.TropicalCyclone) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.TropicalCyclone / 10 + '%'">
                  <span class="sr-only">{{ 100 * informResult?.TropicalCyclone / 10}}%</span>
                </div>
              </div>
            </span>

            <h3>Societal Risk Factors</h3>
            <b class="gray-title">FOOD INSECURITY</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.FoodSecurity) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.FoodSecurity / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.FoodSecurity / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">AID DEPENDENCY</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.AidDependency) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.AidDependency / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.AidDependency / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">HEALTHCARE INADEQUACY</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.AccessToHealthCare) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.AccessToHealthCare / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.AccessToHealthCare / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">SOCIO ECONOMIC VULNERABILITY</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.SocioEconomicVulnerability) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.SocioEconomicVulnerability / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.SocioEconomicVulnerability / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">UPROOTED PEOPLE</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.UprootedPeople) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.UprootedPeople / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.UprootedPeople / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">GOVERNMENT INEFFECTIVENESS OR CORRUPTION</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.Governance) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.Governance / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.Governance / 10}}%</span>
              </div>
            </div>
            <b class="gray-title">DISASTER PLANNING INSUFFICIENCY</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.DRR) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.DRR / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.DRR / 10}}%</span>
              </div>
            </div>

            <h3>Infrastructure Issues</h3>
            <b class="gray-title">COMMUNICATION LIMITATIONS</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.DRR) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.DRR / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.DRR / 10}}%</span>
              </div>
            </div>

            <b class="gray-title">PHYSICAL INFRASTRUCTURE LIMITATIONS</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(informResult?.PhysicalInfrastructure) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * informResult?.PhysicalInfrastructure / 10 + '%'">
                <span class="sr-only">{{ 100 * informResult?.PhysicalInfrastructure / 10}}%</span>
              </div>
            </div>

            <h3>Historial Threat Levels</h3>
            <b class="gray-title">CRIME</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(indexResult?.Crime * 10) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * indexResult?.Crime + '%'">
                <span class="sr-only">{{ 100 * indexResult?.Crime / 10}}%</span>
              </div>
            </div>

            <b class="gray-title">TERRORISM</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(indexResult?.Terrorism * 10) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * indexResult?.Terrorism + '%'">
                <span class="sr-only">{{ 100 * indexResult?.Terrorism }}%</span>
              </div>
            </div>

            <b class="gray-title">DISEASE</b>
            <div class="progress">
              <div class="progress-bar progress-bar-{{ calculateRiskLevel(indexResult?.Disease * 10) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * indexResult?.Disease + '%'">
                <span class="sr-only">{{ 100 * indexResult?.Disease }}%</span>
              </div>
            </div>

            <div class="text-right"><em>Data based on INFORM data and other data sets. INFORM recency {{informResult?.RecentnessData }} years</em></div>
            <hr>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
