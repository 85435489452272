import {Location} from '../location/location.interface';
import {dateFromJson, fromJson, initFromObject} from 'util/from-json';
import {User, UserFromApi} from '../user/user.interface';
import {Ping, PingFromApi} from '../ping/ping.interface';
import {Timestamped} from '../timestamped.interface';

export interface DeviceFromApi {

  User?: UserFromApi;
  LastPing?: PingFromApi;
  Serial: string;
  PhoneNumber: string;
  Id: any;
  Model: string;
  FcmToken: string;
  Created: string;
  Modified: string;
}

export class Device implements Timestamped {

  /** This is only used when getting pings nearby reports */
  user?: User;
  lastPing?: Ping;
  id: any;
  modelName: string;
  serialNumber: string;
  fcmToken?: string;
  phoneNumber: string;
  whenCreated: Date;
  whenModified: Date;

  public static fromJson(srcJson): Device {
    return fromJson(srcJson, (src: DeviceFromApi) => {
      return new Device({
        id: src.Id,
        user: User.fromJson(src.User),
        lastPing: Ping.fromJson(src.LastPing && src.LastPing[0]
          /* TODO: Is it intentional, that LastPing is an array? */),
        modelName: src.Model,
        fcmToken: src.FcmToken,
        whenCreated: dateFromJson(src.Created),
        whenModified: dateFromJson(src.Modified),
        serialNumber: src.Serial,
        phoneNumber: src.PhoneNumber,
      });
    });
  }

  constructor(initFrom: Device) {
    initFromObject(this, initFrom);
  }

}
