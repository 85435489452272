<ul *acRequireRole="'CrisisManager'" class="nav nav-pills nav-stacked side-menu">
  <li><a routerLink="/alerts"><div class="menu-icon"><i class="ion-alert-circled" aria-hidden="true"></i></div>Alerts</a></li>
  <li><a routerLink="/calendar"><div class="menu-icon"><i class="ion-calendar" aria-hidden="true"></i></div>Calendar</a></li>
  <li><a routerLink="/countries"><div class="menu-icon"><i class="ion-earth" aria-hidden="true"></i></div>Countries</a></li>
  <li *acRequireRole="'SuperUser'"><a routerLink="/customers"><div class="menu-icon"><i class="ion-briefcase" aria-hidden="true"></i></div>Customers</a></li>
  <li><a routerLink="/devices"><div class="menu-icon"><i class="ion-iphone" aria-hidden="true"></i></div>Devices</a></li>
  <li *acRequireRole="'CrisisManager'"><a routerLink="/geo-fences"><div class="menu-icon"><i class="ion-android-expand" aria-hidden="true"></i></div>Geo-fences</a></li>
  <li><a routerLink="/groups"><div class="menu-icon"><i class="ion-ios-people" aria-hidden="true"></i></div>Groups</a></li>
  <li><a routerLink="/impacts/list"><div class="menu-icon"><i class="ion-map" aria-hidden="true"></i></div>Impacts</a></li>
  <li><a routerLink="/locations"><div class="menu-icon"><i class="ion-ios-location" aria-hidden="true"></i></div>Locations</a></li>
  <li *acRequireRole="'AccountManager'"><a routerLink="/customers/view/{{this.customerId }}"><div class="menu-icon"><i class="ion-briefcase" aria-hidden="true"></i></div>Organization</a></li>
  <li><a routerLink="/users"><div class="menu-icon"><i class="ion-person" aria-hidden="true"></i></div>People</a></li>
  <li><a routerLink="/places"><div class="menu-icon"><i class="ion-ios-home-outline" aria-hidden="true"></i></div>Places</a></li>
  <li class="hidden"><a routerLink="/risks"><div class="menu-icon"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></div>Risks</a></li>
  <li *acRequireRole="'SuperUser'"><a routerLink="/riskindexes"><div class="menu-icon"><i class="ion-android-list" aria-hidden="true"></i></div>Risk Indexes</a></li>
  <li *acRequireRole="'SuperUser'"><a routerLink="/risks"><div class="menu-icon"><i class="ion-ios-circle-outline" aria-hidden="true"></i></div>Threat Types</a></li>
  <li><hr></li>
  <li *acRequireRole="'AccountManager'"><a routerLink="/service-health"><div class="menu-icon"><i class="ion-checkmark-circled" aria-hidden="true"></i></div>Service Health</a></li>
</ul>
