var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { environment } from '../../../environments/environment';
import { ApiService } from '../../api/services/api.service';
import { SessionService } from '../../session/services/session.service';
import { ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import { RTDService } from './rtd.service';
const PULL_TIME = 30000;
export class BadgeCountsService {
    constructor(apiService, sessionService, rtdService) {
        this.apiService = apiService;
        this.sessionService = sessionService;
        this.rtdService = rtdService;
        this.counts = new ReplaySubject();
        // setInterval(() => this.pull(), PULL_TIME);
        this.sessionService.getCurrentUser().subscribe(() => this.pull());
        this.pull();
        this.rtdService.reportsCreate$.subscribe(() => this.incrementState(1, 0, 0, 0));
        this.rtdService.issuesCreate$.subscribe(() => this.incrementState(0, 1, 0, 0));
        this.rtdService.notificationsCreate$.subscribe(() => this.incrementState(0, 0, 1, 0));
        this.rtdService.tripsCreate$.subscribe(() => this.incrementState(0, 0, 0, 1));
    }
    get version() {
        return '1.0';
    }
    get url() {
        return `${environment.API_POLLING_URL}/${this.version}/ui/counts`;
    }
    resetReports() {
        const state = this.getCurrentCounterState();
        if (!state || !state.count) {
            return;
        }
        state.count.reports = 0;
        this.setCurrentCounterState(state);
        this.compareAndEmitState(state);
    }
    resetIssues() {
        const state = this.getCurrentCounterState();
        if (!state || !state.count) {
            return;
        }
        state.count.issues = 0;
        this.setCurrentCounterState(state);
        this.compareAndEmitState(state);
    }
    resetNotifications() {
        const state = this.getCurrentCounterState();
        if (!state || !state.count) {
            return;
        }
        state.count.notifications = 0;
        this.setCurrentCounterState(state);
        this.compareAndEmitState(state);
    }
    resetTrips() {
        const state = this.getCurrentCounterState();
        if (!state || !state.count) {
            return;
        }
        state.count.trips = 0;
        this.setCurrentCounterState(state);
        this.compareAndEmitState(state);
    }
    incrementState(reports, issues, notifications, trips) {
        return __awaiter(this, void 0, void 0, function* () {
            const state = this.getCurrentCounterState();
            state.count.reports += reports;
            state.count.issues += issues;
            state.count.notifications += notifications;
            state.count.trips += trips;
            state.lastPulled = new Date().getTime();
            this.setCurrentCounterState(state);
            this.compareAndEmitState(state);
        });
    }
    pull() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sessionService.getCurrentUserId()) {
                return;
            }
            const state = yield this.getState();
            this.compareAndEmitState(state);
        });
    }
    compareAndEmitState(state) {
        if (!this.state || !this.compareStates(state, this.state)) {
            this.state = state;
            this.counts.next(this.state.count);
        }
    }
    compareStates(a, b) {
        try {
            const jsonA = JSON.stringify(a.count);
            const jsonB = JSON.stringify(b.count);
            return jsonA === jsonB;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }
    getState() {
        return __awaiter(this, void 0, void 0, function* () {
            const now = new Date().getTime();
            const state = this.getCurrentCounterState();
            if (now - state.lastPulled < PULL_TIME) {
                return state;
            }
            else {
                let sinceDate = new Date();
                if (state.lastPulled) {
                    sinceDate.setTime(state.lastPulled);
                }
                else {
                    sinceDate = moment(sinceDate).add(-1, 'day').toDate();
                }
                state.lastPulled = now;
                this.setCurrentCounterState(state);
                const counts = yield this.getCounts(sinceDate);
                counts.issues += state.count.issues;
                counts.notifications += state.count.notifications;
                counts.reports += state.count.reports;
                counts.trips += state.count.trips;
                const newState = {
                    lastPulled: now,
                    count: counts
                };
                this.setCurrentCounterState(newState);
                return newState;
            }
        });
    }
    getCounts(since) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = {
                user_id: this.sessionService.getCurrentUserId(),
                since_reports: since.toISOString(),
                since_issues: since.toISOString(),
                since_notifications: since.toISOString(),
                since_trips: since.toISOString()
            };
            const userId = this.sessionService.getCurrentUserId();
            const date = '02/23/2018';
            const data = yield this.apiService.get(`${this.url}`, request).toPromise();
            return {
                issues: data.issues.Issues.count,
                notifications: data.notifications.Notifications.count,
                reports: data.reports.Reports.count,
                trips: data.trips ? data.trips.Trips.count : 0,
            };
        });
    }
    getCurrentCounterState() {
        const state = localStorage.getItem('navigo:BadgeCounts');
        if (!state) {
            return this.nullState();
        }
        try {
            return JSON.parse(state);
        }
        catch (err) {
            console.error(err);
            return this.nullState();
        }
    }
    setCurrentCounterState(state) {
        const json = JSON.stringify(state);
        localStorage.setItem('navigo:BadgeCounts', json);
    }
    nullState() {
        return {
            lastPulled: 0,
            count: {
                issues: 0,
                notifications: 0,
                reports: 0,
                trips: 0
            }
        };
    }
}
export class BadgeCounterSessionState {
}
