var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { RiskIndexesService } from '../../../../app/risk-indexes/services/risk-indexes.service';
import { CalendarsService } from '../../../../app/calendar/services/calendars.service';
import { NewArticlesService } from '../../../../app/articles/services/new-articles.service';
import { DrawerService } from '../services/drawer.service';
import { ToastService } from '../../../shared/services/toast.service';
const countryCodes = require('../../countryCodes.json');
export class DrawerComponent {
    constructor(riskIndexesService, drawerService, calendarsService, articlesService, toastService) {
        this.riskIndexesService = riskIndexesService;
        this.drawerService = drawerService;
        this.calendarsService = calendarsService;
        this.articlesService = articlesService;
        this.toastService = toastService;
        this.showDrawer = false;
        this.loaded = false;
        this.visible = [];
        this.articlesSearchText = '';
        this.loadingArticles = true;
        this.diseaseConcepts = [];
    }
    ngOnInit() {
        this.visible = ['Advisory'];
        this.drawerService.getCurrentCountryCode().subscribe(code => {
            if (!code || code === 'WW') {
                this.showDrawer = false;
                return;
            }
            this.loadRisk(code);
        });
    }
    loadArticles(code) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingArticles = true;
            this.articles = yield this.articlesService.getCountryArticles(code).toPromise();
            this.loadingArticles = false;
            this.filterArticles(null);
        });
    }
    filterArticles(event) {
        const text = this.articlesSearchText.trim().toLowerCase();
        if (text) {
            this.filteredArticles = this.articles.filter(d => {
                return (d.title).toLowerCase().indexOf(text) >= 0;
            });
        }
        else {
            this.filteredArticles = this.articles;
        }
    }
    loadRisk(code) {
        this.CountryCode = code;
        if (code === 'US') {
            this.visible = ['introduction'];
        }
        this.riskIndexesService.getFactbook(code).subscribe(result => {
            this.factbookResult = result;
            this.loaded = true;
        });
        this.riskIndexesService.getInform(code).subscribe(result => {
            this.informResult = result;
            this.loaded = true;
        });
        this.riskIndexesService.getIndex(code).subscribe(result => {
            this.indexResult = result;
            if (this.indexResult.RoadDeathsPer100k) {
                this.indexResult.RoadDeathsPer100k = parseInt(this.indexResult.RoadDeathsPer100k, 10);
            }
            if (this.indexResult.DiseaseTravelAdvice) {
                this.getDiseaseConcepts(this.indexResult.DiseaseTravelAdvice);
            }
            this.loaded = true;
        });
        this.loadArticles(code);
        this.loadEvents(code);
        this.showDrawer = true;
    }
    loadEvents(code) {
        this.events$ = this.calendarsService.getCountryCalendars(this.CountryCode);
    }
    isSingleDayEvent(event) {
        const start = moment(event.startTime);
        const end = moment(event.endTime);
        const duration = moment.duration(start.diff(end));
        const days = Math.abs(duration.asDays());
        if (days === 1) {
            return true;
        }
        else {
            return false;
        }
    }
    hideDrawer() {
        this.showDrawer = false;
        this.clearCurrentCountry();
    }
    toggleVisible(section) {
        this.visible = [];
        const sectionIndex = this.visible.indexOf(section);
        if (sectionIndex === -1) {
            this.visible.push(section);
        }
        else {
            this.visible.splice(sectionIndex, 1);
        }
    }
    clearCurrentCountry() {
        this.informResult = null;
        this.indexResult = null;
        this.factbookResult = null;
        this.articles = null;
        this.events$ = null;
        this.loaded = false;
        this.diseaseConcepts = [];
    }
    getCountryCode(name) {
        if (!name) {
            return;
        }
        if (name === 'US') {
            name = 'United States';
        }
        const index = countryCodes.map(function (e) { return e.Name; }).indexOf(name);
        if (index > -1) {
            const code = countryCodes[index].Code;
            this.CountryCode = code;
            this.clearCurrentCountry();
            this.loadRisk(code);
        }
    }
    parseDate(date) {
        return parseInt(date, 10) * 1000;
    }
    detectLevel(title) {
        if (!title) {
            return 'lower';
        }
        if (title.match('Level 2')) {
            return 'medium';
        }
        if (title.match('Level 3')) {
            return 'high';
        }
        if (title.match('Level 4')) {
            return 'critical';
        }
        return 'lower';
    }
    checkVisible(section) {
        if (this.visible.indexOf(section) !== -1) {
            return true;
        }
        else {
            return false;
        }
    }
    nl2br(str, is_xhtml) {
        const breakTag = '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    calculateRiskLevel(level) {
        if (level > 9.0) {
            return 'critical';
        }
        if (level > 7.0) {
            return 'high';
        }
        if (level > 5.0) {
            return 'medium';
        }
        else {
            return 'lower';
        }
    }
    objectKeys(obj) {
        return Object.keys(obj).sort();
    }
    rD(value) {
        if (!value) {
            return;
        }
        return value.replace(new RegExp('_', 'g'), ' ');
    }
    isString(value) {
        if (typeof value === 'string' || value instanceof String || typeof value === 'number') {
            return true;
        }
    }
    isObject(value) {
        if (!value) {
            return;
        }
        if (typeof value === 'object' && value !== null || value.isArray) {
            return true;
        }
    }
    checkArray(value) {
        if (!value) {
            return;
        }
        return value.isArray;
    }
    isNumber(value) {
        let x;
        if (isNaN(value)) {
            return false;
        }
        x = parseFloat(value);
        return (x || 0) === x;
    }
    splitAdvice(s) {
        s = s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        s = s.replace('Key', '');
        return s = s.replace(/([A-Z])/g, '<br>• $1');
    }
    getDiseaseConcepts(DiseaseTravelAdvice) {
        const diseases = ['Malaria', 'Dengue', 'Rabies', 'Zika', 'Polio', 'Typhoid',
            'Ebola', 'Yellow Fever', 'Hepatitis A', 'Hepatitis B', 'Cholera', 'Tuberculosis',
            'Creutzfeldt-Jakob', 'Marburg', 'H5N1', 'H7N9', 'MERS', 'Meningitis', 'Lassa', 'HIV'];
        diseases.forEach(d => {
            if (DiseaseTravelAdvice.toLowerCase()
                .indexOf(d.toLowerCase()) > -1 && this.diseaseConcepts
                .indexOf(d.toLowerCase()) === -1) {
                this.diseaseConcepts.push(d);
            }
        });
    }
    setAdvisoryEmails(emails) { this.advisoryEmails = emails; }
    sendAdvisoryEmails() {
        if (!this.advisoryEmails || this.advisoryEmails === '') {
            this.toastService.error('Could not forward advisories. Please enter an email address');
            return;
        }
        if (!!this.advisoryEmails && this.advisoryEmails !== '') {
            // validate emails
            const emails = this.advisoryEmails.split(',');
            let valid = true;
            const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for (let i = 0; i < emails.length; i++) {
                if (emails[i] === '' || !regex.test(emails[i].replace(/\s/g, ''))) {
                    valid = false;
                }
            }
            if (valid) {
                this.riskIndexesService.notifyViaEmail(this.CountryCode, this.advisoryEmails).subscribe(a => {
                    this.toastService.success('Advisory Forwarded');
                    this.advisoryEmails = '';
                });
            }
            else {
                this.toastService.error('Could not forward advisories. Please correct one or more invalid emails.');
            }
        }
    }
}
