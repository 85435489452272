export interface Quotation {
  baseAmount: number;
  proratedAmount: number;
  proratedDays: number;
  scheduleDate: string;
  seatCount: number;
  seatPrice: number;
}

export class Quotation  {
  constructor(
  ) {}

  baseAmount: number;
  proratedAmount: number;
  proratedDays: number;
  scheduleDate: string;
  seatCount: number;
  seatPrice: number;

  toParamObject(): any {
    return {
      base_amount: this.baseAmount,
      prorated_amount: this.proratedAmount,
      prorated_days: this.proratedDays,
      schedule_date: this.scheduleDate,
      seat_count: this.seatCount,
      seat_price: this.seatPrice,
    };
  }

  fromJson(data: any) {
    this.baseAmount = data.BaseAmount;
    this.proratedAmount = data.ProratedAmount;
    this.proratedDays = data.ProratedDays;
    this.scheduleDate = data.ScheduleDate;
    this.seatCount = data.SeatCount;
    this.seatPrice = data.SeatPrice;
    return this;
  }

}
