var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';
import { SessionService } from '../../session/services/session.service';
import { AccessControlRoles } from '../roles.enum';
export class AuthGuard {
    constructor(sessionService, toastService, router) {
        this.sessionService = sessionService;
        this.toastService = toastService;
        this.router = router;
    }
    canActivate(route, state) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sessionService.isAuthenticated()) {
                this.toastService.error('Unauthorized. Redirecting to Login.');
                yield this.sessionService.login();
                return this.sessionService.isAuthenticated();
            }
            const role = this.sessionService.getUserRole();
            const userId = this.sessionService.getCurrentUserId();
            if (role === AccessControlRoles.AppUser && route.params.id !== userId && state.url.startsWith('/settings')) {
                this.toastService.error('Unauthorized');
                this.router.navigate(['/auth/welcome/']);
                //this.router.navigate(['/users/view/' + this.sessionService.getCurrentUserId()]);
                return false;
            }
            return true;
        });
    }
}
