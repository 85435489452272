export enum PingAwarenessActivity {
  DRIVING = 0,
  BICYCLING = 1,
  ONFOOT = 2,
  STOPPED = 3,
  UNKNOWN = 4,
  TILTING = 5,
  WALKING = 7,
  RUNNING = 8
}

export const PingAwarenessActivityLabels = {
  [PingAwarenessActivity.DRIVING]: 'Driving',
  [PingAwarenessActivity.BICYCLING]: 'Bicycling',
  [PingAwarenessActivity.ONFOOT]: 'On Foot',
  [PingAwarenessActivity.STOPPED]: 'Stopped',
  [PingAwarenessActivity.UNKNOWN]: 'Unknown Activity',
  [PingAwarenessActivity.TILTING]: 'Tilting',
  [PingAwarenessActivity.WALKING]: 'Walking',
  [PingAwarenessActivity.RUNNING]: 'Running'
};

export const PingAwarenessActivityIcons = {
  [PingAwarenessActivity.DRIVING]: 'ion-model-s',
  [PingAwarenessActivity.BICYCLING]: 'ion-model-s',
  [PingAwarenessActivity.ONFOOT]: 'on-android-walk',
  [PingAwarenessActivity.STOPPED]: 'ion-android-hand',
  [PingAwarenessActivity.UNKNOWN]: 'ion-help',
  [PingAwarenessActivity.TILTING]: 'ion-android-hand',
  [PingAwarenessActivity.WALKING]: 'on-android-walk',
  [PingAwarenessActivity.RUNNING]: 'on-android-walk'
};
