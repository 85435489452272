import { NgModule } from '@angular/core';
import { UsersService } from './users.service';
import { ApiClientService } from './api-client.service';
import { ClientConfigService } from './client-config.service';
import { DevicesService } from './devices.service';
import { CalendarService } from './calender.service';
import { GroupsService } from './groups.service';
import { IncidentsService } from './incidents.service';

@NgModule({
  providers: [
    ApiClientService,
    UsersService,
    ClientConfigService,
    DevicesService,
    CalendarService,
    GroupsService,

    IncidentsService
  ]
})
export class NavigoServicesClientModule { }
