import { ApiClientService } from './api-client.service';
import { Device } from './models';
import * as i0 from "@angular/core";
import * as i1 from "./api-client.service";
export class DevicesService {
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.crudClient = this.apiClient.getCrudClient('devices', true, (json) => new Device().fromJson(json), (calendar) => calendar.toParamObject());
    }
}
DevicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DevicesService_Factory() { return new DevicesService(i0.ɵɵinject(i1.ApiClientService)); }, token: DevicesService, providedIn: "root" });
