import { Directive, ElementRef, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgModel } from '@angular/forms';


@Directive({
  selector: '[ngModel][appIntlTelInput]',
  providers: [{provide: NG_VALIDATORS, useExisting: IntlTelInputDirective, multi: true}, NgModel]
})
export class IntlTelInputDirective implements OnDestroy {
  
  cleanValue: any;
  dirty: boolean = false;
  input: HTMLInputElement;
  blurEvent = () => this.onInputBlur();

  @Output() ngModelChange:EventEmitter<any> = new EventEmitter();
  @Input() set ngModel(value:any){
    this.cleanValue = !this.cleanValue ? value : this.cleanValue;
  }

  constructor(private ref: ElementRef) {
    this.input = ref.nativeElement;
    window['$'](this.input).intlTelInput();
    this.input.addEventListener('blur', this.blurEvent);
  }

  validate(control: AbstractControl): {[key: string]: any} {
    const isValid = window['$'](this.input).intlTelInput('isValidNumber');

    this.dirty = control.value != this.cleanValue;

    if (!isValid) {
      return { 'invalidPhoneNumber': control.value };
    } else {
      return null;
    }
  }

  onInputBlur() {
    if(this.dirty) {
      const number = window['$'](this.input).intlTelInput('getNumber');
      this.ngModelChange.emit(number);    
    }
  }

  ngOnDestroy() {
    this.input.removeEventListener('blur', this.blurEvent);
  }

}
