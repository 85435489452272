export interface RiskSummary {
  Average: number;
  Id: string;
  Country: string;
  Beneficiaries: number;
  Kidnapping: number;
  Social: number;
  LegalTrend: number;
  Governmental: number;
  CrimeTrend: number;
  Terrorism: number;
  GovernmentalTrend: number;
  BeneficialTrend: number;
  Crime: number;
  Disease: number;
  TerrorismTrend: number;
  DiseaseTrend: number;
  KidnappingTrend: number;
  Legal: number;
  SocialTrend: number;
  StateDepLink: string;
  StateDepPubDate: Date;
  StateDepRegionIdentifier: string;
  StateDepStatus: string;
  StateDepTitle: string;
}

export const parse = (input: any) => input;
