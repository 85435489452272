/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-menu.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/directives/ac-require.directive";
import * as i5 from "../../shared/services/access-control.service";
import * as i6 from "./user-menu.component";
var styles_UserMenuComponent = [i0.styles];
var RenderType_UserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserMenuComponent, data: {} });
export { RenderType_UserMenuComponent as RenderType_UserMenuComponent };
function View_UserMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_3); }); }
function View_UserMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "divider": 0, "dropdown-item": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMenuComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit.separator, !_v.parent.context.$implicit.separator); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.separator; _ck(_v, 5, 0, currVal_1); }, null); }
function View_UserMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMenuComponent_2)), i1.ɵdid(2, 81920, null, 0, i4.AcRequireDirective, [i1.TemplateRef, i1.ViewContainerRef, i5.AccessControlService], { acRequireRole: [0, "acRequireRole"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.role; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ul", [["class", "nav navbar-nav navbar-right app-user-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "li", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-toggle username"], ["data-toggle", "dropdown"], ["href", "#"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "ion-person"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "hidden-sm lspace rspace"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMenuComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.links; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.fullName(); _ck(_v, 5, 0, currVal_0); }); }
export function View_UserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-menu", [], null, null, null, View_UserMenuComponent_0, RenderType_UserMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.UserMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMenuComponentNgFactory = i1.ɵccf("app-user-menu", i6.UserMenuComponent, View_UserMenuComponent_Host_0, { user: "user" }, {}, []);
export { UserMenuComponentNgFactory as UserMenuComponentNgFactory };
