import { ApiClientService } from './api-client.service';
import { Group } from './models';
import * as i0 from "@angular/core";
import * as i1 from "./api-client.service";
export class GroupsService {
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.crudClient = this.apiClient.getCrudClient('groups', true, (json) => new Group().fromJson(json), (group) => group.toParamObject());
    }
}
GroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupsService_Factory() { return new GroupsService(i0.ɵɵinject(i1.ApiClientService)); }, token: GroupsService, providedIn: "root" });
