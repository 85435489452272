import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessControlRoles } from '../roles.enum';
import { AccessControlService } from '../services/access-control.service';

@Directive({
  selector: '[acRequireRole]'
})
export class AcRequireDirective {

  @Input() set acRequireRole(value: any) {
    this.role = this.getAccessControlRoles(value);
  }

  private role: AccessControlRoles = AccessControlRoles.AppUser;

  private exactMatch: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessControlService: AccessControlService) { }

  ngOnInit() {

    if (this.accessControlService.hasRole(this.role, this.exactMatch)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  getAccessControlRoles(value: any): AccessControlRoles {

    if (typeof value === 'string' &&  value.indexOf('*') === 0) {
      this.exactMatch  = true;
      value = value.replace('*', '');
    }

    if (typeof value === 'number' && AccessControlRoles[value]) {
      return value;
    } else if (typeof value === 'string' && AccessControlRoles[value] >= 0 ) {

      return AccessControlRoles[<string>value];
    } else {
      throw new Error('Invalide Role Vaue: value');
    }
  }

}
