var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { map } from 'rxjs/operators';
import { Report, convertToParams, dismissReport } from '../../../data/report/report.interface';
import { ApiService } from '../../api/services/api.service';
import { API_URL, UPLOAD_API_URL } from '../../../app.constants';
import { HttpService } from '../../api/services/http.service';
import { parse } from '../../reports/parsers/report-comment';
export class ReportsService {
    constructor(apiService, httpService) {
        this.apiService = apiService;
        this.httpService = httpService;
    }
    get url() {
        return `${API_URL}/${this.version}/reports`;
    }
    get uploadUrl() {
        return `${UPLOAD_API_URL}/${this.version}/reports`;
    }
    get version() {
        return '1.0';
    }
    getReports() {
        return this.apiService.get(this.url).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    getReport(Id) {
        return this.apiService.get(`${this.url}/${Id}`).pipe(map(data => Report.fromJson(data.reports)));
    }
    getGroupReports(groupId) {
        return this.apiService.get(`${this.url}?group_id=${groupId}`).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    getUserReports(userId) {
        return this.apiService.get(`${this.url}?user_id=${userId}`).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    saveReport(report) {
        return this.apiService.patch(`${this.url}/${report.id}`, convertToParams(report)).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    dismissReport(report) {
        return this.apiService.patch(`${this.url}/${report.id}`, dismissReport(report)).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    getDismissedReports(query = '') {
        return this.apiService.get(`${API_URL}/${this.version}/search?dismissed=1&query=${query}`).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
    createReport(report) {
        return this.apiService.post(`${this.url}`, convertToParams(report)).pipe(map(data => data.reports.map((r) => Report.fromJson(r))[0]));
    }
    uploadImage(reportId, file) {
        return this.apiService.uploadFile(`${this.uploadUrl}/upload?id=${reportId}`, file, 'image');
    }
    uploadVideo(reportId, file) {
        return this.apiService.uploadFile(`${this.uploadUrl}/upload?id=${reportId}`, file, 'video');
    }
    /**
     * Cancel SOS
     */
    cancelSOS(id) {
        return this.apiService.post(`${this.url}/${id}/cancel-sos`, {}).pipe(map(data => data.reports.map((r) => Report.fromJson(r))[0]));
    }
    /**
     * Comments
     */
    getComments(reportId) {
        return this.httpService.get(`${this.url}/${reportId}/comments`).pipe(map(response => {
            return response['data']['comments']
                .map(parse)
                .sort((a, b) => a.created.getTime() - b.created.getTime());
        }));
    }
    postComment(report_id, comment) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post(`${this.url}/${report_id}/comments`, { comment })
                .toPromise();
        });
    }
    updateComment(report_id, comment_id, changes) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.patch(`${this.url}/${report_id}/comments/${comment_id}`, changes)
                .toPromise();
        });
    }
    getPaginatedReports(page, count, dismissedReports, filters, hasPic) {
        const dismissed = dismissedReports ? 'true' : 'false';
        const params = { page, count, dismissed };
        if (!!filters && filters.types.length > 0) {
            let i = 0;
            filters.types.forEach(t => {
                params['type'] = t + (i > 0 ? ',' + params['type'] : '');
                i++;
            });
        }
        if (!!filters && filters.urgencies.length > 0) {
            let i = 0;
            filters.urgencies.forEach(t => {
                params['urgency'] = t + (i > 0 ? ',' + params['urgency'] : '');
                i++;
            });
        }
        if (!!filters && filters.countries.length > 0) {
            let i = 0;
            filters.countries.forEach(t => {
                params['country_code'] = t + (i > 0 ? ',' + params['country_code'] : '');
                i++;
            });
        }
        if (!!filters && filters.messages.length > 0) {
            params['query'] = encodeURIComponent(filters.messages[0]);
        }
        if (!!hasPic && hasPic === true) {
            params['has_image'] = true;
        }
        return this.httpService.get(this.url, { params }).pipe(map((response) => {
            const pagination = response['meta']['reports']['paging'];
            return {
                pagination: {
                    total: pagination['nbResults'],
                    page: pagination['page'],
                    count: pagination['count'],
                    max: pagination['maxPerPage'],
                    isLastPage: pagination['isLastPage'],
                },
                list: response['data']['reports'].map((r) => Report.fromJson(r)),
            };
        }));
    }
    getPaginatedUserReaports(user_id, page, count) {
        return this.httpService.get(this.url, { params: { page, count, user_id } }).pipe(map((response) => {
            const pagination = response['meta']['reports']['paging'];
            return {
                pagination: {
                    total: pagination['nbResults'],
                    page: pagination['page'],
                    count: pagination['count'],
                    max: pagination['maxPerPage'],
                    isLastPage: pagination['isLastPage'],
                },
                list: response['data']['reports'].map((r) => Report.fromJson(r)),
            };
        }));
    }
    getPaginatedUserReports(user_id, page, count = 10) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.get(this.url, { params: { page, count, user_id } }).toPromise();
            const pagination = response['meta']['reports']['paging'];
            return {
                list: response['data']['reports'].map((r) => Report.fromJson(r)),
                total: pagination['nbResults'],
                page: pagination['page'],
                count: pagination['count'],
                max: pagination['maxPerPage'],
                isLastPage: pagination['isLastPage']
            };
        });
    }
    acknowledgeReport(report_id, user_id) {
        return this.httpService.post(`${this.url}/${report_id}/acknowledge`);
    }
    escalate(report_id, user_id) {
        return this.httpService.post(`${this.url}/${report_id}/escalate?user_id=${user_id}`);
    }
    getLatestUsersReports(user_ids, since) {
        return this.apiService.get(`${this.url}/latest?since=${since.toISOString()}&user_ids=${user_ids.join(',')}`).pipe(map(data => data.reports.map((r) => Report.fromJson(r))));
    }
}
