import { ReportComment } from '../models/report-comment';
import { User, UserFromApi } from 'data/user/user.interface';

export interface APIReportComment {
  Id: string;
  UserId: string;
  Model: string;
  ModelId: string;
  Comment: string;
  Created: string;
  Modified: string;
  Users: UserFromApi[];
}

export const parse = (input: APIReportComment): ReportComment => {
  return {
    id: input.Id,
    userId: input.UserId,
    model: input.Model,
    modelId: input.ModelId,
    comment: input.Comment,
    created: new Date(input.Created),
    modified: new Date(input.Modified),
    author: User.fromJson(input.Users[0]),
  };
};
