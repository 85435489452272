import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { ToastService } from '../services/toast.service';
import { SessionService } from '../../session/services/session.service';
import { AccessControlRoles } from '../roles.enum';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private sessionService: SessionService,
    private toastService: ToastService,
    private router: Router,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (!this.sessionService.isAuthenticated()) {
      this.toastService.error('Unauthorized. Redirecting to Login.');
      await this.sessionService.login();
      return this.sessionService.isAuthenticated();
    }

    const role = this.sessionService.getUserRole();
    const userId = this.sessionService.getCurrentUserId();

    if ( role === AccessControlRoles.AppUser && route.params.id !== userId && state.url.startsWith('/settings')) {

      this.toastService.error('Unauthorized');
      this.router.navigate(['/auth/welcome/']);
      //this.router.navigate(['/users/view/' + this.sessionService.getCurrentUserId()]);
      return false;
    }

    return true;
  }

}
