export const environment = {
  production: true,
  hmr: false,
  CHAT_NOTIFICATION_THROTTLE: 10000, // in ms
  RELATIVE_DATE_WEEKS: 0.5,
  API_URL: 'https://qa-api-web.navigo.global',
  API_POLLING_URL: 'https://qa-api-data.navigo.global',
  MAPBOX_TOKEN: 'pk.eyJ1IjoibGlnaHRob3JzZS1vcHMiLCJhIjoiY2o4NjQ5eGRrMHMxNTJxbHNvZ2V0OHpzbiJ9.Tv0RsbBSVz257Wg5hu5AbA',
  UPLOAD_API_URL: 'https://qa-api-files.navigo.global',
  APP_NAMESPACE: 'navigo/qa',
  DASHBOARD_REFRESH_INTERVAL: 30000, // in ms
  GOOGLE_MAPS_API: 'AIzaSyCapdb6aVGk_2QwmaV9Q-MgABsBPX1tQE4',
  PUBNUB_PUBLISH_KEY: 'pub-c-918094ae-4ee6-48df-a360-2604696e4e8f',
  PUBNUB_SUBSCRIBE_KEY: 'sub-c-32bd091c-9f11-11e8-944c-22e677923cb5',
  IMPACTS_URL: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/acled',
  HEARTLAND_PUBLICKEY: 'pkapi_cert_B4MtbRmEtN2NATrvzb',
  USGS_URL: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/usgs',
  GDACS_URL: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/gdacs',
  IA_TERROR: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-terrorism',
  IA_FOOD: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-food',
  IA_INDUSTRIAL: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-industrial',
  IA_JOURNALISTS: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-journalists',
  IA_KIDNAPPING: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-kidnapping',
  IA_SPORTS: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-sports',
  IA_TRAFFICKING: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-trafficking',
  IA_TRAVEL: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-travel',
  IA_DISEASE: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-disease',
  IA_CHURCH: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-church',
  IA_AVIATION: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/ia-aviation',
  LIVEUA: 'https://navigo-asset-qa.s3.eu-west-1.amazonaws.com/impacts/liveua',
  STATS_API_URL: 'https://qa-services.navigo.global/regions-api',
  TRIPS_API: 'https://qa-services.navigo.global/navigo-trips-api/1.0',
  SSO_CONFIG: {
    "authServerUrl": "https://qa-login.navigo.global/auth",
    "clientId": "qa-web-client",
    "realm": "navigo",
    "publicClient": null,
    "confidentialPort": null,
    'minValidityRefresh': null,
  }
};
