import { Component, OnInit } from '@angular/core';
import { RiskIndexesService } from 'app/risk-indexes/services/risk-indexes.service';
import { DrawerService } from '../services/drawer.service';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.sass']
})
export class CountryListComponent implements OnInit {
  riskIndexes: any;
  filteredRiskIndexes: any;
  loading = true;
  filterText = '';
  comparisons = [];
  comparisonsLink = '';
  trendLink = '';
  constructor(
    private riskIndexesService: RiskIndexesService,
    private drawerService: DrawerService
  ) {

  }

  ngOnInit() {
    this.loadIndexes();
  }

  async loadIndexes() {
    await this.riskIndexesService.listIndexes().subscribe(indexes => {
      this.riskIndexes = indexes
      .sort((a, b) => a.Country.localeCompare(b.Country))
      .filter(d => { return d.Id !== 'WW'; });
      this.filter(null);
      this.loading = false;
    });
  }

  loadDrawer(code: string) {
    this.drawerService.openDrawer(code);
  }

  public calculateRiskLevel(level: number) {
    if (level > 9.0 ) { return 'critical'; }
    if (level > 7.0 ) { return 'high'; }
    if (level > 5.0 ) { return 'medium'; } else { return 'lower'; }
  }

  toggleComparison(code: string) {
    const index = this.comparisons.indexOf(code);
    this.comparisonsLink = '';
    this.trendLink = '';
    if (index > -1) {
      this.comparisons.splice(index, 1);
    } else {
      if (this.comparisons.length > 5) { alert('You can compare a maximum of 6 countries at a time'); return; }
      this.comparisons.push(code);
    }

    this.comparisons.forEach(ctry => {
      this.comparisonsLink += ctry;
      this.trendLink += ctry + ',';
    });
  }

  detectActive(code: string) {
    if (this.comparisons.indexOf(code) > -1) {return true; }
  }


  detectLevel(title: string) {
    if (!title) {
      return 'lower';
    }
    if (title.match('Level 2')) {
      return 'medium';
    }
    if (title.match('Level 3')) {
      return 'high';
    }
    if (title.match('Level 4')) {
      return 'critical';
    }
    return 'lower';
  }

  filter(event: Event) {
    const text = this.filterText.trim().toLowerCase();

    if (text) {
      this.filteredRiskIndexes = this.riskIndexes.filter(d => {
        return (d.Country).toLowerCase().indexOf(text) >= 0;
      });
    } else {
      this.filteredRiskIndexes = this.riskIndexes;
    }

  }

  trimAdvisory(advisory: string) {
    if (!advisory) { return; }
    const s1 = advisory.substring(advisory.indexOf('Level'));
    return s1.trim();
  }
}
