<header role="banner" id="header-area">

  <nav class="navbar navbar-default" id="primary-navigation" role="navigation">
    <div class="container-fluid">
      <div class="navbar-header">

        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-navbar-collapse-1" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <div class="navbar-brand" routerLink="/dashboard" style="overflow:hidden">
          <img src="/assets/lockup-light.svg" class="logo img-responsive"/>
        </div>

      </div>

      <div class="collapse navbar-collapse" id="bs-navbar-collapse-1" *ngIf="user">
        <ul class="nav navbar-nav" *acRequireRole="'CrisisManager'">
          <li routerLinkActive="active">
            <a class="nav-link" routerLink="/dashboard">Home</a>
          </li>
          <!--
          TEMP HIDE INTEL PAGE
          <li routerLinkActive="active">
            <a class="nav-link" routerLink="/intel/articles">Intel <span class="label label-default">new</span></a>
          </li>
          -->
          <li routerLinkActive="active">
            <a class="nav-link" routerLink="/users">People</a>
          </li>
          <li routerLinkActive="active">
            <a class="nav-link" routerLink="/reports">Reports <span *ngIf="reportsCount" class="label label-default">{{reportsCount}}</span></a>
          </li>
          <li routerLinkActive="active">
            <a class="nav-link" routerLink="/issues">Issues <span *ngIf="issuesCount" class="label label-warning">{{issuesCount}}</span></a>
          </li>
          <li routerLinkActive="active" class="hidden">
            <a class="nav-link" routerLink="/call-center">Calls</a>
          </li>
        </ul>

        <app-user-menu [user]="user"></app-user-menu>
        <form class="navbar-form navbar-right">
          <app-alert-indicator *acRequireRole="'CrisisManager'" [count]="notificationsCount"></app-alert-indicator>
        </form>
      </div>

    </div>

  </nav>

</header>
