import { ServiceObject } from './service-object';
import { User } from '../../users/models/user';
import { HsasUrgencyLevel } from 'app/common/enums/hsas-urgency-level';

export class Issue extends ServiceObject {

  issueUsers: any[];
  meta: any;
  model: string;
  type: string;
  id: string;
  title: string;
  modified: Date;
  geoJson: string;
  dismissed: Date;
  created: Date;
  foreignKey: string;
  urgency: number;
  customerId: string;
  related: any;
  displayUsers: boolean;
  location: any;
  hsasLevel?: HsasUrgencyLevel;

  toParamObject(): any {
    return {
      customer_id: this.customerId,
      model: this.model,
      type: this.type,
      // user_id: this.userId,
      id: this.id,
      title: this.title,
      modified: this.modified,
      geo_json: this.geoJson,
      dismissed: this.dismissed,
      created: this.created,
      foreign_key: this.foreignKey,
      urgency: this.urgency,
    };
  }

  fromJson(data: any) {
    this.customerId = data.CustomerId;
    this.model = data.Model;
    this.type = data.Type;
    // this.userId = data.UserId;
    this.modified = data.Modified;
    this.geoJson = data.GeoJson;
    this.dismissed = data.Dismissed ? new Date(data.Dismissed) : null;
    this.created = new Date(data.Created);
    this.foreignKey = data.ForeignKey;
    this.urgency = data.Urgency;
    this.id = data.Id;
    this.title = data.Title;
    this.issueUsers = data.IssueUsers;
    this.related = data.Related;
    this.displayUsers = false;
    this.hsasLevel = data.Urgency;

    if (this.related && this.related.Location) {

      this.location = {
        name: null,
        id: 'FIXME-LOCATION-ID',
        country: null,
        coordinates: getPositionFromPoint(this.related.Location),
      };

    } else {
      this.location = {};
    }
    return this;
  }
}


function getPositionFromPoint(geoJson): any {
    let point: any;
    try {
      point = JSON.parse(geoJson);
    } catch (e) {
      console.error('Error parsing GeoJson', geoJson, e);

      return null;
    }

    const [longitude, latitude] = point.coordinates;
    return {
      longitude, latitude
    };
  }
