<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <h1>Oops!</h1>
      <h2>We can't seem to find<br class="hidden-xs" /> the page you're looking for.</h2>
      <p>Error Code: 404</p>
      <p>Here are some helpful links instead:</p>
      <ul>
        <li><a routerLink="/dashboard">
          <div class="ion-home text-center not-found-icon"></div>
          <span class="lspace">Home</span></a>
        </li>
        <li><a routerLink="/support/center">
          <div class="ion-help-circled text-center not-found-icon"></div>
          <span class="lspace">Support Center</span></a>
        </li>
        <li><a routerLink="/sitemap">
          <div class="ion-grid text-center not-found-icon"></div>
          <span class="lspace">Sitemap</span></a>
        </li>
        <li><a routerLink="/support/contact">
          <div class="ion-email text-center not-found-icon"></div>
          <span class="lspace">Contact Us</span></a>
        </li>
      </ul>
    </div>
    <div class="col-sm-6">
      <img src="/assets/images/404d.jpg" class="img-responsive"/>
    </div>
  </div>
</div>
