import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Models
import { RequestParams } from '../models/request-params.interface';

// Util
import { asHttpParams } from '../util/as-http-params';
import { asFormData } from '../util/as-form-data';

@Injectable()
export class HttpService {

  constructor(
    private http: HttpClient,
  ) { }

  public get(url: string, options: {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: RequestParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } = {}) {
    return this.http.get(url, {
      ...options,
      params: asHttpParams(options.params)
    });
  }

  public post(url: string, body: any = {}, options: {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: RequestParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } = {}) {
    return this.http.post(url, asFormData(body), {
      ...options,
      params: asHttpParams(options.params),
      headers: new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded')
    });
  }

  public delete(url: string, options: {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: RequestParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } = {}) {
    return this.http.delete(url, {
      ...options,
      params: asHttpParams(options.params),
      headers: new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded')
    });
  }

  public patch(url: string, body: any = {}, options: {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: RequestParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } = {}) {
    return this.http.patch(url, asFormData(body), {
      ...options,
      params: asHttpParams(options.params),
      headers: new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded')
    });
  }

}
