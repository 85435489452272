import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams} from '@angular/common/http';

import { Observable } from 'rxjs';

// Services
import { SessionService } from '../session/services/session.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private sessionService: SessionService,
    ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // TODO: Non-critical Angular bug duplicating params
    // @see https://github.com/angular/angular/issues/18812

    if (req.url.includes('.amazonaws.com')) {
      // Do not add the header
      return next.handle(req);
    }

    req.params.keys().forEach(key => {
      const value = req.params.get(key);
      if (typeof value === typeof undefined || value === null || value === 'null') {
        console.error('invalid value in params', req.method, {key, value}, req.urlWithParams);
      }
    });

    let newBody = req.body;
    // Certain post requests with a single param in the body do no have a .body.keys() method available
    if (['POST', 'PATCH'].includes(req.method) && req.body && typeof req.body.keys === 'function') {
      req.body.keys().forEach(key => {
        const value = req.body.get(key);
        if (typeof value === typeof undefined || value === null || value === 'null') {
          console.error('invalid param in body', req.method, {key, value}, req.body);
          // These values need to be sent so we can "clear" the fields the represent
          // Sending a JS null would result in a "null" string being stored due to the transformation into query params
          // on the service side
          newBody = newBody.set(key, ''); // Set empty values for null/undefined params
        }
      });
    }

    const changedReq = req.clone({
        setHeaders: {'Authorization': `Bearer ${this.sessionService.getCurrentToken()}`},
        body: newBody,
    });

    return next.handle(changedReq);
  }
}
