var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../api/services/http.service';
import * as moment from 'moment/moment';
import { API_POLLING_URL, IMPACTS_URL, GDACS_URL, USGS_URL, IA_TERROR, IA_FOOD, IA_INDUSTRIAL, IA_JOURNALISTS, IA_KIDNAPPING, IA_SPORTS, IA_TRAFFICKING, IA_TRAVEL, IA_DISEASE, IA_CHURCH, IA_AVIATION, LIVEUA } from '../../../app.constants';
import { Issue } from '../../common/models/issue';
import { Place } from '../../common/models/place';
import { Report } from 'data/report/report.interface';
import { User } from '../../users/models/user';
import { UserPing } from '../models/user-ping';
// Parsers
import { parse as parseImpact } from '../../impacts/parsers/impact.parser';
import { parse as parseLocation } from '../../common/models/location.interface';
import { parse as parseRiskType } from '../../risk-types/parsers/risk-type.parser';
export class UIService {
    constructor(httpService, http) {
        this.httpService = httpService;
        this.http = http;
    }
    get version() {
        return '1.0';
    }
    get url() {
        return `${API_POLLING_URL}/${this.version}/ui`;
    }
    get pullingUrl() {
        return `${API_POLLING_URL}/${this.version}/ui`;
    }
    dashboard(fromDate, toDate = new Date(), usePullingUrl = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = usePullingUrl ? this.pullingUrl : this.url;
            const params = `start=${this.encodeDate(fromDate)}&end=${this.encodeDate(toDate)}`;
            const response = yield this.httpService.get(`${url}/dashboard?${params}`).toPromise();
            const data = response.data;
            const uiData = {
                startDate: fromDate,
                endDate: toDate,
                impacts: data.impacts.map(i => parseImpact(i)),
                issues: data.issues.map(i => new Issue().fromJson(i)),
                locations: data.locations.map(i => parseLocation(i)),
                reports: data.reports.map(i => Report.fromJson(i)),
                riskTypes: data.risktypes.map(i => parseRiskType(i)),
                users: data.users.map(i => new User().fromJson(i)),
                pings: [],
                places: (data.places || []).map(i => new Place().fromJson(i)),
            };
            // Consolidate pings
            const pings = uiData.users
                .map(user => user.devices
                .map(device => device.lastPing
                .map(ping => {
                const userPing = new UserPing();
                Object.assign(userPing, ping);
                userPing.userId = user.id;
                userPing.deviceId = device.id;
                return userPing;
            })))
                .reduce((a, b) => [...a, ...b], [])
                .reduce((a, b) => [...a, ...b], []);
            uiData.pings = pings;
            const s3Impacts = yield this.getS3ImpactsData();
            uiData.impacts = arrayDistinct([...uiData.impacts, ...s3Impacts], i => i.id);
            return uiData;
        });
    }
    encodeDate(input) {
        return encodeURIComponent(moment.utc(input).format('YYYY-MM-DD'));
    }
    getS3ImpactsData(all = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const latestOrTotal = all ? 'total' : 'latest';
            let acled;
            let usgs;
            let gdacs;
            let ia_terror;
            let ia_food;
            let ia_industrial;
            let ia_journalists;
            let ia_kidnapping;
            let ia_sports;
            let ia_trafficking;
            let ia_travel;
            let ia_disease;
            let ia_church;
            let ia_aviation;
            let liveua;
            acled = yield this.http.get(`${IMPACTS_URL}/${latestOrTotal}`).toPromise();
            gdacs = yield this.http.get(`${GDACS_URL}/${latestOrTotal}`).toPromise();
            usgs = yield this.http.get(`${USGS_URL}/${latestOrTotal}`).toPromise();
            ia_terror = yield this.http.get(`${IA_TERROR}/${latestOrTotal}`).toPromise();
            ia_food = yield this.http.get(`${IA_FOOD}/${latestOrTotal}`).toPromise();
            ia_industrial = yield this.http.get(`${IA_INDUSTRIAL}/${latestOrTotal}`).toPromise();
            ia_journalists = yield this.http.get(`${IA_JOURNALISTS}/${latestOrTotal}`).toPromise();
            ia_kidnapping = yield this.http.get(`${IA_KIDNAPPING}/${latestOrTotal}`).toPromise();
            ia_sports = yield this.http.get(`${IA_SPORTS}/${latestOrTotal}`).toPromise();
            ia_trafficking = yield this.http.get(`${IA_TRAFFICKING}/${latestOrTotal}`).toPromise();
            ia_travel = yield this.http.get(`${IA_TRAVEL}/${latestOrTotal}`).toPromise();
            ia_disease = yield this.http.get(`${IA_DISEASE}/${latestOrTotal}`).toPromise();
            ia_church = yield this.http.get(`${IA_CHURCH}/${latestOrTotal}`).toPromise();
            ia_aviation = yield this.http.get(`${IA_AVIATION}/${latestOrTotal}`).toPromise();
            liveua = yield this.http.get(`${LIVEUA}/${latestOrTotal}`).toPromise();
            // HttpResponse now parses the response body automatically
            const rawImpacts = [
                ...acled,
                ...usgs
            ];
            if (ia_industrial) {
                rawImpacts.push(...ia_industrial);
            }
            if (ia_sports) {
                rawImpacts.push(...ia_sports);
            }
            if (ia_travel) {
                rawImpacts.push(...ia_travel);
            }
            if (ia_terror) {
                rawImpacts.push(...ia_terror);
            }
            if (ia_journalists) {
                rawImpacts.push(...ia_journalists);
            }
            if (ia_food) {
                rawImpacts.push(...ia_food);
            }
            if (ia_disease) {
                rawImpacts.push(...ia_disease);
            }
            if (ia_church) {
                rawImpacts.push(...ia_church);
            }
            if (ia_kidnapping) {
                rawImpacts.push(...ia_kidnapping);
            }
            if (ia_trafficking) {
                rawImpacts.push(...ia_trafficking);
            }
            if (ia_aviation) {
                rawImpacts.push(...ia_aviation);
            }
            if (liveua) {
                rawImpacts.push(...liveua);
            }
            const impacts = rawImpacts.map(impact => parseImpact(impact));
            return impacts;
        });
    }
}
export class DashboardUIData {
    constructor() {
        this.impacts = [];
        this.issues = [];
        this.locations = [];
        this.reports = [];
        this.riskTypes = [];
        this.users = [];
        this.pings = [];
        this.places = [];
    }
}
