import { Component, OnInit } from '@angular/core';
import { RiskIndexesService } from 'app/risk-indexes/services/risk-indexes.service';
import { DrawerService } from '../services/drawer.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-compare-countries',
  templateUrl: './compare-countries.component.html',
  styleUrls: ['./compare-countries.component.sass']
})
export class CompareCountriesComponent implements OnInit {
  riskIndexes: any;
  filteredRiskIndexes: any;
  loading = true;
  filterText = '';
  countries = [];
  countryCodes = [];
  overAllRisks = ['HazardAndExposure', 'Vulnerability', 'LackOfCopingCapacity', 'Human'];
  naturalDisasterRisks = ['Drought', 'Earthquake', 'TropicalCyclone', 'Tsunami'];

  maxPop = 1;
  constructor(
    private riskIndexesService: RiskIndexesService,
    private drawerService: DrawerService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['countries']) { this.loadCountries(params['countries']); }
    });

  }

  async loadCountries(countries: string) {
    this.countryCodes = countries.match(/.{1,2}/g);
    this.countryCodes.forEach(code => {
      const country = {};

      this.riskIndexesService.getFactbook(code).subscribe(result => {
        country['factbook'] = result;
      });
      this.riskIndexesService.getInform(code).subscribe(result => {
        country['inform'] = result;
      });
      this.riskIndexesService.getIndex(code).subscribe(result => {
        country['index'] = result;
      });
      this.countries[code] = country;
    });
    this.loading = false;
  }

  setMaxPop(pop: number) {
    if (!pop) { return; }
    if (pop > this.maxPop) { this.maxPop = pop; }
    return pop;
  }
  percentPop(pop: number) {
    return 100 * pop / this.maxPop;
  }
  inversePop(pop: number) {
    return 1 - (100 * pop / this.maxPop);
  }

  removeCountry(code: string) {
    const index = this.countryCodes.indexOf(code);
    if (index > -1) {this.countryCodes.splice(index, 1); }
  }

  loadDrawer(code: string) {
    this.drawerService.openDrawer(code);
  }

  public splitTitle(title: string) {
    // custom overrides
    if (title === 'Human') { return 'Human Conflict Risk'; };
    return title.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }

  public calculateRiskLevel(level: number) {
    if (level > 9.0 ) { return 'critical'; }
    if (level > 7.0 ) { return 'high'; }
    if (level > 5.0 ) { return 'medium'; } else { return 'lower'; }
  }

  detectLevel(title: string) {
    if (!title) {
      return 'lower';
    }
    if (title.match('Level 2')) {
      return 'medium';
    }
    if (title.match('Level 3')) {
      return 'high';
    }
    if (title.match('Level 4')) {
      return 'critical';
    }
    return 'lower';
  }

  trimAdvisory(advisory: string) {
    if (!advisory) { return; }
    const s1 = advisory.substring(advisory.indexOf('Level'));
    return s1.trim();
  }
}
