// Models
import { Impact } from '../models/impact.interface';
import { ImpactSource } from '../models/impact-source.interface';

// Parsers
import { parse as parseRiskType } from '../../risk-types/parsers/risk-type.parser';
import { RiskTypeSource } from '../../risk-types/models/risk-type-source.interface';

export const validate = (i: ImpactSource) => {
  if (!i.Id) {
    throw new Error('Missing ID');
  }

  if (!i['RiskType']) {
    throw new Error('Missing RiskType property');
  }

  try {
    JSON.parse(i.Area);
  } catch (e) {
    throw new Error('Area is not valid JSON');
  }

  try {
    JSON.parse(i.Location);
  } catch (e) {
    throw new Error('Location is not valid JSON');
  }
};

export const parse = (input: ImpactSource): Impact => {

  // try {
  //   validate(input);
  // } catch (e) {
  //   console.warn('Invalid Impact', e, input);
  //   return null;
  // }

  return {
    id: input.Id,
    risk_type: input.RiskType ? parseRiskType(input.RiskType) : null,
    risk_type_id: input.RiskTypeId,
    area: JSON.parse(input.Area),
    location: isJson(input.Location) ? JSON.parse(input.Location) : null,
    date: new Date(input.Date || new Date().toISOString()),
    start_time: new Date(input.StartTime || new Date().toISOString()),
    end_time: new Date(input.EndTime || new Date().toISOString()),
    country_code: input.CountryCode,
    title: input.Title || 'No Title',
    level: input.Level,
    hsasLevel: input.Level,
    event_type: input.EventType,
    actor_1: input.Actor1,
    actor_1_assoc: input.Actor1Assoc,
    actor_1_type: input.Actor1Type,
    actor_2: input.Actor2,
    actor_2_assoc: input.Actor2Assoc,
    actor_2_type: input.Actor2Type,
    fatalities: input.Fatalities,
    interaction: input.Interaction,
    origin: input.Origin,
    public: input.Public,
    notes: input.Notes,
    notify_on_expire: input.NotifyOnExpire,
    notify_on_proximity: input.NotifyOnProximity,
    image: input.Image
  };

};

function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
