import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {getPercentClassSuffix, getPercentWidthStyle} from 'util/Stylers';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-percent-bar-base',
  templateUrl: './percent-bar-base.component.html',
  styleUrls: ['./percent-bar-base.component.sass']
})
export class PercentBarBaseComponent {

  @Input() percent: number;
  @Input() small: boolean;
  @Input() iconClasses: string;


  constructor(
    private domSanitizer: DomSanitizer,
  ) {
  }

  public getPercentStyle() {
    return getPercentWidthStyle(this.domSanitizer, Math.abs(this.percent));
  }

  public getProgressBarClassSuffix() {
    return getPercentClassSuffix(Math.abs(this.percent));
  }

  formatAbs(value: number) {
    return Math.abs(value);
  }
}
