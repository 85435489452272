import { ServiceObject } from './service-object';
import {dateFromJson, fromJson, initFromObject} from 'util/from-json';
import * as moment from 'moment/moment';
export class Incident extends ServiceObject {

  id: string;
  customerId: string;
  status: number;
  title: string;
  description: string;
  incidentLevel: number;
  startDate: Date;
  endDate: Date;
  GeoJSON: any;
  zoom: number;
  countryCode: string;
  peopleInvolved: number;
  injuries: number;
  deaths: number;
  modified: Date;
  created: Date;


  toParamObject(): any {
    return {
      id: this.id,
      customer_id: this.customerId,
      title: this.title,
      status: this.status,
      description: this.description,
      incident_level: this.incidentLevel,
      start_date: encodeURIComponent(moment(this.startDate).utc().format('YYYY-MM-DD')),
      end_date: encodeURIComponent(moment(this.endDate).utc().format('YYYY-MM-DD')),
      geo_json: this.GeoJSON,
      zoom: this.zoom,
      country_code: this.countryCode,
      people_involved: this.peopleInvolved,
      injuries: this.injuries,
      deaths: this.deaths
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.title = data.Title;
    this.status = data.Status;
    this.description = data.Description;
    this.incidentLevel = data.IncidentLevel;
    this.startDate = data.StartDate;
    this.endDate = data.EndDate;
    this.GeoJSON = data.GeoJson;
    this.zoom = data.Zoom;
    this.countryCode = data.CountryCode || '';
    this.peopleInvolved = data.PeopleInvolved;
    this.injuries = data.Injuries;
    this.deaths = data.Deaths;
    this.modified = dateFromJson(data.Modified);
    this.created = dateFromJson(data.Created);
    return this;
  }
}
