import { ServiceObject } from './service-object';

export class LastPing extends ServiceObject {
  id: string;
  batteryLevel: number;
  cellSignalStrength: number;
  appVersion: number;
  created: Date;
  awarenessActivity: number;
  latitude: number;
  longitude: number;
  country: string;

  toParamObject(): any {
    return {};
  }

  fromJson(data: any) {
    this.batteryLevel = parseInt(data.BatteryLevel, 10);
    this.appVersion = parseInt(data.AppVersion, 10);
    this.cellSignalStrength = parseInt(data.CellSignalStrength, 10);
    this.created = new Date(data.Created);
    this.awarenessActivity = parseInt(data.AwarenessActivity, 10);
    this.latitude = parseFloat(data.Latitude);
    this.longitude = parseFloat(data.Longitude);
    this.country = data.Country;
    this.id = data.Id || `${data.DeviceId}-${data.Timestamp}`;
    return this;
  }

}
