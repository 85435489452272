import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { NavigoPagingMeta } from './models/navigo-paging-meta';
import { PagedResponse, NavigoResponse } from './models/navigo-response';
import { CustomEncoder } from './ng-encoder';
import { ClientConfigService } from './client-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(
    private httpClient: HttpClient,
    private clientConfigService: ClientConfigService,
) { }

  getCrudClient<ServiceName extends string, paged extends boolean, Model>
  (serviceName: ServiceName, paged: paged, fromJson: (value) => Model, toParam: (value: Model) => any, baseRoute: string = null)
  {
    return new NavigoCrudClient(this, serviceName, paged, fromJson, toParam, baseRoute);
  }

  async get<T>(url: string, queryParams: any = null): Promise<HttpResponse<T>> {
    return this.request({method: 'GET', url: url, queryParams});
  }

  async post<T>(url: string, body: any, queryParams: any = null): Promise<HttpResponse<T>> {
    return this.request({method: 'POST', url, body, queryParams});
  }

  async patch<T>(url: string, body: any, queryParams: any = null): Promise<HttpResponse<T>> {
    return this.request({method: 'PATCH', url, body, queryParams});
  }

  async delete<T>(url: string, queryParams: any = null): Promise<HttpResponse<T>> {
    return this.request({method: 'DELETE', url, queryParams});
  }

  async request(request: {
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
    url: string,
    queryParams?: any,
    body?: any,
    bodyFormat?: 'json' | 'params'
  }) {

    const body = request.body && request.bodyFormat == 'params' ? this.generateParams(request.body) : null; 

    const r = new HttpRequest(
      request.method,
      this.clientConfigService.baseUri + "/1.0/" + request.url,
      body,
      {
        params: request.queryParams ? this.generateParams(request.queryParams) : null,
        headers: this.generateHeaders(request.bodyFormat),
      }
    );

    const response = await this.httpClient.request(r).toPromise();

    return response as any;
  }

  private generateParams(obj: Object): HttpParams {
    let params = new HttpParams({encoder: new CustomEncoder()});

    if (obj) {
      Object.keys(obj).forEach((k) => {
        let value = obj[k];

        if (typeof value === 'undefined' || value == '') {
          return; // Skip undefined keys
        }

        if (value instanceof Date) {
          value = value.toISOString();
        }

        params = params.append(k, value);
      });
    }
    return params;
  }

  private generateHeaders(requestType: 'json' | 'params'): HttpHeaders {
    let headers = new HttpHeaders();

    const token = '';

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    if (requestType == 'json') {
      headers = headers.append('Content-Type', 'application/json');
    } else if (requestType == 'params') {
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    return headers;
  }

}

export class NavigoCrudClient<ServiceName extends string, paged extends boolean, Model> {
  constructor(
    private client: ApiClientService,
    private serviceName: ServiceName,
    private paged: paged,
    private fromJson: (value) => Model,
    private toParam: (value: Model) => any,
    private baseRoute: string = null
  ) {
    if(!baseRoute) {
      this.baseRoute = serviceName;
    }
  }

  async get(page: number = 1, count: number = 20, filter?: any): Promise<PagedResponse<Model[]>> {
    const response = await this.client.get<NavigoResponse<any, any[], true>>(`${this.baseRoute}`, {
      count,
      page,
      ...(filter instanceof Object ? filter : {}) 
    });

    this.normalizeNavigoResponse(response, this.serviceName);

    return {
      list: response.body.data[this.serviceName].map(d => this.fromJson(d) as any),
      paging: response.body.meta[this.serviceName].paging
    };
  }

  async read(id: string): Promise<Model> {
    const response = await this.client.get<NavigoResponse<any, any, false>>(`${this.baseRoute}/${id}`);
    return this.fromJson(response.body.data[this.serviceName]);
  }

  async create(data: Model): Promise<Model> {
    const response = await this.client.post<NavigoResponse<any, any, false>>(`${this.baseRoute}`, this.toParam(data));
    return this.fromJson(response.body.data[this.serviceName]);
  }

  async save<Returns extends boolean>(id: string, data: Model): Promise<Model> {
    const response = await this.client.patch<NavigoResponse<any, any, false>>(`${this.baseRoute}/${id}`, this.toParam(data));
    return this.fromJson(response.body.data[this.serviceName]);
  }

  async delete(id: string): Promise<void> {
    await this.client.delete<NavigoResponse<any, any, false>>(`${this.baseRoute}/${id}`);
  }

  private normalizeNavigoResponse(r: HttpResponse<any>, serviceName: string) {

    if(!r.body.data) {
      r.body.data = {}
    }

    if(!r.body.data[serviceName]) {
      r.body.data[serviceName] = null;
    }

    if(!r.body.meta) {
      r.body.meta = {}
    }

    if(!r.body.meta[serviceName]) {
      r.body.meta[serviceName] = {};
    }

    if(!r.body.meta[serviceName]['paging']) {
      r.body.meta[serviceName]['paging'] = {};
    }


  }

}