// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { NotFoundComponent } from './not-found/not-found.component';
import { CountryListComponent } from './advisory/components/country-list/country-list.component';
import { ApplicationLayoutComponent, EmptyLayoutComponent  } from './layout';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/settings',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('../app/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      {
        path: 'activate-device/:code',
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
      },
    ]
  },
  {
    path: '',
    component: ApplicationLayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: 'settings',
      },
      {
        path: 'countries',
        component: CountryListComponent,
      },
      {
        path: 'alerts',
        loadChildren: () => import('../app/alerts/alerts.module').then(m => m.AlertsModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('../app/calendar/calendar.module').then(m => m.CalendarModule),
      },
      {
        path: 'countries',
        loadChildren: () => import('../app/countries/countries.module').then(m => m.CountriesModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('../app/customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'devices',
        loadChildren: () => import('../app/devices/devices.module').then(m => m.DevicesModule),
      },
      {
        path: 'directives',
        loadChildren: () => import('../app/directives/directives.module').then(m => m.DirectivesModule),
      },
      {
        path: 'events',
        loadChildren: () => import('../app/events/events.module').then(m => m.EventsModule),
      },
      {
        path: 'feedback',
        loadChildren: () => import('../app/feedback/feedback.module').then(m => m.FeedbackModule),
      },
      {
        path: 'groups',
        loadChildren: () => import('../app/groups/groups.module').then(m => m.GroupsModule),
      },
      {
        path: 'impacts',
        loadChildren: () => import('../app/impacts/impacts.module').then(m => m.ImpactsModule),
      },
      {
        path: 'invitations',
        loadChildren: () => import('../app/invitations/invitations.module').then(m => m.InvitationsModule),
      },
      {
        path: 'issues',
        loadChildren: () => import('../app/issues/issues.module').then(m => m.IssuesModule),
      },
      {
        path: 'locations',
        loadChildren: () => import('../app/locations/locations.module').then(m => m.LocationsModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('../app/notifications/notifications.module').then(m => m.NotificationsModule),
      },
      {
        path: 'places',
        loadChildren: () => import('../app/places/places.module').then(m => m.PlacesModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('../app/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'risks',
        loadChildren: () => import('../app/risks/risks.module').then(m => m.RisksModule),
      },
      {
        path: 'riskindexes',
        loadChildren: () => import('../app/risk-indexes/risk-indexes.module').then(m => m.RiskIndexesModule),
      },
      {
        path: 'sitemap',
        loadChildren: () => import('../app/sitemap/sitemap.module').then(m => m.SitemapModule),
      },
      {
        path: 'scenarios',
        loadChildren: () => import('../app/scenarios/scenarios.module').then(m => m.ScenariosModule),
      },
      {
        path: 'service-health',
        loadChildren: () => import('../app/service-health/service-health.module').then(m => m.ServiceHealthModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/user-settings/user-settings.module').then(m => m.UserSettingsModule),
      },
      {
        path: 'support',
        loadChildren: () => import('../app/support-center/support-center.module').then(m => m.SupportCenterModule),
      },
      {
        path: 'terms-of-service',
        loadChildren: () => import('../app/tos/tos.module').then(m => m.TosModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../app/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'geo-fences',
        loadChildren: () => import('../app/geo-fence/geo-fence.module').then(m => m.GeoFenceModule),
      },
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
  constructor() {
  }
}
