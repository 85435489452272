import { ServiceObject } from './service-object';
import {Waypoint, WaypointSource} from './waypoint';
import { Reservation } from './reservation';
import { Traveler } from './traveler';
import {TravelerReservation} from "./traveler-reservation";

export class Trip extends ServiceObject {

  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  status: string;
  riskRating: number;
  primaryContactId: string;
  imageUrl: string;
  distance: number;
  notes: string;
  approvedBy: string;
  modified: Date;
  created: Date;
  traveler?: Traveler[];
  waypoint?: Waypoint[];
  reservation?: Reservation[];
  type: string;
  commentCount: number;

  toParamObject(): any {
    return {
      id: this.id,
      name: this.name,
      start_date: this.startDate,
      end_date: this.endDate,
      status: this.status,
      risk_rating: this.riskRating,
      primary_contact_id: this.primaryContactId,
      image_url: this.imageUrl,
      distance: this.distance,
      notes: this.notes,
      approved_by: this.approvedBy,
      created: this.created,
      modified: this.modified,
      type: this.type,
      comment_count: this.commentCount
    };
  }


  fromJson(data: any) {
    this.id = data.Id;
    this.name = data.Name;
    this.startDate = new Date(data.StartDate);
    this.endDate = new Date(data.EndDate);
    this.status = data.Status;
    this.riskRating = data.RiskRating;
    this.primaryContactId = data.PrimaryContactId;
    this.imageUrl = data.ImageUrl;
    this.distance = data.Distance;
    this.notes = data.Notes;
    this.approvedBy = data.ApprovedBy;
    this.modified = data.Modified;
    this.created = new Date(data.Created);
    this.type = data.Type;
    this.commentCount = data.CommentCount;

    this.waypoint = (data.Waypoint || []).map((waypoint) => {
      return new Waypoint().fromJson(waypoint);
    });
    this.traveler = (data.Traveler || []).map((traveler) => {
      return new Traveler().fromJson(traveler);
    });
    this.reservation = (data.Reservation || []).map((reservation) => {
      return new Reservation().fromJson({data: reservation, waypoints: this.waypoint});
    });

    return this;
  }

}
