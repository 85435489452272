import { ServiceObject } from './service-object';
import {dateFromJson, fromJson, initFromObject} from 'util/from-json';
import { Group } from 'app/groups/models/group';

export class GroupUser extends ServiceObject {

  id: string;
  groupId: string;
  userId: string;
  userType: number;
  created: Date;
  level: number;
  group?: Group;

  toParamObject(): any {
    return {
      id: this.id,
      group_id: this.groupId,
      user_id: this.userId,
      user_type: this.userType,
      created: this.created,
      level: this.level,
      group: this.group
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.groupId = data.GroupId;
    this.userId = data.UserId;
    this.userType = data.UserType;
    this.created = dateFromJson(data.Created);
    this.level = data.Level;
    this.group = data.Group;
    return this;
  }
}
