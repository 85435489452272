// https://developers.google.com/android/reference/com/google/android/gms/location/DetectedActivity
// export enum PingActivity {
//   IN_VEHICLE,	// The device is in a vehicle, such as a car.
//   ON_BICYCLE, // The device is on a bicycle.
//   ON_FOOT, // The device is on a user who is walking or running.
//   RUNNING, // The device is on a user who is running.
//   STILL, // The device is still (not moving).
//   TILTING, // The device angle relative to gravity changed significantly.
//   UNKNOWN, // Unable to detect the current activity.
//   WALKING, // The device is on a user who is walking.
// }


/** Enum-like class
 * SPEC DOC: https://github.com/navigo-app/spec/blob/master/models/Pings.md */
export class PersonActivityType {

  private static VALUES: { [id: string]: PersonActivityType; } = {};
  private static VALUES_BY_INT: { [intId: number]: PersonActivityType } = {};

  public static DRIVING   = new PersonActivityType(0, 'Driving', 'ion-model-s');
  public static BICYCLING = new PersonActivityType(1, 'Bicycling'); // todo; added by Karol, verify if makes sense
  public static ON_FOOT   = new PersonActivityType(2, 'On Foot', 'ion-android-walk');
  public static STOPPED   = new PersonActivityType(3, 'Stopped', 'ion-android-hand');
  public static UNKNOWN   = new PersonActivityType(4, 'Unknown', 'ion-help');
  public static TILTING   = new PersonActivityType(5, 'Tilting', 'ion-android-hand');
  public static WALKING   = new PersonActivityType(7, 'Walking', 'ion-android-walk');
  public static RUNNING   = new PersonActivityType(8, 'Running', 'ion-android-walk');
  public static UNKNOWN_ACTIVITY   = new PersonActivityType(-129, '', '');

  // private static VALUES: PersonActivityType[] = [];

  public static valueOf(name: string) {
    if ( ! name ) {
      return undefined;
    }
    return PersonActivityType.VALUES[name.toUpperCase()];
  }

  public static fromInt(intId: number): PersonActivityType {
    return PersonActivityType.VALUES_BY_INT[intId] || PersonActivityType.UNKNOWN_ACTIVITY;
  }

  private constructor(
      public intId: number,
      public name: string,
      public iconName?: string) {
    PersonActivityType.VALUES[name.toUpperCase()] = this;
    PersonActivityType.VALUES_BY_INT[intId] = this;
  }

}

