import { Injectable } from '@angular/core';

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageLightboxComponent } from '../components/image-lightbox/image-lightbox.component';

@Injectable()
export class ImageLightboxService {


  constructor(
    private nbModal: NgbModal
  ) {

  }

  public open(url: string) {
    const modal = this.nbModal.open(ImageLightboxComponent, {windowClass: 'lightbox', size: <any>'lightbox'});
    modal.componentInstance.setImageUrl(url);
  }
}
