// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 3rd party
import { MomentModule } from 'ngx-moment';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';

// Dependencies
import { ApiModule } from '../api/api.module';
import { SosPopupModule } from './sos-popup/index';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';

// Services
import { AuthService } from './services/auth.service';
import { ToastService } from './services/toast.service';
import { AccessControlService } from './services/access-control.service';
import { GeocodingService } from './services/geocoding.service';
import { ImageLightboxService } from './services/image-lightbox.service';
import { RTDService } from './services/rtd.service';


// Pipes
import { FilterByPipe } from './pipes/filter-by.pipe';
import { CoordinatesPipe } from './pipes/Coordinates.pipe';
import { GeocodingPipe } from './pipes/geocoding.pipe';
import { ReverseGeocodingPipe } from './pipes/reverse-geocoding.pipe';

// Components used in multiple places
import { DateLinkComponent } from './components/date-link/date-link.component';
import { PercentBarBaseComponent } from './components/percent-bar-base/percent-bar-base.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { GroupPickerComponent } from './components/group-picker/group-picker.component';

// Components that cannot be isolated in their own modules
import { UserLinkComponent } from './components/user-link/user-link.component';

// Components that should go in their respective modules
import { LocationLinkComponent } from './components/location-link/location-link.component';
import { SortUsersPipe } from './pipes/sort-users.pipe';

// Directives
import { AcRequireDirective } from './directives/ac-require.directive';
import { ImageLightboxComponent } from './components/image-lightbox/image-lightbox.component';
import { ImageLightboxDirective } from './directives/image-lightbox.directive';
import { PasswordValidatorDirective } from './directives/password.directive';
import { IntlTelInputDirective } from './directives/intl-tel-input.directive';
import { AutofocusDirective } from './directives/auto-focus.directive';

// API Services(To resolve circular dependency issues)
import { GroupsService } from './api/groups.service';
import { ReportsService } from './api/reports.service';
import { BadgeCountsService } from './services/badge-counts.service';
import { PlacesService } from './api/places.service';
import { UIService } from './api/ui.service';
import { SortLinesPipe } from './pipes/sort-lines.pipe';
import { SortAlphaPipe } from './pipes/sort-alpha.pipe';
import { UtcDatePipe } from './pipes/utc-date.pipe';

const components = [
  DateLinkComponent,
  LocationLinkComponent,
  UserLinkComponent,
  PercentBarBaseComponent,
  ImageSelectorComponent,
  AcRequireDirective,
  ImageLightboxComponent,
  ImageLightboxDirective,
  PasswordValidatorDirective,
  IntlTelInputDirective,
  AutofocusDirective,
  GroupPickerComponent
];

const pipes = [
  FilterByPipe,
  CoordinatesPipe,
  SortUsersPipe,
  GeocodingPipe,
  ReverseGeocodingPipe,
  SortLinesPipe,
  SortAlphaPipe,
  UtcDatePipe
];

const services = [
  ToastService,
  AuthGuard,
  AuthService,
  AccessControlService,
  RoleGuard,
  GeocodingService,
  ImageLightboxService,
  GroupsService,
  ReportsService,
  BadgeCountsService,
  PlacesService,
  RTDService,
  UIService
];

@NgModule({
  imports: [
    // Angular
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // 3rd party
    MomentModule,
    NguiAutoCompleteModule,
    // Dependencies
    ApiModule
  ],
  declarations: [
    ...components,
    ...pipes
  ],
  exports: [
    ...components,
    ...pipes
  ],
  entryComponents: [
    ImageLightboxComponent
  ]
})
export class SharedModule {
  constructor() {
  }

  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: services,
    };
  }
}
