
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Place } from '../../common/models/place';
import { ApiService } from '../../api/services/api.service';
import { API_URL } from '../../../app.constants';

@Injectable()
export class PlacesService {

  get url() {
    return `${API_URL}/1.0/places`;
  }

  constructor(private apiService: ApiService) {}

  getPlaces(): Observable<Place[]> {
    return this.apiService.get(`${this.url}`).pipe(
    map(r => this.mapDataPlace(r)));
  }

  getActivePlaces(): Observable<Place[]> {
    return this.apiService.get(`${this.url}?active=true`).pipe(
    map(r => this.mapDataPlace(r)));
  }

  getPlace(id: string) {
    return this.apiService.get(`${this.url}/${id}`).pipe(
    map(r => this.mapDataPlace(r)));
  }

  createPlace(place: Place) {
    return this.apiService.post(`${this.url}`, place.toParamObject()).pipe(
    map(r => this.mapDataPlace(r)));
  }

  updatePlace(place: Place) {
    return this.apiService.patch(`${this.url}/${place.id}`, place.toParamObject()).pipe(
    map(r => this.mapDataPlace(r)));
  }

  deletePlace(id: string) {
    return this.apiService.delete(`${this.url}/${id}`).pipe(
    map(r => this.mapDataPlace(r)));
  }

  private mapDataPlace(data: any): Place[] {
    if (data.places && data.places instanceof Array) {
      return  (data.places as Array<any>).map(obj => new Place().fromJson(obj));
    } else if (data.places) {
      return [ new Place().fromJson(data.places) ];
    } else {
      return [];
    }
  }

}
