import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../../api/services/http.service';
import * as moment from 'moment/moment';

import { API_POLLING_URL, IMPACTS_URL, GDACS_URL, USGS_URL,
  IA_TERROR, IA_FOOD, IA_INDUSTRIAL, IA_JOURNALISTS,
  IA_KIDNAPPING, IA_SPORTS, IA_TRAFFICKING,
  IA_TRAVEL, IA_DISEASE, IA_CHURCH, IA_AVIATION, LIVEUA
} from '../../../app.constants';

// Models
import { Impact } from '../../impacts/models/impact.interface';
import { Issue } from '../../common/models/issue';
import { Place } from '../../common/models/place';
import { Location } from '../../common/models/location.interface';
import { Report } from 'data/report/report.interface';
import { RiskType } from '../../risk-types/models/risk-type.interface';
import { User } from '../../users/models/user';
import { LastPing } from '../../common/models/last-ping';
import { UserPing } from '../models/user-ping';


// Parsers
import { parse as parseImpact } from '../../impacts/parsers/impact.parser';
import { parse as parseLocation } from '../../common/models/location.interface';
import { parse as parseRiskType } from '../../risk-types/parsers/risk-type.parser';

@Injectable()
export class UIService {

  private get version(): string {
    return '1.0';
  }

  private get url() {
    return `${API_POLLING_URL}/${this.version}/ui`;
  }

  private get pullingUrl() {
    return `${API_POLLING_URL}/${this.version}/ui`;
  }

  constructor(private httpService: HttpService, private http: HttpClient) {}

  async dashboard(fromDate: Date | string, toDate: Date | string = new Date(), usePullingUrl = false): Promise<DashboardUIData> {

    const url = usePullingUrl ? this.pullingUrl : this.url;
    const params = `start=${this.encodeDate(fromDate)}&end=${this.encodeDate(toDate)}`;

    const response: any = await this.httpService.get(`${url}/dashboard?${params}`).toPromise();
    const data: {[key: string]: any[]} = response.data;

    const uiData: DashboardUIData = {
      startDate: fromDate,
      endDate: toDate,

      impacts: data.impacts.map(i => parseImpact(i)),
      issues: data.issues.map(i => new Issue().fromJson(i)),
      locations: data.locations.map(i => parseLocation(i)),
      reports: data.reports.map(i => Report.fromJson(i)),
      riskTypes: data.risktypes.map(i => parseRiskType(i)),
      users: data.users.map(i => new User().fromJson(i)),
      pings: [],
      places: (data.places || []).map(i => new Place().fromJson(i)),
    };

    // Consolidate pings
    const pings = uiData.users
      .map(user => user.devices
        .map(device => device.lastPing
          .map(ping => {
            const userPing = new UserPing();
            Object.assign(userPing, ping);
            userPing.userId = user.id;
            userPing.deviceId = device.id;

            return userPing;
          })))
      .reduce( (a, b) => [...a, ...b], [])
      .reduce( (a, b) => [...a, ...b], []);

    uiData.pings = pings;

    const s3Impacts = await this.getS3ImpactsData();
    uiData.impacts = arrayDistinct([...uiData.impacts, ...s3Impacts], i => i.id);

    return uiData;
  }


  private encodeDate (input: Date | string): string {
    return encodeURIComponent(moment.utc(input).format('YYYY-MM-DD'));
  }

  async getS3ImpactsData(all = true) {
    const latestOrTotal = all ? 'total' : 'latest';
    let acled: any;
    let usgs: any;
    let gdacs: any;
    let ia_terror: any;
    let ia_food: any;
    let ia_industrial: any;
    let ia_journalists: any;
    let ia_kidnapping: any;
    let ia_sports: any;
    let ia_trafficking: any;
    let ia_travel: any;
    let ia_disease: any;
    let ia_church: any;
    let ia_aviation: any;
    let liveua: any;

    acled = await this.http.get<any>(`${IMPACTS_URL}/${latestOrTotal}`).toPromise();
    gdacs = await this.http.get(`${GDACS_URL}/${latestOrTotal}`).toPromise();
    usgs = await this.http.get<any>(`${USGS_URL}/${latestOrTotal}`).toPromise();
    ia_terror = await this.http.get(`${IA_TERROR}/${latestOrTotal}`).toPromise();
    ia_food = await this.http.get(`${IA_FOOD}/${latestOrTotal}`).toPromise();
    ia_industrial = await this.http.get(`${IA_INDUSTRIAL}/${latestOrTotal}`).toPromise();
    ia_journalists = await this.http.get(`${IA_JOURNALISTS}/${latestOrTotal}`).toPromise();
    ia_kidnapping = await this.http.get(`${IA_KIDNAPPING}/${latestOrTotal}`).toPromise();
    ia_sports  = await this.http.get(`${IA_SPORTS}/${latestOrTotal}`).toPromise();
    ia_trafficking  = await this.http.get(`${IA_TRAFFICKING}/${latestOrTotal}`).toPromise();
    ia_travel = await this.http.get(`${IA_TRAVEL}/${latestOrTotal}`).toPromise();
    ia_disease  = await this.http.get(`${IA_DISEASE}/${latestOrTotal}`).toPromise();
    ia_church  = await this.http.get(`${IA_CHURCH}/${latestOrTotal}`).toPromise();
    ia_aviation  = await this.http.get(`${IA_AVIATION}/${latestOrTotal}`).toPromise();
    liveua = await this.http.get(`${LIVEUA}/${latestOrTotal}`).toPromise();

    // HttpResponse now parses the response body automatically
    const rawImpacts: any[] = [
      ...acled,
      ...usgs
    ];

    if (ia_industrial) { rawImpacts.push(...ia_industrial); }
    if (ia_sports) { rawImpacts.push(...ia_sports); }
    if (ia_travel) { rawImpacts.push(...ia_travel); }
    if (ia_terror) { rawImpacts.push(...ia_terror); }
    if (ia_journalists) { rawImpacts.push(...ia_journalists); }
    if (ia_food) { rawImpacts.push(...ia_food); }
    if (ia_disease) { rawImpacts.push(...ia_disease); }
    if (ia_church) { rawImpacts.push(...ia_church); }
    if (ia_kidnapping) { rawImpacts.push(...ia_kidnapping); }
    if (ia_trafficking) { rawImpacts.push(...ia_trafficking); }
    if (ia_aviation) { rawImpacts.push(...ia_aviation); }
    if (liveua) { rawImpacts.push(...liveua); }

    const impacts = rawImpacts.map(impact => parseImpact(impact));
    return impacts;
  }

}

export class DashboardUIData {
  startDate: Date | string;
  endDate: Date | string;
  impacts: Impact[] = [];
  issues: Issue[] = [];
  locations: Location[] = [];
  reports: Report[] = [];
  riskTypes: RiskType[] = [];
  users: User[] = [];
  pings: UserPing[] = [];
  places: Place[] = [];
}
