/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-indicator.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./alert-indicator.component";
var styles_AlertIndicatorComponent = [i0.styles];
var RenderType_AlertIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertIndicatorComponent, data: {} });
export { RenderType_AlertIndicatorComponent as RenderType_AlertIndicatorComponent };
function View_AlertIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "font-weight: bold !important"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; _ck(_v, 2, 0, currVal_0); }); }
export function View_AlertIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "btn btn-sm btn-default indicator"], ["routerLink", "/notifications"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-bell"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertIndicatorComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/notifications"; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.count; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AlertIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-indicator", [], null, null, null, View_AlertIndicatorComponent_0, RenderType_AlertIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlertIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertIndicatorComponentNgFactory = i1.ɵccf("app-alert-indicator", i4.AlertIndicatorComponent, View_AlertIndicatorComponent_Host_0, { count: "count" }, {}, []);
export { AlertIndicatorComponentNgFactory as AlertIndicatorComponentNgFactory };
