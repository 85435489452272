import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { User } from '../../users/models/user';
import { AccessControlRoles } from '../../shared/roles.enum';
import { SSOService } from './sso.service';
import { ApiService } from '../../api/services/api.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class SessionService {

  private get url() {
    return `${environment.API_URL}/${this.version}/users`;
  }

  private get version(): string {
    return '1.0';
  }

  private user$: BehaviorSubject<User> = new BehaviorSubject(null);
  user: User;

  constructor(
    private ssoService: SSOService,
    private apiService: ApiService
  ) {
    this.ssoService.sso.on('token', () => {
      this.apiService.setSession(this.ssoService.token, this.ssoService.claims.customerId);
      return null;
    });
  }

  public async login() {
    await this.ssoService.login();
    const userId = this.getCurrentUserId();
    const user = await this.fetchUser(userId);
    this.setCurrentUser(user);

    return userId;
  }

  public getCurrentToken(): string {
    return this.ssoService.token;
  }

  public getCurrentCustomerId(): string {
    return this.ssoService.claims.customerId;
  }

  public getCurrentUserId(): string {
    return this.ssoService.claims.userId;
  }

  public getCurrentUser(): Observable<User> {
    return this.user$.asObservable();
  }

  public setCurrentUser(user: User) {
    this.user = user;
    this.user$.next(user);
  }

  public isAuthenticated(): boolean {
    return this.ssoService.authenticated;
  }

  public getUserRole(): AccessControlRoles {
    return this.ssoService.claims.role;
  }

  public setToken(token: string) { }
  public setContextCustomer(id: string) { }
  async switchCustomer(customerId: string) { }

  private async fetchUser(userId: string): Promise<User> {
    const storageKey = `${environment.APP_NAMESPACE}:user`;
    const jsonUser = localStorage.getItem(storageKey);
    let user: User = null;

    try {
      if(jsonUser) {
        user = JSON.parse(jsonUser);
        if(typeof user !== 'object' || user.id !== userId) {
          user = null;
        } else {
          return user;
        }
      }
    } catch(err) {
      console.error(err);
    }
    user = new User();

    const data = await this.apiService.get(`${this.url}/${userId}`).toPromise();
    user.fromJson(data.users);

    return user;
  }

}
