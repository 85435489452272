import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SessionModule } from '../session/session.module';
import { SharedModule } from '../shared/shared.module';


import { HeaderComponent } from './header.component';
import { AlertIndicatorComponent } from './alert-indicator/alert-indicator.component';
import { IncidentsIndicatorComponent } from './incidents-indicator/incidents-indicator.component';
import { MessageIndicatorComponent } from './message-indicator/message-indicator.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { CustomerService } from '../customer/services/customer.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SessionModule,
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    IncidentsIndicatorComponent,
    AlertIndicatorComponent,
    MessageIndicatorComponent,
    UserMenuComponent,
  ],
  exports: [
    HeaderComponent,
  ],
  providers: [
    CustomerService
  ]
})
export class HeaderModule { }
