/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-lightbox.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-lightbox.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ImageLightboxComponent = [i0.styles];
var RenderType_ImageLightboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageLightboxComponent, data: {} });
export { RenderType_ImageLightboxComponent as RenderType_ImageLightboxComponent };
export function View_ImageLightboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["style", "max-height: 90vh; max-width: 90vw;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "close ion-close-circled"], ["style", "color: white; opacity: 1;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageLightboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-lightbox", [], null, null, null, View_ImageLightboxComponent_0, RenderType_ImageLightboxComponent)), i1.ɵdid(1, 114688, null, 0, i2.ImageLightboxComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageLightboxComponentNgFactory = i1.ɵccf("app-image-lightbox", i2.ImageLightboxComponent, View_ImageLightboxComponent_Host_0, {}, {}, []);
export { ImageLightboxComponentNgFactory as ImageLightboxComponentNgFactory };
