import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { ToastService } from '../services/toast.service';
import { SessionService } from '../../session/services/session.service';
import { AccessControlService } from '../services/access-control.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private sessionService: SessionService,
    private toastService: ToastService,
    private router: Router,
    private accessControlService: AccessControlService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const userRole = this.accessControlService.getUserRole();

    if (!route.data || !route.data.roles || !route.data.roles.length) {
      return true;
    }

    const matchingRole = route.data.roles.filter(r => this.accessControlService.hasRole(r.role))
      .sort(( a, b) => a.role < b.role);

    if (matchingRole.length === 0) {
      return false;
    }

    const matchCustomer = matchingRole[0].matchCustomer;

    const customerId = this.sessionService.getCurrentCustomerId();

    if (matchCustomer && route.params[matchCustomer] !== customerId) {
      return false;
    }

    return true;
  }

}


