import { Injectable } from '@angular/core';

// Rxjs
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

// Services
import { ApiService } from '../../api/services/api.service';

// Models
import { Article } from '../models/new-article.interface';
import { ExtractedArticle } from '../models/extracted-article.interface';

// Dependencies
import { API_POLLING_URL } from '../../../app.constants';

@Injectable()
export class NewArticlesService {

  get url() {
    return `${API_POLLING_URL}/1.0/articles`;
  }

  constructor(private apiService: ApiService) {}

  getArticles(): Observable<Article[]> {
    return this.apiService.get(`${this.url}`)
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  getCountryArticles(country: string): Observable<Article[]> {
    return this.apiService.get(`${this.url}?country_code=${country}`)
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  getArticle(id: string) {
    return this.apiService.get(`${this.url}/${id}`)
    .pipe(map(r => this.mapDataToArticle(r)[0]));
  }

  createArticle(article: Article) {
    return this.apiService.post(`${this.url}`, article.toParamObject())
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  updateArticle(article: Article) {
    return this.apiService.patch(`${this.url}/${article.id}`, article.toParamObject())
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  deleteArticle(id: string) {
    return this.apiService.delete(`${this.url}/${id}?id=${id}`)
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  searchArticle(query: string) {
    return this.apiService.get(`${this.url}/search?query=${query}`)
    .pipe(map(r => this.mapDataToArticle(r)));
  }

  public extractArtcileFromURL(url: string): Observable<ExtractedArticle> {
    return this.apiService.get( this.url + `/url-extract?url=${encodeURIComponent(url)}`)
    .pipe(map( d =>  {
      return ( <any>d ).data['url-extract'] || {};
    }));
  }

  private mapDataToArticle(data: any): Article[] {
    if (data.articles && data.articles instanceof Array) {
      return  (data.articles as Array<any>).map(obj => new Article().fromJson(obj));
    } else if (data.articles) {
      return [ new Article().fromJson(data.articles) ];
    } else {
      return [];
    }
  }

}
