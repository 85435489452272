import { ServiceObject } from './service-object';

import * as moment from 'moment/moment';
import 'moment-timezone';
// import {MetadataTravelerReservation} from '../../trips/trips-editor-reservation-row-view/trips-editor-reservation-row-view.component';
import {Flight} from './flight';
import {Waypoint, WaypointSource} from './waypoint';
import {TravelerReservation, TravelerReservationSource} from "./traveler-reservation";

export class Reservation extends ServiceObject {
  id: string;
  name: string;
  title: string;
  notes: string;
  tripId: string;
  type: ReservationType;
  riskRating: number;
  imageUrl: string;
  providerId: string;
  waypointId: string;
  endWaypointId: string;
  userId: string;
  startDate: Date;
  endDate: Date;
  metaData: ReservationFlightMetadata|any; // TODO: Type all properties in use
  confirmationCode: string;
  created: Date;
  modified: Date;
  travelerReservation: TravelerReservation[];

  tmpId: string;
  localArrival: Date;
  localDeparture: Date;

  toParamObject(): ReservationEdit {
    return {
      id: this.id,
      name: this.name,
      title: this.title,
      trip_id: this.tripId,
      type: ReservationType[this.type],
      risk_rating: this.riskRating || 3,
      image_url: this.imageUrl,
      provider_id: this.providerId,
      notes: this.notes,
      waypoint_id: this.waypointId,
      end_waypoint_id: this.endWaypointId,
      user_id: this.userId,
      start_date: this.startDate.toISOString(),
      end_date: this.endDate.toISOString(),
      meta_data: JSON.stringify(this.metaData),
      confirmation_code: this.confirmationCode,
    };
  }

  fromJson({data, waypoints}: {data: ReservationSource, waypoints: Waypoint[]}) {
    this.id = data.Id;
    this.name = data.Name;
    this.title = data.Title;
    this.tripId = data.TripId;
    this.type = ReservationType[data.Type];
    this.riskRating = data.RiskRating;
    this.imageUrl = data.ImageUrl;
    this.providerId = data.ProviderId;
    this.waypointId = data.WaypointId;
    this.endWaypointId = data.EndWaypointId;
    this.userId = data.UserId;
    this.startDate = new Date(data.StartDate);
    this.endDate = new Date(data.EndDate);
    this.confirmationCode = data.ConfirmationCode;
    this.modified = new Date(data.Modified);
    this.created = new Date(data.Created);
    this.notes = data.Notes;

    this.travelerReservation = (data.TravelerReservation || []).map((r) => {
      return new TravelerReservation().fromJson(r);
    });

    if (data.MetaData) {
      try {
        this.metaData = JSON.parse(data.MetaData);
      } catch (e) {
        console.log('Cannot parse Reservation metadata', data);
      }
    }

    if(waypoints[0]) {
      this.localArrival = this.parseWaypointLocalArrival(waypoints[0]);
    }

    if (waypoints[1]) {
      this.localDeparture = this.parseWaypointLocalDeparture(waypoints[1]);
    }

//    this.localArrival = this.parseLocalArrival(this.metaData);
  //  this.localDeparture = this.parseLocalDeparture(this.metaData);

    return this;
  }

  parseLocalArrival(meta: ReservationFlightMetadata): Date|null {
    if (meta && meta.arrival_datetime) {
      return this.parseAsTimezone(meta.arrival_datetime, meta.arrival_time_zone_id);
    } else {
      return null;
    }

  }

  parseLocalDeparture(meta: ReservationFlightMetadata): Date|null {
    if (meta && meta.departure_datetime) {
    return this.parseAsTimezone(meta.departure_datetime, meta.departure_time_zone_id);
    } else {
      return null;
    }
  }

  parseAsTimezone(source: string, timezone: string): Date|null {
    if (typeof source === typeof undefined || !source) {
      console.warn('Trying to parse an invalid date');
      debugger;
      return null;
    }

//    console.log('Original date', source);
    // The original date does not have a timezone marker, but we know the timezone since it's part of the metadata
    const date = moment.tz(source, timezone).toDate();
//    console.log('Parsed date', date);

    if (isNaN(date.getTime())) {
      console.warn('Could not parse given date', source);
      debugger;
      return null;
    }

    return date;
  }

  private parseWaypointLocalArrival(waypoint: Waypoint) {
    if (!waypoint || ! waypoint.arrivalDate) {
      console.log(waypoint);
      debugger;
    }
    return this.parseAsTimezone(waypoint.arrivalDate.toISOString(), waypoint.timezone);
  }

  private parseWaypointLocalDeparture(waypoint: Waypoint) {
    return this.parseAsTimezone(waypoint.departureDate.toISOString(), waypoint.timezone);
  }
}

export interface ReservationSource {
  ConfirmationCode: string;
  Created: string;
  EndDate: string;
  EndWaypoint: WaypointSource;
  EndWaypointId: string;
  Id: string;
  ImageUrl: string|null;
  MetaData: string;
  Modified: string;
  Name: string;
  ProviderId: string|null;
  RiskRating: number;
  StartDate: string;
  Title: string;
  TripId: string;
  Notes: string;
  Type: string;
  UserId: string|null;
  WaypointId: string;
  Waypoint: WaypointSource;
  Trip: any;
  TravelerReservation: TravelerReservationSource[];
}

export interface ReservationEdit {
  id: string;
  name: string;
  title: string;
  trip_id: string;
  type: string;
  notes: string;
  risk_rating: number;
  image_url: string|null;
  provider_id: string|null;
  waypoint_id: string;
  end_waypoint_id: string;
  user_id: string|null;
  start_date: string;
  end_date: string;
  meta_data: string;
  confirmation_code: string;
}

export interface ReservationFlightMetadata {
  flight: Flight;
  arrival_datetime: string;
  arrival_time_zone_id: string;
  departure_datetime: string;
  departure_time_zone_id: string;
  origin_city_name: string;
  destination_city_name: string;
  airline: string;
  flight_number: string;
  origin: string;
  destination: string;
  travelers: any[], //MetadataTravelerReservation[]
  notes: string;
}

export enum ReservationType {
  Flight = 'Flight',
  Hotel = 'Hotel',
  Car = 'Car',
  Rail = 'Rail',
  Ship = 'Ship',
  Activity = 'Activity', // TODO: Implement
}
