
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';

import {alertError} from './notify';
import {HttpClient, HttpResponse} from '@angular/common/http';

/** TODO consider some metaprogramming magic to automate these calls instead of having them in
 *   public static fromJson() in each model class
 *
 * TODO consider using decorators on properties
 * TODO consider that all of those goodies could be somewhere in some library like Jackson...
 * --> I asked: http://stackoverflow.com/questions/43644176/jackson-library-equivalent-for-typescript-javascript
 */
export function fromJson<T>(srcJson, toObjectFun: (n) => T) {
  try {
    if ( typeof srcJson === 'string' ) {
      srcJson = JSON.parse(srcJson);
    }
    // console.log('fromJson util method: srcJson: ', srcJson);
    if ( ! srcJson ) {
      // return null;
      return srcJson;
    }
    try {
      return toObjectFun(srcJson);
    } catch (e) {
      console.error('ERROR in toObjectFun() parsing object from JSON: ', e);
      throw e; // TODO prevent reporting twice (on outer catch level) -- change catch nesting to be more specific, e.g. json parsing as well
    }
  } catch (e) {
    // TODO: do the exception handling here or in call site (e.g. in http observable/.map) ?
    console.error('ERROR Parsing object from JSON: ', e);
    throw e; /* TODO: consider wrapping exception, preserving original cause
        - I asked: http://stackoverflow.com/questions/43643354/exception-nesting-wrapping-in-typescript */
  }
}

export function dateFromJson(srcJson): Date {
  return new Date(srcJson);
}

export function initFromObject(objectToInit, initFrom) {
  Object.assign(objectToInit, initFrom);
}

export function httpResponseToObservableArray<TRaw, TParsed>(
    http: HttpClient, url: string, infix: string, itemParseFunc: (u: TRaw) => TParsed
    ): Observable<TParsed[]> {
  // consider switchMap (in case of cancelling of network requests etc)
  // FIXME: error handling: error => ...
  return http.get(url).pipe(map((response: HttpResponse<any>) => {
    const mappedArray: TParsed[] = (response as any).data[infix].map(itemParseFunc);
    return mappedArray;
  }));
  // note, the [0] will need to be revised if we start to be concerned with batch requests/responses
}

export function httpResponseToSingleObservable<TRaw, TParsed>(
  http: HttpClient, url: string, infix: string, itemParseFunc: (u: TRaw) => TParsed
): Observable<TParsed> {
  // consider switchMap (in case of cancelling of network requests etc)
  // FIXME: error handling: error => ...
  return http.get(url).pipe(
    map((response: HttpResponse<any>) => {
    const dataInInfix = (response as any).data[infix];
    // const arrayLength = dataInInfix.length;
    // const EXPECTED_LENGTH = 1;
    // if ( arrayLength !== EXPECTED_LENGTH ) {
    //   alertError(`Unexpected array length returned (expected==${EXPECTED_LENGTH}): '${arrayLength}'` +
    //     `for infix '${infix}' at url '${url}'`);
    // }
    const parsed: TParsed = itemParseFunc(dataInInfix);
    return parsed;
  })
  );
  // note, the [0] will need to be revised if we start to be concerned with batch requests/responses
}


/** Ugly name, but this a bridge between the 2 approaches
 * (not yet sure which we will choose; or ngrx) */
export function httpResponseToObservableArrayOfObservable<TRaw, TParsed>(
    http: HttpClient, url: string, infix: string, itemParseFunc: (u: TRaw) => TParsed
): Observable<Observable<TParsed>[]> {
  return httpResponseToObservableArray<TRaw, TParsed>(http, url, infix, itemParseFunc).pipe(map((items: TParsed[]) =>
     items.map(item => observableOf(item))
  ));
}

/** A'la safe navigational operator "?." */
export function fieldOrNull<T>(src: T, fieldName: keyof T) {
// export function fieldOrNull<TParsed>(src: TParsed, fieldName) {
  // NOTE: keyof requires typescript >= 2.1
  return src ? src[fieldName] : null;
}

export function parseArrayToObservableOfObservables<TRaw, TParsed>(
  rawArray: TRaw[],
  itemParseFunc: (u: TRaw) => TParsed
): Observable<Observable<TParsed>[]> {
  if ( ! rawArray ) {
    return observableOf([]);
  }
  const parsedArray: Observable<TParsed>[] = (! rawArray) ? []
    : rawArray.map((rawItem: TRaw) =>
      observableOf(itemParseFunc(rawItem))
    );

  return observableOf(parsedArray);
}

export function parseArrayToObservable<TRaw, TParsed>(
  rawArray: TRaw[],
  itemParseFunc: (u: TRaw) => TParsed
): Observable<TParsed[]> {
  return observableOf(parseArray(rawArray, itemParseFunc));
}

export function parseArray<TRaw, TParsed>(
  rawArray: TRaw[],
  itemParseFunc: (u: TRaw) => TParsed
): TParsed[] {
  if ( ! rawArray ) {
    return [];
  }
  const parsedArray: TParsed[] = (! rawArray) ? []
    : rawArray.map((rawItem: TRaw) =>
      itemParseFunc(rawItem)
    );
  return parsedArray;
}
