import { Injectable } from '@angular/core';

import { SessionService } from '../../session/services/session.service';
import { AccessControlRoles } from '../roles.enum';



@Injectable()
export class AccessControlService {

  constructor(private sessionService: SessionService) { }

  getUserRole() {
    return this.sessionService.getUserRole();
  }

  hasRole(reqiuredRole: AccessControlRoles, exactMatch: boolean = false) {

    const userRole = this.sessionService.getUserRole();

    const userRoleInt = <number> userRole;
    const requiredRoleInt = <number> reqiuredRole;


    if (exactMatch && userRoleInt === requiredRoleInt) {
      return true;
    } else if (!exactMatch && userRoleInt >= requiredRoleInt ) {
      // More privileged Roles have larger integer values and inherit all permissions under them;
      // So just compare their integer values
      return true;
    } else {
      return false;
    }
  }

  hasRoleIsCustomer(reqiuredRole: AccessControlRoles, requestedCustomerId: string) {
    const customerId = this.sessionService.getCurrentCustomerId();

    return this.hasRole(reqiuredRole) && requestedCustomerId === customerId;
  }
}
