// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { FormsModule } from '@angular/forms';

// Dependencies
//  import { TripsRoutingModule } from './trips-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { RiskIndexesService } from 'app/risk-indexes/services/risk-indexes.service';

// Components
import { DrawerComponent } from './components/drawer/drawer.component';
import { AdvisoryRoutingModule } from './advisory-routing.module';
import { CountryListComponent } from './components/country-list/country-list.component';
import { CalendarsService } from 'app/calendar/services/calendars.service';
import { NewArticlesService } from 'app/articles/services/new-articles.service';
import { CompareCountriesComponent } from './components/compare-countries/compare-countries.component';



@NgModule({
  imports: [
    // Angular
    CommonModule,
    // Dependencies
    AdvisoryRoutingModule,
    LayoutModule,
    FormsModule
  ],
  declarations: [
    DrawerComponent,
    CountryListComponent,
    CompareCountriesComponent
  ],
  providers: [
    RiskIndexesService,
    CalendarsService,
    NewArticlesService
  ],
  exports: [
    DrawerComponent
  ]
})
export class AdvisoryModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: AdvisoryModule,
      providers: [RiskIndexesService],
    };
  }
 }
