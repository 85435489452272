
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../../common/models/customer';
import { ApiService } from '../../api/services/api.service';
import { API_URL } from '../../../app.constants';
import { Seat } from '../../../app/common/models/seat';
import { Card } from '../../../app/common/models/cards';
import { Order } from '../../../app/common/models/orders';
import { Transaction } from '../../../app/common/models/transactions';
import { Feature } from '../../../app/common/models/feature';
import { Plan } from '../../../app/common/models/plan';
import { Purchase } from '../../../app/common/models/purchase';
import { Quotation } from '../../../app/common/models/quotation';
import {HttpParams} from "@angular/common/http";
import {ParamObject} from "../../common/models/param-object.interface";


@Injectable()
export class CustomerService {

  get url() {
    return `${API_URL}/1.0/customers`;
  }

  constructor(private apiService: ApiService) {}

  getCustomers(access?: boolean, query?: string): Observable<Customer[]> {
    let params = new HttpParams();
    if (!!access) {
      params = params.append('support_access', 'true');
    }
    if (query) {
      params = params.append('query', query);
    }
    return this.apiService.get(`${this.url}?${params.toString()}`).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  getCustomer(id: string) {
    return this.apiService.get(`${this.url}/${id}`).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  createCustomer(customer: Customer) {
    return this.apiService.post(`${this.url}`, customer.toParamObject()).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  updateCustomer(customer: Customer) {
    return this.apiService.patch(`${this.url}/${customer.id}`, customer.toParamObject()).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  customerToggleSupportAccess(customer_id: string, access: boolean) {
    const params = { toggle: access };
    return this.apiService.post(`${this.url}/${customer_id}/access`, params).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  deleteCustomer(id: string) {
    return this.apiService.delete(`${this.url}/${id}`).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  createPurchase(customer_id: string, purchase: Purchase) {
    return this.apiService.post(`${this.url}/${customer_id}/purchase`, purchase.toParamObject());
  }

  createQuotation(customer_id: string, purchase: Purchase): any {
    return this.apiService.post(`${this.url}/${customer_id}/quotation`, purchase.toParamObject()).pipe(
    map(r => this.mapDataQuotation(r)));
  }

  getSeats(customer_id: string): Observable<Seat[]> {
    return this.apiService.get(`${this.url}/${customer_id}/seats`).pipe(
    map(r => this.mapDataSeat(r)));
  }



  getEmptySeats(customer_id: string): Observable<Seat[]> {
    return this.apiService.get(`${this.url}/${customer_id}/seats?has_user=false&has_invite=false`).pipe(
    map(r => this.mapDataSeat(r)));
  }

  public listSeats(customer_id: string): Observable<Seat[]> {
    return this.apiService
      .get(`${this.url}/${customer_id}/seats`).pipe(
      map(r => this.mapDataSeat(r)));
  }

  getSeat(customer_id: string, id: string) {
    return this.apiService.get(`${this.url}/${customer_id}/seats/${id}`).pipe(
    map(r => this.mapDataSeat(r)));
  }

  createSeat(customer_id, seat: Seat) {
    return this.apiService.post(`${this.url}/${customer_id}/seats`, seat.toParamObject()).pipe(
    map(r => this.mapDataSeat(r)));
  }

  updateSeat(customer_id: string, seat: Seat) {
    return this.apiService.patch(`${this.url}/${customer_id}/seats/${seat.id}`, seat.toParamObject()).pipe(
    map(r => this.mapDataSeat(r)));
  }

  deleteSeat(customer_id: string, id: string) {
    return this.apiService.delete(`${this.url}/${customer_id}/seats/${id}`).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  deleteExpireSeat(customer_id: string, id: string) {
    return this.apiService.delete(`${this.url}/${customer_id}/seats/${id}?expire=true`).pipe(
    map(r => this.mapDataCustomer(r)));
  }

  getCards(customer_id: string, user_id: string): Observable<Card[]> {
    const params = new FormData();
    params.append('user_id', user_id);
    return this.apiService.get(`${this.url}/${customer_id}/cards`, params).pipe(
    map(r => this.mapDataCard(r)));
  }

  deleteCard(customer_id: string, id: string) {
    return this.apiService.delete(`${this.url}/${customer_id}/cards/${id}`).pipe(
    map(r => this.mapDataCard(r)));
  }

  getOrders(customer_id: string): Observable<Order[]> {
    return this.apiService.get(`${this.url}/${customer_id}/orders`).pipe(
    map(r => this.mapDataOrder(r)));
  }

  getOrder(customer_id: string, id: string) {
    return this.apiService.get(`${this.url}/${customer_id}/orders/${id}`).pipe(
    map(r => this.mapDataOrder(r)));
  }

  getTransactions(customer_id: string, userId?: string): Observable<Transaction[]> {
    const params = { user_id: userId, count: 500, page: 1 };
    return this.apiService.get(`${this.url}/${customer_id}/transactions`, params).pipe(
    map(r => this.mapDataTransaction(r)));
  }

  createTransaction(customer_id: string, token: string): Observable<Transaction[]> {
    return this.apiService.postRaw(`${this.url}/${customer_id}/transaction`, `{"key": "${token}"}`).pipe(
    map(r => this.mapDataTransaction(r)));
  }

  listPlans(customer_id: string): Observable<Plan[]> {
    return this.apiService
      .get(`${this.url}/${customer_id}/plans`).pipe(
      map(r => this.mapDataPlan(r)));
  }

  getPlan(customer_id: string, id: string) {
    return this.apiService.get(`${this.url}/${customer_id}/plans/${id}`).pipe(
    map(r => this.mapDataPlan(r)));
  }

  createPlan(customer_id, plan: Plan) {
    return this.apiService.post(`${this.url}/${customer_id}/plans`, plan.toParamObject()).pipe(
    map(r => this.mapDataPlan(r)));
  }

  updatePlan(customer_id: string, plan: Plan) {
    return this.apiService.patch(`${this.url}/${customer_id}/plans/${plan.id}`, plan.toParamObject()).pipe(
    map(r => this.mapDataPlan(r)));
  }

  deletePlan(customer_id: string, id: string, reason: string) {
    const params = new FormData();
    params.append('reason', reason);
    return this.apiService.delete(`${this.url}/${customer_id}/plans/${id}?reason=${reason}`).pipe(
    map(r => this.mapDataPlan(r)));
  }

  listFeatures(customer_id: string): Observable<Feature[]> {
    return this.apiService
      .get(`${this.url}/${customer_id}/features`).pipe(
      map(r => this.mapDataFeature(r)));
  }

  getFeature(customer_id: string, id: string) {
    return this.apiService.get(`${this.url}/${customer_id}/features/${id}`).pipe(
    map(r => this.mapDataFeature(r)));
  }

  createFeature(customer_id, feature: Feature) {
    return this.apiService.post(`${this.url}/${customer_id}/features`, feature.toParamObject()).pipe(
    map(r => this.mapDataFeature(r)));
  }

  updateFeature(customer_id: string, feature: Feature) {
    return this.apiService.patch(`${this.url}/${customer_id}/features/${feature.id}`, feature.toParamObject()).pipe(
    map(r => this.mapDataPlan(r)));
  }

  deleteFeature(customer_id: string, id: string) {
    return this.apiService.delete(`${this.url}/${customer_id}/features/${id}`).pipe(
    map(r => this.mapDataFeature(r)));
  }

  private mapDataCustomer(data: any): Customer[] {
    if (data.customers && data.customers instanceof Array) {
      return  (data.customers as Array<any>).map(obj => new Customer().fromJson(obj));
    } else if (data.customers) {
      return [ new Customer().fromJson(data.customers) ];
    } else {
      return [];
    }
  }

  private mapDataSeat(data: any): Seat[] {
    if (data.seats && data.seats instanceof Array) {
      return  (data.seats as Array<any>).map(obj => new Seat().fromJson(obj));
    } else if (data.seat && data.seat instanceof Array) {
      return  (data.seat as Array<any>).map(obj => new Seat().fromJson(obj));
    } else if (data.seats) {
      return [ new Seat().fromJson(data.seats) ];
    } else {
      return [];
    }
  }

  private mapDataQuotation(data: any): Quotation[] {
    if (data.quotations && data.quotations instanceof Array) {
      return  (data.quotations as Array<any>).map(obj => new Quotation().fromJson(obj));
    } else if (data.quotations) {
      return [ new Quotation().fromJson(data.quotations) ];
    } else {
      return [];
    }
  }

  private mapDataCard(data: any): Card[] {
    if (data.methods && data.methods instanceof Array) {
      return  (data.methods as Array<any>).map(obj => new Card().fromJson(obj));
    } else if (data.methods) {
      return [ new Card().fromJson(data.methods) ];
    } else {
      return [];
    }
  }

  private mapDataTransaction(data: any): Transaction[] {
    if (data.transactions && data.transactions instanceof Array) {
      return  (data.transactions as Array<any>).map(obj => new Transaction().fromJson(obj));
    } else if (data.transactions) {
      return [ new Transaction().fromJson(data.transactions) ];
    } else {
      return [];
    }
  }

  private mapDataOrder(data: any): Order[] {
    if (data.orders && data.orders instanceof Array) {
      return  (data.orders as Array<any>).map(obj => new Order().fromJson(obj));
    } else if (data.orders) {
      return [ new Order().fromJson(data.orders) ];
    } else {
      return [];
    }
  }

  private mapDataPlan(data: any): Plan[] {
    if (data.plans && data.plans instanceof Array) {
      return  (data.plans as Array<any>).map(obj => new Plan().fromJson(obj));
    } else if (data.plans) {
      return [ new Plan().fromJson(data.plans) ];
    } else {
      return [];
    }
  }

  private mapDataFeature(data: any): Feature[] {
    if (data.planfeatures && data.planfeatures instanceof Array) {
      return  (data.planfeatures as Array<any>).map(obj => new Feature().fromJson(obj));
    } else if (data.planfeatures) {
      return [ new Feature().fromJson(data.planfeatures) ];
    } else {
      return [];
    }
  }

}
