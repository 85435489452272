import {dateFromJson, fromJson, initFromObject} from 'util/from-json';

export class CheckIn {
  when: Date;

  public static fromJson(srcJson) {
    // seems too repetitive but let's keep it like that for now...
    return fromJson(srcJson, () => {
      return {
        when: dateFromJson(srcJson.when),
      };
    });
  }

  constructor(initFrom: CheckIn) {
    initFromObject(this, initFrom);
  }

}
