import { ServiceObject } from './service-object';

export class TravelerReservation extends ServiceObject {

  id: string;
  // created: Date;
  // modified: Date;

  travelerId: string;
  room: string;
  confirmation: string;
  ticket: string;
  seat: string;

  toParamObject(): TravelerReservationEdit {
    return {
      id: this.id,

      travelerId: this.travelerId,
      room: this.room,
      confirmation: this.confirmation,
      ticket: this.ticket,
      seat: this.seat,
    };
  }

  fromJson(data: TravelerReservationSource) {
    this.id = data.Id;
    // TODO: Why are there not in here?
    // this.modified = new Date(data.Modified);
    // this.created = new Date(data.Created);

    this.travelerId = data.TravelerId;
    this.room = data.Room;
    this.confirmation = data.Confirmation;
    this.ticket = data.Ticket;
    this.seat = data.Seat;

    return this;
  }

}

export interface TravelerReservationSource {
  Id: string;
  // Modified: string;
  // Created: string;

  TravelerId: string;
  Room: string;
  Confirmation: string;
  Ticket: string;
  Seat: string;
}

export interface TravelerReservationEdit {
  id: string;
  travelerId: string;
  room: string|null;
  confirmation: string|null;
  ticket: string|null;
  seat: string|null;
}
