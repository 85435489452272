import { ApiClientService } from './api-client.service';
import { User } from './models';
import * as i0 from "@angular/core";
import * as i1 from "./api-client.service";
export class UsersService {
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.crudClient = this.apiClient.getCrudClient('users', true, (json) => new User().fromJson(json), (user) => user.toParamObject());
    }
    get() {
        return this.crudClient.get(1, 10);
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.ApiClientService)); }, token: UsersService, providedIn: "root" });
