import { HttpParameterCodec, HttpParams } from '@angular/common/http';

import { RequestParams } from '../models/request-params.interface';

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const asHttpParams = (config: RequestParams): HttpParams => {
  if (!config) {
    return null;
  }

  let params = new HttpParams({encoder: new CustomEncoder()});

  Object.keys(config).forEach(key => {
    if (typeof config[key] === 'undefined') {
      return; // skip undefined params
    }

    params = params.append(key, String(config[key]));
  });

  return params;
};
