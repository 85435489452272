import { Directive, OnInit, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, Validator, ValidatorFn} from '@angular/forms';

const PASSWORD_MIN_LENGTH = 8;

@Directive({
  selector: '[appPassword]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]
})
export class PasswordValidatorDirective implements Validator {

  private element: HTMLDivElement;

  constructor(private ref: ElementRef) {
  }

  ngOnInit() {
    this.injectElement();
    const input = this.ref.nativeElement as HTMLElement;
    this.show();
  }

  validate(control: AbstractControl): {[key: string]: any} {
    const level = this.getStrengthLevel(control.value);

    if (level === 'invalid') {
      this.element.innerHTML = 'Invalid';
    } else if (level === 'weak') {
      this.element.innerHTML = 'Weak';
    } else if (level === 'good') {
      this.element.innerHTML = 'Good';
    } else if (level === 'very-good') {
      this.element.innerHTML = 'Very Good';
    } else {
      this.element.innerHTML = '';
    }

    if (control.value ===  '****' && control.pristine) {
      this.element.innerHTML = '';
      return null;
    }

    this.element.setAttribute('class', 'app-password-validator-popup password-' + level);

    if (level === 'invalid') {
      return { 'invalidPassword': control.value };
    } else {
      return null;
    }
  }

  hide() {
    this.element.style.display = 'none';
  }

  show() {
    this.calculatePostion();
    this.element.style.display = 'block';
  }

  calculatePostion() {
    const input = this.ref.nativeElement as HTMLElement;
    const left = input.offsetWidth + input.offsetLeft - 100;
    const top = input.offsetTop;
    this.element.style.left = left + 'px';
    this.element.style.top = top + 'px';
    this.element.style.height = input.offsetHeight + 'px';
  }

  injectElement() {
    this.element = document.createElement('div');
    this.element.style.position = 'absolute';
    this.element.setAttribute('class', 'app-password-validator-popup');
    this.calculatePostion();

    let parent = this.ref.nativeElement.parentNode as any;
    while (
      parent.parentNode
        && parent.tagName !== 'BODY'
        && window.getComputedStyle(parent).position !== 'absolute'
        && window.getComputedStyle(parent).position !== 'fixed'
        && window.getComputedStyle(parent).position !== 'relative' ) {
          parent = parent.parentNode;
    }
    parent.appendChild(this.element);
  }

  getStrengthLevel(password: string) {
    const score = this.evaluatePassword(password);
    if (!password) {
      return 'empty';
    } else if (score < 30) {
      return 'invalid';
    } else if (score <= 35) {
      return 'weak';
    } else if (score < 50) {
      return 'good';
    } else {
      return 'very-good';
    }
  }

  evaluatePassword(password: string) {

    if (!password) {
      return 0;
    }

    const preg_match = (r: RegExp, subject: string) => subject.search(r) > -1;
    const lower = /[a-z]/g;
    const upper = /[A-Z]/g;
    const number = /[0-9]/g;
    const symbol = /[^a-zA-Z0-9]/g;
    const hasLower = preg_match(lower, password);
    const hasUpper = preg_match(upper, password);
    const hasNumber = preg_match(number, password);
    const hasSymbol = preg_match(symbol, password);
    let count = 0;
    let points = 0;
    const unique = [];
    const chars = password.split('');
    for (let i = 0; i < chars.length; i++) {
      const char = chars[i];
        count = 1;
        if (unique.indexOf(char) === -1) {
            unique.push(char);
        }
        if (preg_match(lower, char) && chars[i - 1] !== char && chars[i + 1] !== char) {
            count += hasUpper ? 1 : 0;
            count += hasNumber ? 1 : 0;
            count += hasSymbol ? 1 : 0;
            points += count;
            continue;
        }
        if (preg_match(upper, char) && chars[i - 1] !== char && chars[i + 1] !== char) {
            count += hasLower ? 1 : 0;
            count += hasNumber ? 1 : 0;
            count += hasSymbol ? 1 : 0;
            points += count;
            continue;
        }
        if (preg_match(number, char) && chars[i - 1] !== char && chars[i + 1] !== char) {
            count += hasLower ? 1 : 0;
            count += hasUpper ? 1 : 0;
            count += hasSymbol ? 1 : 0;
            points += count;
            continue;
        }
        if (preg_match(symbol, char) && chars[i - 1] !== char && chars[i + 1] !== char ) {
            count = 1;
            count += hasLower ? 1 : 0;
            count += hasUpper ? 1 : 0;
            count += hasNumber ? 1 : 0;
            points += count;
            continue;
        }
    }

    if (unique.length < chars.length) {
      points -= chars.length - unique.length;
    }

    points += ( chars.length / PASSWORD_MIN_LENGTH * 10 ) - PASSWORD_MIN_LENGTH;

    return points;
  }
}
