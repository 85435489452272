var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { CustomEncoder } from './ng-encoder';
import { ClientConfigService } from './client-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./client-config.service";
export class ApiClientService {
    constructor(httpClient, clientConfigService) {
        this.httpClient = httpClient;
        this.clientConfigService = clientConfigService;
    }
    getCrudClient(serviceName, paged, fromJson, toParam, baseRoute = null) {
        return new NavigoCrudClient(this, serviceName, paged, fromJson, toParam, baseRoute);
    }
    get(url, queryParams = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.request({ method: 'GET', url: url, queryParams });
        });
    }
    post(url, body, queryParams = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.request({ method: 'POST', url, body, queryParams });
        });
    }
    patch(url, body, queryParams = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.request({ method: 'PATCH', url, body, queryParams });
        });
    }
    delete(url, queryParams = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.request({ method: 'DELETE', url, queryParams });
        });
    }
    request(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const body = request.body && request.bodyFormat == 'params' ? this.generateParams(request.body) : null;
            const r = new HttpRequest(request.method, this.clientConfigService.baseUri + "/1.0/" + request.url, body, {
                params: request.queryParams ? this.generateParams(request.queryParams) : null,
                headers: this.generateHeaders(request.bodyFormat),
            });
            const response = yield this.httpClient.request(r).toPromise();
            return response;
        });
    }
    generateParams(obj) {
        let params = new HttpParams({ encoder: new CustomEncoder() });
        if (obj) {
            Object.keys(obj).forEach((k) => {
                let value = obj[k];
                if (typeof value === 'undefined' || value == '') {
                    return; // Skip undefined keys
                }
                if (value instanceof Date) {
                    value = value.toISOString();
                }
                params = params.append(k, value);
            });
        }
        return params;
    }
    generateHeaders(requestType) {
        let headers = new HttpHeaders();
        const token = '';
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        if (requestType == 'json') {
            headers = headers.append('Content-Type', 'application/json');
        }
        else if (requestType == 'params') {
            headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        }
        return headers;
    }
}
ApiClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiClientService_Factory() { return new ApiClientService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ClientConfigService)); }, token: ApiClientService, providedIn: "root" });
export class NavigoCrudClient {
    constructor(client, serviceName, paged, fromJson, toParam, baseRoute = null) {
        this.client = client;
        this.serviceName = serviceName;
        this.paged = paged;
        this.fromJson = fromJson;
        this.toParam = toParam;
        this.baseRoute = baseRoute;
        if (!baseRoute) {
            this.baseRoute = serviceName;
        }
    }
    get(page = 1, count = 20, filter) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.client.get(`${this.baseRoute}`, Object.assign({ count,
                page }, (filter instanceof Object ? filter : {})));
            this.normalizeNavigoResponse(response, this.serviceName);
            return {
                list: response.body.data[this.serviceName].map(d => this.fromJson(d)),
                paging: response.body.meta[this.serviceName].paging
            };
        });
    }
    read(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.client.get(`${this.baseRoute}/${id}`);
            return this.fromJson(response.body.data[this.serviceName]);
        });
    }
    create(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.client.post(`${this.baseRoute}`, this.toParam(data));
            return this.fromJson(response.body.data[this.serviceName]);
        });
    }
    save(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.client.patch(`${this.baseRoute}/${id}`, this.toParam(data));
            return this.fromJson(response.body.data[this.serviceName]);
        });
    }
    delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.client.delete(`${this.baseRoute}/${id}`);
        });
    }
    normalizeNavigoResponse(r, serviceName) {
        if (!r.body.data) {
            r.body.data = {};
        }
        if (!r.body.data[serviceName]) {
            r.body.data[serviceName] = null;
        }
        if (!r.body.meta) {
            r.body.meta = {};
        }
        if (!r.body.meta[serviceName]) {
            r.body.meta[serviceName] = {};
        }
        if (!r.body.meta[serviceName]['paging']) {
            r.body.meta[serviceName]['paging'] = {};
        }
    }
}
