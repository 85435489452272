import { ServiceObject } from './service-object';

export interface GatewayResponse {
  licenseId: string;
  siteId: string;
  deviceId: string;
  gatewayTxnId: string;
  gatewayRspCode: number;
  gatewayRspMsg: string;
  rspDT: Date;
  rspCode: number;
  rspText: string;
  authCode: string;
  aVSRsltCode: string;
  refNbr: string;
  cardType: string;
  aVSRsltText: string;
  cardBrandTxnId: string;
}

export class GatewayResponse extends ServiceObject {


  licenseId: string;
  siteId: string;
  deviceId: string;
  gatewayTxnId: string;
  gatewayRspCode: number;
  gatewayRspMsg: string;
  rspDT: Date;
  rspCode: number;
  rspText: string;
  authCode: string;
  AVSRsltCode: string;
  refNbr: string;
  cardType: string;
  AVSRsltText: string;
  cardBrandTxnId: string;

  toParamObject(): any {
    return {
      licenseId: this.licenseId,
      siteId: this.siteId,
      deviceId: this.deviceId,
      gatewayTxnId: this.gatewayTxnId,
      gatewayRspCode: this.gatewayRspCode,
      gatewayRspMsg: this.gatewayRspMsg,
      rspDT: this.rspDT,
      rspCode: this.rspCode,
      rspText: this.rspText,
      authCode: this.authCode,
      AVSRsltCode: this.AVSRsltCode,
      refNbr: this.refNbr,
      cardType: this.cardType,
      AVSRsltText: this.AVSRsltText,
      cardBrandTxnId: this.cardBrandTxnId
    };
  }


  fromJson(data: any) {
    try {
      data = JSON.parse(data);
    } catch (err) {
      return null;
    }

    this.licenseId = data.LicenseId;
    this.siteId = data.SiteId;
    this.deviceId = data.DeviceId;
    this.gatewayTxnId = data.GatewayTxnId;
    this.gatewayRspCode = data.GatewayRspCode;
    this.gatewayRspMsg = data.GatewayRspMsg;
    this.rspDT = data.RspDT;
    this.rspCode = data.RspCode;
    this.rspText = data.RspText;
    this.authCode = data.AuthCode;
    this.AVSRsltCode = data.AVSRsltCode;
    this.refNbr = data.RefNbr;
    this.cardType = data.CardType;
    this.AVSRsltText = data.AVSRsltText;
    this.cardBrandTxnId = data.CardBrandTxnId;
    return this;
  }

}
