import { ApiClientService } from './api-client.service';
import { Calendar } from './models';
import * as i0 from "@angular/core";
import * as i1 from "./api-client.service";
export class CalendarService {
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.crudClient = this.apiClient.getCrudClient('calendarevents', true, (json) => new Calendar().fromJson(json), (calendar) => calendar.toParamObject(), 'calendar');
    }
}
CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.ApiClientService)); }, token: CalendarService, providedIn: "root" });
