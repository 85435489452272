var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientConfigService } from '@navigo/navigo-services-client';
import { environment } from '../../../environments/environment';
import { SSOClient } from '@navigo-app/sso-client';
export class SSOService {
    constructor(servicesConfig) {
        this.servicesConfig = servicesConfig;
        this.claims = {};
        this.sso = new SSOClient(environment.SSO_CONFIG);
        this.sso.on('authSuccess', () => {
            this.authenticated = true;
        });
        this.sso.on('authLogout', () => {
            this.authenticated = false;
        });
        this.sso.on('authError', () => {
            this.authenticated = false;
            return false;
        });
        this.sso.on('token', () => {
            this.fetchSessionData();
        });
    }
    login() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.sso.start();
            this.fetchSessionData();
        });
    }
    isAuthenticated() {
        return this.sso.getToken() ? true : false;
    }
    fetchSessionData() {
        this.authenticated = this.sso.getToken() ? true : false;
        this.token = this.sso.getToken();
        this.claims.userId = this.sso.getTokenClaim('data.user');
        this.claims.customerId = this.sso.getTokenClaim('data.customer');
        this.claims.firstName = this.sso.getTokenClaim('given_name');
        this.claims.lastName = this.sso.getTokenClaim('family_name');
        this.claims.role = this.sso.getTokenClaim('data.permissions.role');
        this.claims.email = this.sso.getTokenClaim('email');
        this.servicesConfig.authToken = this.token;
    }
}
