import {EXAMPLE_PING_DRIVING_NOW, Ping} from '../ping/ping.interface';
import {CheckIn} from '../check-in/check-in.interface';
import {parseArrayToObservable, dateFromJson, fromJson, initFromObject, parseArray} from 'util/from-json';
import {PersonActivityType} from '../ping/user-activity-type.interface';
import {Device, DeviceFromApi} from '../device/device.interface';
import { Seat } from 'app/common/models/seat';
import {Observable} from 'rxjs';

export interface UserFromApi {
  Phone: string;
  Email: string;
  AddressStreet2: string;
  LastPingCity: string;
  Created: string;
  JobDescription: string;
  Token: string;
  LastName: string;
  AddressStreet1: string;
  Id: string;
  Password: string;
  Session: string;
  LastPingLatitude: number;
  LastPingTime: string;
  LastPingCountryCode: string;
  CustomerId: string;
  SupervisorPhone: string;
  AddressPostalCode: string;
  FirstName: string;
  AddressCity: string;
  AddressCountry: string;
  Modified: string;
  Active: boolean;
  AddressState: string;
  LastPingLongitude: number;
  Devices: DeviceFromApi[];
  Seats: Seat[];
}


export class User {
  id: string;
  active?: boolean;

  public isInvited?(): boolean {
    return ! ( this.active === true || this.active === false );
  }

  firstName: string;
  lastName: string;
  lastCheckIn?: CheckIn;
  lastPing?: Ping;
  public getLastPing?(): Ping {
    // should be an optional getter, but: https://github.com/Microsoft/TypeScript/issues/14417
    // TODO change to observable
    if ( this.lastPing ) {
      return this.lastPing;
    }
    if ( this.devices && this.devices.length >= 1 ) {
      return this.devices[0].lastPing; // FIXME: return findLargestByCriterion(...)
    }
  }

  whenCreated?: Date;
  phone?: string;
  email?: string;
  /** Hack for users picker auto-complete, it apparently requires property instead of function:
   * display-property-name="fullNameProperty"
   * (though I did not dig deep enough yet) */
  fullNameProperty?: string;
  devices?: Device[];

  public static fromJsonMock(srcJson): User {
    return fromJson(srcJson, (srcObj: User) => {
      return new User({
        id: srcObj.id,
        active: srcObj.active,
        firstName: srcObj.firstName,
        lastName: srcObj.lastName,
        lastCheckIn: CheckIn.fromJson(srcObj.lastCheckIn),
        lastPing: Ping.fromJsonMock(srcObj.lastPing),
        whenCreated: dateFromJson(srcObj.whenCreated),
        // displayName: () => {
        //   return 'Disp name'; // FIXME
        // }
      });
    });
  }

  public static fromJson(srcJson: UserFromApi): User {
    return fromJson(srcJson, (srcObj: UserFromApi) => {
      return new User({
        id: srcObj.Id,
        active: srcObj.Active,
        firstName: srcObj.FirstName,
        lastName: srcObj.LastName,
        whenCreated: dateFromJson(srcObj.Created),
        // lastPing: getLastPingFromDevices(),
        phone: srcObj.Phone,
        email: srcObj.Email,
        devices: parseArray(srcObj.Devices, Device.fromJson),
      });
    });
  }

  public fullName?(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public getLastActivityType?(): PersonActivityType {
    const lastPing = this.getLastPing();
    if ( lastPing ) {
      return lastPing.activityType;
    }
  }

  public getLastPingWhen?(): Date {
    const lastPing = this.getLastPing();
    if ( lastPing ) {
      return lastPing.when;
    }
  }

  getLastPingPercentBattery?(): number {
    const lastPing = this.getLastPing();
    if ( lastPing ) {
      return lastPing.batteryPercent;
    }
  }

  getLastPingPercentSignal?(): number {
    const lastPing = this.getLastPing();
    if ( lastPing ) {
      return lastPing.signalPercent;
    }
  }

  constructor(initFrom: User) {
    initFromObject(this, initFrom);
    this.fullNameProperty = this.fullName();

    this.lastPing = this.getLastPing(); /* compatibility with old approach by field.
      For the future we will only use the method
      (or, better yet, the optional getter once TS resolves the issue) */
  }

}
