// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { SessionModule } from '../session/session.module';

// Components
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    SharedModule,
    SessionModule
  ],
  declarations: [
    SideMenuComponent,
    FooterComponent,
  ],
  exports: [
    SideMenuComponent,
    FooterComponent,
  ],
})
export class LayoutModule { }
