
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from '../../groups/models/group';
import { User } from '../../users/models/user';
import { ApiService } from '../../api/services/api.service';
import { API_URL } from '../../../app.constants';
import { ResponseGroup } from '../../groups/models/response-group';
import { HttpService } from '../../api/services/http.service';


const till: any = {
  firstName: 'Till',
  lastName: ' Lindemann',
  id: 'till',
};

const james: any = {
  firstName: 'James',
  lastName: ' Hetfield',
  id: 'james',
};

@Injectable()
export class GroupsService {

  get url() {
    return `${API_URL}/1.0/groups`;
  }

  constructor(
    private apiService: ApiService,
    private httpService: HttpService
  ) {}

  getGroups(): Observable<Group[]> {
    return this.apiService.get(`${this.url}`).pipe(
    map(r => this.mapDataGroup(r)));
  }

  getUsers(id: string): Observable <User[]> {
    return this.apiService.get(`${this.url}/${id}/users`).pipe(
    map(this.mapDataUser));
  }

  getGroup(id: string): Observable<Group> {
    return this.apiService.get(`${this.url}/${id}`).pipe(
    map(r => this.mapDataGroup(r)),
    map(list => list.pop()),);
  }

  getResponseGroups(group_id: string): Observable<ResponseGroup[]> {
    return this.apiService.get(`${this.url}/${group_id}/response`).pipe(
    map(r => this.sortResponseGroups(this.mapResponeGroup(r))));
  }

  getResponseGroup(group_id: string, response_group_id: string): Observable<ResponseGroup> {
    return this.apiService.get(`${this.url}/${group_id}/response/${response_group_id}`).pipe(
    map(r => this.mapResponeGroup(r)[0]));
  }

  searchGroups(query: string) {
    return this.apiService.get(`${this.url}?query=${query}`).pipe(
    map(r => this.mapDataGroup(r)));
  }

  async orderResponseGroups(group_id: string, responsGroups: ResponseGroup[]) {
    const idList = responsGroups.map(group => group.id).join(',');

    await this.httpService.post(`${this.url}/${group_id}/response-order?order=${idList}`).toPromise();

    return this.getResponseGroupsAndMembers(group_id);
  }

  async removeResponseGroupUser(responsGroups: ResponseGroup[], groupId: string, userId: string) {
    await this.removeUserFromAllResponseGroups(groupId, responsGroups, userId);

    return this.getResponseGroupsAndMembers(groupId);
  }

  async addResponseGroupUser(responsGroups: ResponseGroup[], groupId: string, responseGroupId: string, userId: string) {
    await this.removeUserFromAllResponseGroups(groupId, responsGroups, userId);

    await this.addResponseGroupMember(groupId, responseGroupId, userId);

    return this.getResponseGroupsAndMembers(groupId);
  }

  async removeUserFromAllResponseGroups(groupId: string, responsGroups: ResponseGroup[], userId: string) {
      const userGroups = responsGroups
        .filter(responseGroup => responseGroup.members.find(member => member.id === userId) != null);

      for (let i = 0; i < userGroups.length; i++) {
        const group = userGroups[i];
        await this.removeResponseGroupMember(groupId, group.id, userId);
      }
  }

  async getResponseGroupsAndMembers(group_id: string) {
    const responseGroups = await this.getResponseGroups(group_id).toPromise();

    if (responseGroups.length > 0) {
      const memberDictionary = await this.getReponseMembers(group_id, responseGroups.map(r => r.id));

      responseGroups.forEach(responseGroup => {
        responseGroup.members = memberDictionary[responseGroup.id] || [];
      });
    }


    return this.sortResponseGroups(responseGroups);
  }

  async getReponseMembers(group_id: string, response_group_ids: string[]) {
    const idList = response_group_ids.join(',');

    const groupMembers = await this.httpService.get(`${this.url}/${group_id}/response-members?response_ids=${idList}`).pipe(
    map(response => this.mapResponseGroupsMembersDictionary(response)))
    .toPromise();

    return groupMembers;
  }

  async createResponseGroup(group_id: string, response_group: ResponseGroup, groups: ResponseGroup[] = null) {
    const fields = {
      name: response_group.name,
      delay: response_group.delay,
      order: response_group.escalationOrder
    };

    const response = await this.httpService.post(`${this.url}/${group_id}/response`, fields).pipe(
      map(r => this.mapResponeGroup(r['data'])))
      .toPromise();

    if (groups) {
      const newGroup = response[0];
      groups.push(newGroup);
      return this.sortResponseGroups(groups);
    } else {
      return await this.getResponseGroupsAndMembers(group_id);
    }
  }

  async updateResponseGroup(group_id: string, response_group: ResponseGroup) {
    const fields = {
      name: response_group.name,
      delay: response_group.delay,
      order: response_group.escalationOrder
    };
    await this.httpService.patch(`${this.url}/${group_id}/response/${response_group.id}`, fields)
      .toPromise();

    return this.getResponseGroupsAndMembers(group_id);
  }

  async deleteResponseGroup(group_id: string, response_group_id: string) {
    await this.httpService.delete(`${this.url}/${group_id}/response/${response_group_id}`)
      .toPromise()

    return this.getResponseGroupsAndMembers(group_id);
  }

  async addResponseGroupMember(group_id: string, response_group_id: string, user_id: string) {
    return this.httpService
      .post(`${this.url}/${group_id}/response/${response_group_id}/members`, {user_id})
      .toPromise();
  }

  async removeResponseGroupMember(group_id: string, response_group_id: string, user_id: string) {
    return this.httpService
      .delete(`${this.url}/${group_id}/response/${response_group_id}/members/${user_id}`)
      .toPromise();
  }

  createGroup(group: Group) {
    return this.apiService.post(`${this.url}`, group.toParamObject()).pipe(
    map(r => this.mapDataGroup(r)));
  }

  addUser(group_id: string, user_id: string, user_type: number, level: number) {
    return this.apiService.post(`${this.url}/${group_id}/users`, {user_id, user_type, level});
  }

  updateGroup(group: Group) {
    return this.apiService.patch(`${this.url}/${group.id}`, group.toParamObject()).pipe(
    map(r => this.mapDataGroup(r)));
  }

  deleteUser(group_id: string, user_id: string) {
    return this.apiService.delete(`${this.url}/${group_id}/users/${user_id}`);
  }

  deleteGroup(id: string) {
    return this.apiService.delete(`${this.url}/${id}`).pipe(
    map(r => this.mapDataGroup(r)));
  }

  private mapResponeGroup(data: any): Array<ResponseGroup> {
    const groups: ResponseGroup[] = [];
    if (data.responsegroups && data.responsegroups instanceof Array) {
      return data.responsegroups.map(r => ({
        id: r.Id,
        name: r.Name,
        delay: r.Delay,
        escalationOrder: r.EscalationOrder,
        members: this.mapDataUser({users: r.Users})
      }));
    } else if (data.groups) {
      return [{
        id: data.responsegroups.Id,
        name: data.responsegroups.Name,
        delay: data.responsegroups.Delay,
        escalationOrder: data.responsegroups.EscalationOrder,
        members: this.mapDataUser({users: data.Users})
      }];
    } else {
      return [];
    }
  }

  private mapDataGroup(data: any): Group[] {
    if (data.groups && data.groups instanceof Array) {
      return  (data.groups as Array<any>).map(obj => new Group().fromJson(obj));
    } else if (data.groups) {
      return [ new Group().fromJson(data.groups) ];
    } else {
      return [];
    }
  }

  private mapDataUser(data: any): User[] {
    if (data.users && data.users instanceof Array) {
      return  (data.users as Array<any>).map(obj => new User().fromJson(obj));
    } else if (data.users) {
      return [ new User().fromJson(data.users) ];
    } else {
      return [];
    }
  }

  private mapResponseGroupsMembersDictionary(data: any) {
    const response: { [response_group_id: string]: User[] } = {};

    if (data && data.data.groups) {
      Object.keys(data.data.groups)
      .forEach(id => {
        response[id] = this.mapDataUser({users: data.data.groups[id]}) || [];
      });

    }

    return response;
  }

  private sortResponseGroups(groups: ResponseGroup[]) {
    return groups.sort((a, b) => {
      if (a.escalationOrder > b.escalationOrder) {
        return 1;
      } else if (a.escalationOrder < b.escalationOrder) {
        return -1;
      } else {
        return 0;
      }
    });
  }

}
