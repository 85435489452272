/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./alert-indicator/alert-indicator.component.ngfactory";
import * as i5 from "./alert-indicator/alert-indicator.component";
import * as i6 from "../shared/directives/ac-require.directive";
import * as i7 from "../shared/services/access-control.service";
import * as i8 from "./user-menu/user-menu.component.ngfactory";
import * as i9 from "./user-menu/user-menu.component";
import * as i10 from "@angular/forms";
import * as i11 from "./header.component";
import * as i12 from "../shared/services/badge-counts.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-default"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "label label-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issuesCount; _ck(_v, 1, 0, currVal_0); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "ul", [["class", "nav navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "nav-link"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "nav-link"], ["routerLink", "/users"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["People"])), (_l()(), i1.ɵeld(15, 0, null, null, 8, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(16, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(19, 0, null, null, 4, "a", [["class", "nav-link"], ["routerLink", "/reports"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Reports "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(23, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 8, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(25, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(28, 0, null, null, 4, "a", [["class", "nav-link"], ["routerLink", "/issues"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Issues "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(32, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(33, 0, null, null, 6, "li", [["class", "hidden"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(34, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 9, { links: 1 }), i1.ɵqud(603979776, 10, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(37, 0, null, null, 2, "a", [["class", "nav-link"], ["routerLink", "/call-center"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 38).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 671744, [[10, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Calls"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "active"; _ck(_v, 2, 0, currVal_0); var currVal_3 = "/dashboard"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 9, 0, currVal_4); var currVal_7 = "/users"; _ck(_v, 13, 0, currVal_7); var currVal_8 = "active"; _ck(_v, 16, 0, currVal_8); var currVal_11 = "/reports"; _ck(_v, 20, 0, currVal_11); var currVal_12 = _co.reportsCount; _ck(_v, 23, 0, currVal_12); var currVal_13 = "active"; _ck(_v, 25, 0, currVal_13); var currVal_16 = "/issues"; _ck(_v, 29, 0, currVal_16); var currVal_17 = _co.issuesCount; _ck(_v, 32, 0, currVal_17); var currVal_18 = "active"; _ck(_v, 34, 0, currVal_18); var currVal_21 = "/call-center"; _ck(_v, 38, 0, currVal_21); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).target; var currVal_2 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 13).target; var currVal_6 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 20).target; var currVal_10 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_9, currVal_10); var currVal_14 = i1.ɵnov(_v, 29).target; var currVal_15 = i1.ɵnov(_v, 29).href; _ck(_v, 28, 0, currVal_14, currVal_15); var currVal_19 = i1.ɵnov(_v, 38).target; var currVal_20 = i1.ɵnov(_v, 38).href; _ck(_v, 37, 0, currVal_19, currVal_20); }); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-indicator", [], null, null, null, i4.View_AlertIndicatorComponent_0, i4.RenderType_AlertIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i5.AlertIndicatorComponent, [], { count: [0, "count"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "collapse navbar-collapse"], ["id", "bs-navbar-collapse-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(2, 81920, null, 0, i6.AcRequireDirective, [i1.TemplateRef, i1.ViewContainerRef, i7.AccessControlService], { acRequireRole: [0, "acRequireRole"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-user-menu", [], null, null, null, i8.View_UserMenuComponent_0, i8.RenderType_UserMenuComponent)), i1.ɵdid(4, 114688, null, 0, i9.UserMenuComponent, [], { user: [0, "user"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "form", [["class", "navbar-form navbar-right"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i10.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 4210688, null, 0, i10.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i10.ControlContainer, null, [i10.NgForm]), i1.ɵdid(9, 16384, null, 0, i10.NgControlStatusGroup, [[4, i10.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(11, 81920, null, 0, i6.AcRequireDirective, [i1.TemplateRef, i1.ViewContainerRef, i7.AccessControlService], { acRequireRole: [0, "acRequireRole"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "CrisisManager"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 4, 0, currVal_1); var currVal_9 = "CrisisManager"; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "header", [["id", "header-area"], ["role", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "nav", [["class", "navbar navbar-default"], ["id", "primary-navigation"], ["role", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["aria-expanded", "false"], ["class", "navbar-toggle collapsed"], ["data-target", "#bs-navbar-collapse-1"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "navbar-brand"], ["routerLink", "/dashboard"], ["style", "overflow:hidden"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "logo img-responsive"], ["src", "/assets/lockup-light.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "/dashboard"; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i11.HeaderComponent, [i12.BadgeCountsService], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i11.HeaderComponent, View_HeaderComponent_Host_0, { user: "user" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
