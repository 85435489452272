<ul class="nav navbar-nav navbar-right app-user-menu">
  <li class="dropdown">
    <a href="#" class="dropdown-toggle username" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span class="ion-person"></span><span class="hidden-sm lspace rspace">{{ user.fullName() }}</span><span class="caret"></span></a>
    <ul class="dropdown-menu">
      <ng-container *ngFor="let choice of links">
      <li *acRequireRole="choice.role"
         [ngClass]="{'divider': choice.separator, 'dropdown-item': !choice.separator}">

            <a *ngIf="!choice.separator" [routerLink]="choice.route" >{{ choice.label }}</a>

      </li>
      </ng-container>
    </ul>
  </li>
</ul>
