import { User } from 'app/users/models/user';
import { Invitation } from './invitation';
import { Plan } from './plan';

// https://github.com/navigo-app/spec/blob/master/models/Invitations.md

export interface Seat {
  id: string;
  customerId: string;
  userId: string;
  orderId: string;
  ownerId: string;
  price: number;
  discount: number;
  duration: number;
  paymentInterval: number;
  expires: any;
  occupied: any;
  purchased: any;
  locked: any;
  authCustomerId: string;
  type: string;
  created: Date;
  modified: Date;
  userRelatedByOwnerId: User;
  users: User[];
  invitations: Invitation[];
  plans?: Plan[];
}

export class Seat  {
  constructor(
  ) {}

  id: string;
  customerId: string;
  userId: string;
  orderId: string;
  ownerId: string;
  planId: string;
  price: number;
  discount: number;
  duration: number;
  paymentInterval: number;
  expires: any;
  occupied: any;
  purchased: any;
  locked: any;
  authCustomerId: string;
  type: string;
  created: Date;
  modified: Date;
  userRelatedByOwnerId: User;
  users: User[];
  invitations: Invitation[];
  plans?: Plan[];

  toParamObject(): any {
    return {
      id: this.id,
      customer_id: this.customerId,
      user_id: this.userId,
      order_id: this.orderId,
      owner_id: this.ownerId,
      plan_id: this.planId,
      price: this.price,
      discount: this.discount,
      duration: this.duration,
      payment_interval: this.paymentInterval,
      expires: this.expires,
      occupied: this.occupied,
      purchased: this.purchased,
      locked: this.locked,
      auth_customer_id: this.authCustomerId,
      type: this.type,
      created: this.created,
      modified: this.modified,
      userRelatedByOwnerId: this.userRelatedByOwnerId,
      users: this.users,
      invitations: this.invitations,
      plans: this.plans
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.userId = data.UserId;
    this.orderId = data.OrderId;
    this.ownerId = data.OwnerId;
    this.planId = data.PlanId;
    this.price = data.Price;
    this.discount = data.Discount;
    this.duration = data.Duration;
    this.paymentInterval = data.PaymentInterval;
    this.expires = data.Expires;
    this.occupied = data.Occupied;
    this.purchased = data.Purchased;
    this.locked = data.Locked;
    this.authCustomerId = data.AuthCustomerId;
    this.type = data.Type;
    this.created = data.Created;
    this.modified = data.Modified;
    this.userRelatedByOwnerId = data.UserRelatedByOwnerId;
    this.users = data.Users;
    this.invitations = data.Invitations;
    this.plans = data.Plans;
    return this;
  }

}
