import { ServiceObject } from '../../common/models/service-object';
import { Device } from '../../common/models/device';
import { Seat } from 'app/common/models/seat';
import { PingAwarenessActivityIcons, PingAwarenessActivityLabels } from '../../common/enums/ping-awareness-activity.enum';
import { get as _get } from 'lodash';
import { GroupUser } from 'app/common/models/group-user';
export class User extends ServiceObject {
    constructor() {
        super(...arguments);
        this.devices = [];
        this.seats = [];
        this.groupUsers = [];
    }
    isInvited() {
        return !(this.active === true || this.active === false);
    }
    getLastPing() {
        return _get(this.devices, [0, 'lastPing', 0], null);
    }
    fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
    getLastActivityType() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return lastPing.awarenessActivity;
        }
    }
    getActivityLabel() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return PingAwarenessActivityLabels[lastPing.awarenessActivity];
        }
    }
    getActivityIcon() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return PingAwarenessActivityIcons[lastPing.awarenessActivity];
        }
    }
    getLastPingWhen() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return lastPing.created;
        }
    }
    getLastPingCoordinates() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return lastPing.latitude + ',' + lastPing.longitude;
        }
    }
    getLastPingPercentBattery() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return lastPing.batteryLevel;
        }
    }
    getLastPingPercentSignal() {
        const lastPing = this.getLastPing();
        if (lastPing) {
            return lastPing.cellSignalStrength;
        }
    }
    toParamObject() {
        return {
            phone: this.phone,
            email: (this.email || '').trim(),
            address_street_2: this.addressStreet2,
            job_description: this.jobDescription,
            job_type: this.jobType,
            position_type: this.positionType,
            citizenship: this.citizenship,
            token: this.token,
            last_name: this.lastName,
            password: this.password !== '****' ? this.password : undefined,
            address_street_1: this.addressStreet1,
            id: this.id,
            code: this.code,
            customer_id: this.customerId,
            supervisor_phone: this.supervisorPhone,
            address_postal_code: this.addressPostalCode,
            first_name: this.firstName,
            address_city: this.addressCity,
            address_country: this.addressCountry,
            active: this.active,
            address_state: this.addressState,
            last_security_training: this.lastSecurityTraining,
            experienceOverseas: this.experienceOverseas,
            spoken_languages: this.spokenLanguages,
            permissions: this.permissions,
            emergency_contact_alt_phone: this.emergencyContactAltPhone,
            emergency_contact_email: this.emergencyContactEmail,
            emergency_contact_first_name: this.emergencyContactFirstName,
            emergency_contact_last_name: this.emergencyContactLastName,
            emergency_contact_phone: this.emergencyContactPhone,
            emergency_contact_relationship: this.emergencyContactRelationship,
            secondary_emergency_contact_alt_phone: this.secondaryEmergencyContactAltPhone,
            secondary_emergency_contact_email: this.secondaryEmergencyContactEmail,
            secondary_emergency_contact_first_name: this.secondaryEmergencyContactFirstName,
            secondary_emergency_contact_last_name: this.secondaryEmergencyContactLastName,
            secondary_emergency_contact_phone: this.secondaryEmergencyContactPhone,
            secondary_emergency_contact_relationship: this.secondaryEmergencyContactRelationship,
            seat_id: this.seatId,
            parent_id: this.parentId,
            alias: this.alias,
            groupUsers: this.groupUsers
        };
    }
    fromJson(data) {
        this.phone = data.Phone;
        this.email = data.Email;
        this.addressStreet2 = data.AddressStreet2;
        this.lastPingCity = data.LastPingCity;
        this.created = new Date(data.Created);
        this.jobDescription = data.JobDescription;
        this.jobType = data.JobType;
        this.positionType = data.PositionType;
        this.citizenship = data.Citizenship;
        this.token = data.Token;
        this.lastName = data.LastName;
        this.addressStreet1 = data.AddressStreet1;
        this.id = data.Id;
        this.code = data.Code;
        this.devices = data.Devices;
        this.password = data.Password;
        this.session = data.Session;
        this.lastPingLatitude = parseInt(data.LastPingLatitude, 10);
        this.lastPingTime = data.LastPingTime;
        this.lastPingCountryCode = data.LastPingCountryCode;
        this.customerId = data.CustomerId;
        this.supervisorPhone = data.SupervisorPhone;
        this.addressPostalCode = data.AddressPostalCode;
        this.firstName = data.FirstName;
        this.addressCity = data.AddressCity;
        this.addressCountry = data.AddressCountry;
        this.modified = new Date(data.Modified);
        this.active = data.Active;
        this.addressState = data.AddressState;
        this.lastPingLongitude = parseInt(data.LastPingLongitude, 10);
        this.lastSecurityTraining = data.LastSecurityTraining;
        this.experienceOverseas = data.ExperienceOverseas;
        this.spokenLanguages = data.SpokenLanguages;
        this.permissions = data.Permissions;
        this.emergencyContactAltPhone = data.EmergencyContactAltPhone;
        this.emergencyContactEmail = data.EmergencyContactEmail;
        this.emergencyContactFirstName = data.EmergencyContactFirstName;
        this.emergencyContactLastName = data.EmergencyContactLastName;
        this.emergencyContactPhone = data.EmergencyContactPhone;
        this.emergencyContactRelationship = data.EmergencyContactRelationship;
        this.secondaryEmergencyContactAltPhone = data.SecondaryEmergencyContactAltPhone;
        this.secondaryEmergencyContactEmail = data.SecondaryEmergencyContactEmail;
        this.secondaryEmergencyContactFirstName = data.SecondaryEmergencyContactFirstName;
        this.secondaryEmergencyContactLastName = data.SecondaryEmergencyContactLastName;
        this.secondaryEmergencyContactPhone = data.SecondaryEmergencyContactPhone;
        this.secondaryEmergencyContactRelationship = data.SecondaryEmergencyContactRelationship;
        this.parentId = data.ParentId;
        this.alias = data.Alias;
        this.groupUsers = data.GroupUsers;
        if (data.Devices && data.Devices instanceof Array) {
            this.devices = data.Devices.map(device => (new Device()).fromJson(device));
        }
        else {
            this.devices = [];
        }
        if (data.Seats && data.Seats instanceof Array) {
            this.seats = data.Seats.map(seat => (new Seat()).fromJson(seat));
        }
        else {
            this.seats = [];
        }
        if (data.GroupUsers && data.GroupUsers instanceof Array) {
            this.groupUsers = data.GroupUsers.map(groupUsers => (new GroupUser()).fromJson(groupUsers));
        }
        else {
            this.groupUsers = [];
        }
        this.fullNameProperty = data.FirstName + ' ' + data.LastName;
        return this;
    }
}
