import {ReportUrgency} from 'data/report/report.interface';
import {DomSanitizer} from '@angular/platform-browser';

export function getUrgencyClassSuffix(level: ReportUrgency): string {
  // TODO: for dashboard locations: verify the change to gray, yellow, orange, red from level.cssClassSuffix
  return level.cssClassSuffix;
  // switch (level) {
  //   case ReportUrgency.CHECK_IN:
  //     return 'success'; // FIXME
  //   case ReportUrgency.ELEVATED:
  //     return 'info'; // FIXME
  //   case ReportUrgency.HIGH:
  //     return 'warning'; // TODO: perhaps distinguish those colors
  //   // case ReportUrgency.ELEVATED:
  //   //   return 'warning'; // TODO: perhaps distinguish those colors
  //   // case ReportUrgency.HIGH:
  //   //   return 'danger'; // TODO: perhaps distinguish those colors
  //   case ReportUrgency.SEVERE:
  //     return 'danger'; // TODO: perhaps distinguish those colors
  // }
  // note: danger will blink currently
}

export function getPercentWidthStyle(domSanitizer: DomSanitizer, percent: number) {
  return domSanitizer.bypassSecurityTrustStyle(`width:${percent}%`);
}

export function getPercentClassSuffix(percent: number): string {
  let retStyle;
  if (percent < 25) {
    retStyle = 'danger';
  } else if (percent < 50) {
    retStyle = 'warning';
  } else {
    retStyle = 'success';
  }
  return retStyle;
}
