import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { User } from './models';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly crudClient = this.apiClient.getCrudClient('users', true, (json) => new User().fromJson(json), (user) => user.toParamObject());

  constructor(private apiClient: ApiClientService) { }

  get() {
    return this.crudClient.get(1, 10);
  }

}
