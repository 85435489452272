
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Report, convertToParams, dismissReport } from '../../../data/report/report.interface';

import { Observable } from 'rxjs';
import { ApiService } from '../../api/services/api.service';

import { API_URL, UPLOAD_API_URL } from '../../../app.constants';
import { HttpService } from '../../api/services/http.service';
import { Paginated } from '../../api/models/paginated.interface';
import { ReportComponent } from '../../reports/report/report.component';
import { ReportComment } from '../../reports/models/report-comment';
import { parse } from '../../reports/parsers/report-comment';


@Injectable()
export class ReportsService {

  get url() {
    return `${API_URL}/${this.version}/reports`;
  }

  get uploadUrl() {
    return `${UPLOAD_API_URL}/${this.version}/reports`;
  }

  get version() {
    return '1.0';
  }

  constructor(private apiService: ApiService, private httpService: HttpService) { }

  getReports(): Observable<Report[]> {
    return this.apiService.get(this.url).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  getReport(Id: string): Observable<Report> {
    return this.apiService.get(`${this.url}/${Id}`).pipe(
      map(data => Report.fromJson(data.reports)));
  }

  getGroupReports(groupId: string): Observable<Report[]> {
    return this.apiService.get(`${this.url}?group_id=${groupId}`).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  getUserReports(userId: string): Observable<Report[]> {
    return this.apiService.get(`${this.url}?user_id=${userId}`).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  saveReport(report: Report) {
    return this.apiService.patch(`${this.url}/${report.id}`, convertToParams(report)).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  dismissReport(report: Report) {
    return this.apiService.patch(`${this.url}/${report.id}`, dismissReport(report)).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  getDismissedReports(query: string = '') {
    return this.apiService.get(`${API_URL}/${this.version}/search?dismissed=1&query=${query}`).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

  createReport(report: Report): Observable<Report> {
    return this.apiService.post(`${this.url}`, convertToParams(report)).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))[0]));
  }

  uploadImage(reportId: string, file: File) {
    return this.apiService.uploadFile(`${this.uploadUrl}/upload?id=${reportId}`, file, 'image');
  }

  uploadVideo(reportId: string, file: File) {
    return this.apiService.uploadFile(`${this.uploadUrl}/upload?id=${reportId}`, file, 'video');
  }

  /**
   * Cancel SOS
   */

  cancelSOS(id: string): Observable<Report> {
    return this.apiService.post(`${this.url}/${id}/cancel-sos`, {}).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))[0]));
  }

  /**
   * Comments
   */

  getComments(reportId: string): Observable<ReportComment[]> {
    return this.httpService.get(`${this.url}/${reportId}/comments`).pipe(
      map(response => {
        return response['data']['comments']
          .map(parse)
          .sort((a: ReportComment, b: ReportComment) => a.created.getTime() - b.created.getTime());
      }));
  }

  async postComment(report_id: string, comment: string): Promise<any> {
    return this.httpService.post(`${this.url}/${report_id}/comments`, { comment})
      .toPromise();
  }

  async updateComment(report_id: string, comment_id: string, changes: any): Promise<any> {
    return this.httpService.patch(`${this.url}/${report_id}/comments/${comment_id}`, changes)
      .toPromise();
  }

  getPaginatedReports(page: number, count: number, dismissedReports?: boolean, filters?: any, hasPic?: any): Observable<Paginated<Report>> {
    const dismissed = dismissedReports ? 'true' : 'false';
    const params = {page, count, dismissed};
    if (!!filters && filters.types.length > 0) {
      let i = 0;
      filters.types.forEach(t => {
        params['type'] = t + (i > 0 ? ',' + params['type'] : '');
        i++;
      });
    }
    if (!!filters && filters.urgencies.length > 0) {
      let i = 0;
      filters.urgencies.forEach(t => {
        params['urgency'] = t + (i > 0 ? ',' + params['urgency'] : '');
        i++;
      });
    }
    if (!!filters && filters.countries.length > 0) {
      let i = 0;
      filters.countries.forEach(t => {
        params['country_code'] = t + (i > 0 ? ',' + params['country_code'] : '');
        i++;
      });
    }
    if (!!filters && filters.messages.length > 0) {
      params['query'] = encodeURIComponent(filters.messages[0]);
    }
    if (!!hasPic && hasPic === true) { params['has_image'] = true; }

    return this.httpService.get(this.url , {params}).pipe(
      map((response) => {
        const pagination = response['meta']['reports']['paging'];
        return {
          pagination: {
            total: pagination['nbResults'],
            page: pagination['page'],
            count: pagination['count'],
            max: pagination['maxPerPage'],
            isLastPage: pagination['isLastPage'],
          },
          list: response['data']['reports'].map((r) => Report.fromJson(r)),
        };
      }));
  }

  getPaginatedUserReaports(user_id: string, page: number, count: number): Observable<Paginated<Report>> {
    return this.httpService.get(this.url , {params: {page, count, user_id}}).pipe(
      map((response) => {
        const pagination = response['meta']['reports']['paging'];
        return {
          pagination: {
            total: pagination['nbResults'],
            page: pagination['page'],
            count: pagination['count'],
            max: pagination['maxPerPage'],
            isLastPage: pagination['isLastPage'],
          },
          list: response['data']['reports'].map((r) => Report.fromJson(r)),
        };
      }));
  }

  async getPaginatedUserReports(user_id: string, page: number, count: number = 10): Promise<PaginatedReports> {

    const response = await this.httpService.get(this.url , {params: {page, count, user_id}}).toPromise();

    const pagination = response['meta']['reports']['paging'];

    return {
      list: response['data']['reports'].map((r) => Report.fromJson(r)),
      total: pagination['nbResults'],
      page: pagination['page'],
      count: pagination['count'],
      max: pagination['maxPerPage'],
      isLastPage: pagination['isLastPage']
    };
  }


  acknowledgeReport(report_id: string, user_id: string) {
    return this.httpService.post(`${this.url}/${report_id}/acknowledge`);
  }

  escalate(report_id: string, user_id: string) {
    return this.httpService.post(`${this.url}/${report_id}/escalate?user_id=${user_id}`);
  }

  getLatestUsersReports(user_ids: string[], since: Date): Observable<Report[]> {
    return this.apiService.get(`${this.url}/latest?since=${since.toISOString()}&user_ids=${user_ids.join(',')}`).pipe(
      map(data => data.reports.map((r) => Report.fromJson(r))));
  }

}

export interface PaginatedReports {
  list: Report[];
  total: number;
  page: number;
  count: number;
  max: number;
  isLastPage: boolean;
}

