<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 hidden-sm hidden-xs">
        <app-side-menu></app-side-menu>
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-xs-12"><h3><b>Countries</b></h3></div>
          {{ trendLink }}
        </div>
            <div class="input-group">
              <input [(ngModel)]="filterText" (ngModelChange)="filter($event)" type="text" class="form-control" placeholder="Search...">
              <span class="input-group-btn">
                <button [title]="comparisons.length === 0 ? 'Select up to 6 countries to compare' : ''" [disabled]="!comparisons || comparisons.length === 0" routerLink="/compare/{{comparisonsLink}}" class="btn btn-default">Compare <span class="badge">{{ comparisons.length }}</span></button>
                <button [title]="comparisons.length === 0 ? 'Select up to 6 countries to run trending' : ''" [disabled]="!comparisons || comparisons.length === 0" routerLink="/intel/impact-trends" [queryParams]="{ countries : trendLink }" class="btn btn-default">Trends <span class="badge">{{ comparisons.length }}</span></button>
              </span>
            </div>

            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th><span class="ion-ios-checkmark-empty" style="font-size:1.4em"></span></th>
                  <th>Country</th>
                  <th>General Risk Level</th>
                  <th class="hidden-xs">Advisory</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="loading" class="text-center">
                  <td colspan="5" class="loading"><img src="/assets/navigo-loading.gif"/></td>
                </tr>
                <tr *ngFor="let country of filteredRiskIndexes">
                    <td>
                      <span [title]="detectActive(country.Id) ? 'Remove from compare list.' : 'Add to compare list.'" style="cursor: pointer;" (click)="toggleComparison(country.Id)" class="ion-android-checkbox-outline{{ detectActive(country.Id) ? '':'-blank'}}"></span>
                    </td>
                    <td>
                      <a style="cursor:pointer" (click)="loadDrawer(country.Id)">
                        <span style="font-size: 2em" class="flag-icon flag-icon-{{country.Id.toLowerCase()}}"></span> {{ country.Country }}
                      </a>
                    </td>
                    <td style="width:17%">
                      <div class="progress">
                        <div class="progress-bar progress-bar-{{ calculateRiskLevel(10 * country.RiskLevel) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * country.RiskLevel + '%'">
                          <span class="sr-only">{{ 100 * country.RiskLevel }}%</span>
                        </div>
                      </div>
                    </td>
                    <td class="hidden-xs">{{ country.StateDepTitle ? trimAdvisory(country.StateDepTitle) : 'Level NA: Advisory Unavailable' }}</td>
                    <td class="text-right">
                      <a class="btn btn-default btn-sm" (click)="loadDrawer(country.Id)">View Details</a>
                      <!-- <a class="btn btn-default btn-sm" routerLink="/intel/impact-trends" [queryParams]="{ countries : country.Id }">View Trends <span class="ion-arrow-graph-up-right"></span></a> -->
                    </td>
                </tr>
              </tbody>
            </table>

      </div>
    </div>
  </div>
