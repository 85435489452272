<div class="container-fluid">
    <div class="row">
      <div class="col-md-2 hidden-sm hidden-xs">
        <app-side-menu></app-side-menu>
      </div>
      <div class="col-md-10">
          <div class="row">
            <div class="col-xs-12"><h1><b>Compared Countries</b> <a routerLink="/countries" class="pull-right btn btn-default btn-sm">Countries List</a></h1></div>
          </div>
          <div class="alert alert-info" *ngIf="countryCodes.length === 0"><b>Note:</b> No country codes selected. <a routerLink="/countries">Click here</a> to select one or more country codes to compare.</div>
           <!-- <pre>{{ countries | json }}</pre>-->
           <h2>Travel Advisories</h2>
           <table class="table table-striped table-hover table-sm" *ngIf="countryCodes.length > 0 && !!countries[countryCodes[0]]">
             <thead>
               <tr>
                 <th>Metric</th>
                 <th *ngFor="let code of countryCodes" class="text-center">
                   <span *ngIf="code" class="{{ code !== 'WW' ? 'flag-icon flag-icon-' + code.toLowerCase() : 'ion-earth' }} rspace"></span>
                   <a class="pointer" (click)="loadDrawer(code)">{{ !!countries[code].index ? countries[code].index.Country : 'Loading...'}}</a>
                   <a (click)="removeCountry(code)" class="pointer pull-right" title="Remove country from comparison"><span class="ion-android-close"></span></a>
                 </th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td class="text-right"><b>Advisory</b></td>
                 <td
                     *ngFor="let code of countryCodes"
                     [style.width]="100 / (countryCodes.length + 1) + '%'"
                     class="threat-bar {{ !!countries[code].index ? detectLevel(countries[code].index.StateDepTitle) : 'lower' }}"
                 >
                   {{ !!countries[code].index ? trimAdvisory(countries[code].index.StateDepTitle) : 'Loading...'}}
                 </td>
               </tr>


               <tr><td colspan="100%"><h4>Overall Risks</h4></td></tr>
               <tr *ngFor="let comparison of overAllRisks">
                 <td class="text-right" [style.width]="100 / (countryCodes.length + 1) + '%'"><b>{{ splitTitle(comparison) }}</b></td>
                 <td *ngFor="let code of countryCodes" [style.width]="100 / (countryCodes.length + 1) + '%'" style="vertical-align: middle;">
                   <div class="progress" *ngIf="!!countries[code].inform">
                     <div class="progress-bar progress-bar-{{ calculateRiskLevel(countries[code]?.inform[comparison]) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * countries[code]?.inform[comparison] / 10 + '%'">
                       <span class="sr-only">{{ 100 * countries[code]?.inform[comparison] / 10}}%</span>
                     </div>
                   </div>
                 </td>
               </tr>

               <tr><td colspan="100%"><h4>Natural Disaster Risk</h4></td></tr>
               <tr *ngFor="let comparison of naturalDisasterRisks">
                 <td class="text-right" [style.width]="100 / (countryCodes.length + 1) + '%'"><b>{{ splitTitle(comparison) }}</b></td>
                 <td *ngFor="let code of countryCodes" [style.width]="100 / (countryCodes.length + 1) + '%'">
                   <div class="progress" *ngIf="!!countries[code].inform">
                     <div class="progress-bar progress-bar-{{ calculateRiskLevel(countries[code]?.inform[comparison]) }}" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width]="100 * countries[code]?.inform[comparison] / 10 + '%'">
                       <span class="sr-only">{{ 100 * countries[code]?.inform[comparison] / 10}}%</span>
                     </div>
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
           <hr>
          <h2>Country Background</h2>
          <h3>Population</h3>
          <table class="table">
            <thead>
              <tr>
                <th>Country</th>
                <th class="text-center">Population</th>
                <th class="text-center">Rank</th>
                <th class="text-center">Growth Rate</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let code of countryCodes">
                <tr *ngIf="!!countries[code]['factbook']">
                  <td [style.width]="100 / 4 + '%'" class="text-right">
                    <a class="pointer" (click)="loadDrawer(code)">{{ !!countries[code].index ? countries[code].index.Country : 'Loading...'}}</a>
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    {{ setMaxPop(countries[code]['factbook'].data.people.population.total) | number }}
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    {{ countries[code]['factbook'].data.people.population.global_rank }}
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    {{ countries[code]['factbook'].data.people.population_growth_rate.growth_rate }}%
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <h3>Economy</h3>
          <table class="table">
            <thead>
              <tr>
                <th>Country</th>
                <th class="text-center">GDP</th>
                <th class="text-center">Rank</th>
                <th class="text-center">Growth Rate</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let code of countryCodes">
                <tr *ngIf="!!countries[code]['factbook']">
                  <td [style.width]="100 / 4 + '%'" class="text-right">
                    <a class="pointer" (click)="loadDrawer(code)">{{ !!countries[code].index ? countries[code].index.Country : 'Loading...'}}</a>
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    ${{ countries[code]['factbook'].data.economy.gdp.official_exchange_rate.USD | number }} USD
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    {{ countries[code]['factbook'].data.economy.gdp.per_capita_purchasing_power_parity.global_rank }}
                  </td>
                  <td [style.width]="100 / 4 + '%'" class="text-center">
                    {{ countries[code]['factbook'].data.economy.gdp.real_growth_rate.annual_values[0].value }}%
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>



      </div>
    </div>
  </div>
