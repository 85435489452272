/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application-layout.component.sass.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/footer/footer.component.ngfactory";
import * as i3 from "../components/footer/footer.component";
import * as i4 from "../../../../node_modules/ngx-toasta/ngx-toasta.ngfactory";
import * as i5 from "ngx-toasta";
import * as i6 from "../../header/header.component.ngfactory";
import * as i7 from "../../header/header.component";
import * as i8 from "../../shared/services/badge-counts.service";
import * as i9 from "@angular/common";
import * as i10 from "@angular/router";
import * as i11 from "../../advisory/components/drawer/drawer.component.ngfactory";
import * as i12 from "../../advisory/components/drawer/drawer.component";
import * as i13 from "../../risk-indexes/services/risk-indexes.service";
import * as i14 from "../../advisory/components/services/drawer.service";
import * as i15 from "../../calendar/services/calendars.service";
import * as i16 from "../../articles/services/new-articles.service";
import * as i17 from "../../shared/services/toast.service";
import * as i18 from "./application-layout.component";
import * as i19 from "../../session/services/session.service";
import * as i20 from "../../../libs/navigo-services-client/client-config.service";
var styles_ApplicationLayoutComponent = [i0.styles];
var RenderType_ApplicationLayoutComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ApplicationLayoutComponent, data: {} });
export { RenderType_ApplicationLayoutComponent as RenderType_ApplicationLayoutComponent };
function View_ApplicationLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.FooterComponent, [], null, null)], null, null); }
export function View_ApplicationLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-toasta", [], null, null, null, i4.View_ToastaComponent_0, i4.RenderType_ToastaComponent)), i1.ɵdid(1, 114688, null, 0, i5.ToastaComponent, [i5.ToastaConfig, i5.ToastaService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(3, 49152, null, 0, i7.HeaderComponent, [i8.BadgeCountsService], { user: [0, "user"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "section", [["class", "app-content container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationLayoutComponent_1)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-drawer", [], null, null, null, i11.View_DrawerComponent_0, i11.RenderType_DrawerComponent)), i1.ɵdid(12, 114688, null, 0, i12.DrawerComponent, [i13.RiskIndexesService, i14.DrawerService, i15.CalendarsService, i16.NewArticlesService, i17.ToastService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.user$)); _ck(_v, 3, 0, currVal_0); _ck(_v, 8, 0); var currVal_1 = _co.showFooter; _ck(_v, 10, 0, currVal_1); _ck(_v, 12, 0); }, null); }
export function View_ApplicationLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "application-layout", [], null, null, null, View_ApplicationLayoutComponent_0, RenderType_ApplicationLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i18.ApplicationLayoutComponent, [i19.SessionService, i20.ClientConfigService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationLayoutComponentNgFactory = i1.ɵccf("application-layout", i18.ApplicationLayoutComponent, View_ApplicationLayoutComponent_Host_0, {}, {}, []);
export { ApplicationLayoutComponentNgFactory as ApplicationLayoutComponentNgFactory };
