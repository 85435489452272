import { ServiceObject } from 'app/common/models/service-object';
import * as moment from 'moment/moment';

export class Customer extends ServiceObject {
  id: string;
  name: string;
  shortName: string;
  addressStreet1: string;
  addressStreet2: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  addressCountry: string;
  phone: string;
  email: string;
  website: string;
  billingAddressStreet1: string;
  billingAddressStreet2: string;
  billingAddressCity: string;
  billingAddressState: string;
  billingAddressPostalCode: string;
  billingAddressCountry: string;
  contractPricing: any;
  accountType: string;
  industry: string;
  planLevel: string;
  userCount: number;
  accountManagerName: string;
  accountManagerPhone: string;
  accountManagerEmail: string;
  accountManagerId: string;
  serviceStartDate: Date;
  serviceEndDate: Date;
  modified: Date;
  emergencyPhoneNumber: string;
  meta?: string;
  supportAccess?: boolean;


  isActive(): boolean {
    return !this.serviceEndDate  || this.serviceEndDate > new Date();
  }


  toParamObject(): any {
    return {
      name: this.name,
      shortname: this.shortName,
      address_street_1: this.addressStreet1,
      address_street_2: this.addressStreet2,
      address_city: this.addressCity,
      address_state: this.addressState,
      address_postal_code: this.addressPostalCode,
      address_country: this.addressCountry,
      phone: this.phone.toString(),
      email: (this.email || '').trim(),
      website: this.website || undefined,
      billing_address_street_1: this.billingAddressStreet1,
      billing_address_street_2: this.billingAddressStreet2,
      billing_address_city: this.billingAddressCity,
      billing_address_state: this.billingAddressState,
      billing_address_postal_code: this.billingAddressPostalCode,
      billing_address_country: this.billingAddressCountry,
      contract_pricing: this.contractPricing,
      account_type: this.accountType,
      industry: this.industry,
      plan_level: this.planLevel,
      service_start_date: encodeURIComponent(moment(this.serviceStartDate).utc().format('YYYY-MM-DD')),
      service_end_date: encodeURIComponent(moment(this.serviceEndDate).utc().format('YYYY-MM-DD')),
      account_manager_id: this.accountManagerId,
      emergency_phone_number: this.emergencyPhoneNumber,
      meta: this.meta
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.name = data.Name;
    this.shortName = data.ShortName;
    this.addressStreet1 = data.AddressStreet1;
    this.addressStreet2 = data.AddressStreet2;
    this.addressCity = data.AddressCity;
    this.addressState = data.AddressState;
    this.addressPostalCode = data.AddressPostalCode;
    this.addressCountry = data.AddressCountry;
    this.phone = data.Phone;
    this.email = data.Email;
    this.website = data.Website;
    this.billingAddressStreet1 = data.BillingAddressStreet1;
    this.billingAddressStreet2 = data.BillingAddressStreet2;
    this.billingAddressCity = data.BillingAddressCity;
    this.billingAddressState = data.BillingAddressState;
    this.billingAddressPostalCode = data.BillingAddressPostalCode;
    this.billingAddressCountry = data.BillingAddressCountry;
    this.contractPricing = data.ContractPricing;
    this.accountType = data.AccountType;
    this.industry = data.Industry;
    this.planLevel = data.PlanLevel;
    this.userCount = data.UserCount;
    this.accountManagerName = data.AccountManagerName;
    this.accountManagerPhone = data.AccountManagerPhone;
    this.accountManagerEmail = data.AccountManagerEmail;
    this.accountManagerId = data.AccountManagerId;
    this.serviceStartDate = data.ServiceStartDate ? new Date(data.ServiceStartDate) : null;
    this.serviceEndDate = data.ServiceEndDate ? new Date(data.ServiceEndDate) : null;
    this.modified = data.Modified;
    this.emergencyPhoneNumber = data.EmergencyPhoneNumber;
    this.meta = data.Meta;
    this.supportAccess = data.SupportAccess;
    return this;
  }

}
