
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';


// Dependencies
import { GeocodingService } from '../services/geocoding.service';


@Pipe({
  name: 'reverseGeocoding',
})
export class ReverseGeocodingPipe implements PipeTransform {

  constructor(
    private geocoding: GeocodingService,
  ) { }

  transform(value: any, reverseCoords?: any): any {
    if (!value) return value;

    let lat, lng;

    let jsonStruct: any = {}
    if (typeof value === 'string') {
        try {
          jsonStruct = JSON.parse(value);
        } catch (err) {}
    }

    if (value instanceof Array) {
      if (!reverseCoords) {
        [lat, lng] = value;
      } else {
        [lng, lat] = value;
      }
    } else if (jsonStruct.coordinates && jsonStruct.coordinates.length) {
        if (!reverseCoords) {
          lat = jsonStruct.coordinates[0];
          lng = jsonStruct.coordinates[1];
        } else {
          lat = jsonStruct.coordinates[1];
          lng = jsonStruct.coordinates[0];
        }

    } else  {
      if (typeof value !== 'object') {
        throw new Error('Can only reverse objects {lat,lng}/{latitude/longitude} or arrays [lat, lng]');
      }

      if (value['lat']) {
        lat = value.lat;
        lng = value.lng;
      }

      if (value['latitude']) {
        lat = value['latitude'];
        lng = value['longitude'];
      }
    }

    if (lat === 0 && lng === 0) {
      return observableOf(`[${lat}, ${lng}]`);
    }

    return this.geocoding.reverse(lat, lng).pipe(
    map((response: any ) => {
      if(response.results && response.results[0]) {
        return response.results[0].formatted_address;
      } else {
        return ` [${lat}, ${lng}]`;
      }
    }));
  }

}
