import { User, UserFromApi } from '../user/user.interface';
import { User as NewUser } from 'app/users/models/user';
import { Location } from '../location/location.interface';
import { Coordinates } from '../location/coordinates.interface';
import { RiskLevelContext, RiskType } from '../risk/risk.interface';
import { Ping, PingFromApi } from '../ping/ping.interface';
import { User as UserModel } from 'app/users/models/user';




import { API_URL } from '../../app.constants';

import {dateFromJson, fromJson, initFromObject} from 'util/from-json';
import { Point, Position } from 'geojson';

import { ResponseGroup } from 'app/groups/models/response-group';
import { HsasUrgencyLevel } from 'app/common/enums/hsas-urgency-level';
import { ReportLevelLog } from './report-loglevel.interface';

/** Enum-like class
 *
 * TODO: rename to RiskLevel?
 *
 * https://github.com/navigo-app/spec/blob/master/models/Reports.md#urgency
 */
export class ReportUrgency {

  private static VALUES_BY_INT: { [intId: number]: ReportUrgency } = {};

  public static CHECK_IN = new ReportUrgency(0, 'general', 'Check In');
  public static ELEVATED = new ReportUrgency(3, 'caution', 'Moderate');
  public static HIGH = new ReportUrgency(4, 'warning', 'Heightened');
  public static SEVERE = new ReportUrgency(5, 'danger', 'Severe');

  public static fromInt(intId: number): ReportUrgency {
    const valueByInt = ReportUrgency.VALUES_BY_INT[intId];
    if ( ! valueByInt ) {
      return ReportUrgency.CHECK_IN;
      // throw new Error(`ReportUrgency: invalid intId: ${intId}`);
    }
    return valueByInt;
  }

  public static from(id: number|string): ReportUrgency {
    return ReportUrgency.fromInt(<number>id); // just redirect for now
  }

  private constructor(
    public intId,
    public cssClassSuffix,
    public name,
  ) {
    ReportUrgency.VALUES_BY_INT[intId] = this;
  }
}

export enum ReportSource {
  EYE_WITNESS = 0,
  RELIABLE_SOURCE = 1,
  SECOND_HAND = 2,
  RUMOR = 3,
  COMMON_KNOWLEDGE = 4
}
export enum ReportConfidence {
  LOW = 0,
  MODERATE = 1,
  HIGH = 2
}

export interface Area {
  coordinates: Coordinates;
  radiusInMeters: number;
}

export interface ReportFromApi {
  AcknowledgedBy: string;
  Acknowledged: boolean;
  City: string;
  ConfidenceLevel: number;
  CommentsCount: number;
  CustomerId: string;
  CountryCode: string;
  Created: string;
  Video: string;
  Urgency: number;
  Image: string;
  UserId: string;
  Type: number;
  Modified: string;
  Id: string;
  Users: UserFromApi[];
  GeoJson: any;
  Dismissed: string;
  Message: string;
  Meta: string;
  NearbyPingIds: string;
  NearbyPings: PingFromApi[];
  SourceType: number;
  RiskTypes: number;
  Level: any;
  GroupUsers: any;
  ResponseGroup: any;
  LevelLog: string;
  ImageRotation: number;
  ISRCCode: string;
}

export class Report {
  acknowledged: boolean;
  acknowledgedBy: string;
  id: string;
  whenReported?: Date;
  createdByUser: User | UserModel;
  nearbyPings?: Ping[];
  commentsCount?: number;
  location: Location;
  urgency: ReportUrgency;
  source: ReportSource;
  confidence: ReportConfidence;
  coordinates: any;
  area: Area;
  isDismissed: boolean;
  title: string;
  message: string;
  meta: string;
  type: RiskType;
  imageUrl?: string;
  videoUrl?: string;
  dismissed: Date;
  riskTypes?: number;
  countryCode?: string;
  userId: string;
  level: number;
  imageRotation?: number;
  responseGroups: ResponseGroup[];
  levelLog: ReportLevelLog[] = [];
  city: string;
  hsasLevel?: HsasUrgencyLevel;
  isrcCode: string;

  public static fromJson(src: ReportFromApi): Report {
    const report = new Report();
    report.id = src.Id;
    report.whenReported = dateFromJson(src.Created);
    report.createdByUser = src.Users ? User.fromJson(src.Users[0]) : null;

    report.location = Location.fromJson({
      name: src.City,
      id: 'FIXME-LOCATION-ID',
      country: src.CountryCode,
      coordinates: this.getPositionFromPoint(src.GeoJson),
    });

    report.commentsCount = src.CommentsCount;
    report.acknowledged = src.Acknowledged;
    report.confidence = src.ConfidenceLevel >= 0 && src.ConfidenceLevel !== null ? src.ConfidenceLevel : ReportConfidence.LOW;
    report.imageUrl = src.Image ? src.Image.startsWith('http') ? src.Image : `${API_URL}${src.Image}` : null;
    report.isDismissed = !!src.Dismissed;
    report.title = `${src.Message}`;
    report.message = src.Message;
    report.meta = JSON.parse(src.Meta) ? JSON.parse(src.Meta) : null;
    report.nearbyPings = src.NearbyPings ? src.NearbyPings.map(Ping.fromJson) : [];
    report.source = src.SourceType >= 0 && src.SourceType !== null ? src.SourceType : ReportSource.COMMON_KNOWLEDGE;
    report.type = RiskType.from(src.Type || 0);
    report.urgency = ReportUrgency.from(src.Urgency);
    report.videoUrl = src.Video ? src.Video.startsWith('http') ? src.Video : `${API_URL}${src.Video}` : null;
    report.area = null;
    report.dismissed = src.Dismissed ? new Date(src.Dismissed) : null;
    report.riskTypes = src.RiskTypes;
    report.countryCode = src.CountryCode;
    report.userId = src.UserId;
    report.acknowledgedBy = src.AcknowledgedBy;
    report.imageRotation = src.ImageRotation;
    report.city = src.City;
    report.hsasLevel = src.Urgency;
    report.isrcCode = src.ISRCCode;
    if (src.LevelLog) {
      try {
        const items = JSON.parse(src.LevelLog) as Array<any>;
        report.levelLog = items.map(i => ({
          userId: i.UserId,
          modified: new Date(i.LevelModified),
          level: parseInt(i.Level, 10)
        }));
      } catch (err) {
        console.error(err);
        report.levelLog = [];
      }
    }

    if (report.type === RiskType.SOS && src.GroupUsers && src.GroupUsers.length > 0) {
      report.level = parseInt(src.Level, 0) || 0;
      report.responseGroups = mapResponseGroup(src.GroupUsers.find(groupUser => groupUser.ResponseGroups).ResponseGroups);
    }

    return report;
  }

  constructor() {}

  setUrgency(urgency: number): void {
    this.urgency = ReportUrgency.fromInt(urgency);
  }

  setRiskTypes(riskTypes: number): void {
    this.type = RiskType.from(riskTypes || 0);
    this.riskTypes = riskTypes;
  }
  private static getPositionFromPoint(geoJson): Coordinates {
    let point: Point;

    try {
      point = JSON.parse(geoJson);
    } catch (e) {
      console.error('Error parsing GeoJson', geoJson, e);

      return null;
    }

    if(!point) {
      return {
        longitude: 0, latitude: 0
      };
    }

    const longitude = parseFloat(point.coordinates[0] as any);
    const latitude = parseFloat(point.coordinates[1]  as any);

    return {
      longitude, latitude
    };
  }
}

export function convertToParams(report: Report) {
  return {
    id: report.id,
    user_id: report.createdByUser ? report.createdByUser.id : undefined,
    urgency: report.urgency.intId,
    confidence_level: report.confidence,
    type: report.type.intId,
    source_type: report.source,
    message: report.message,
    meta: report.meta,
    image: report.imageUrl ? report.imageUrl : null,
    video: report.videoUrl ? report.videoUrl : null,
    latitude: report.location.coordinates.latitude,
    longitude: report.location.coordinates.longitude,
    // dismissed: report.dismissed instanceof Date ? report.dismissed.toUTCString() : null,
    country_code: report.countryCode,
    image_rotation: report.imageRotation,
    isrc_code: report.isrcCode
  };
}

export function dismissReport(report: Report) {
  return {
    id: report.id,
    dismissed: report.dismissed instanceof Date ? report.dismissed.toUTCString() : 0,
  };
}


function mapResponseGroup(data: any): Array<ResponseGroup> {
  const groups: ResponseGroup[] = [];
  if (data && data instanceof Array) {
    return data.map(r => ({
      id: r.Id,
      name: r.Name,
      delay: r.Delay,
      escalationOrder: r.EscalationOrder,
      members: mapDataUser({users: r.ResponseGroupsUsers})
    }));
  } else if (data.groups) {
    return [{
      id: data.Id,
      name: data.Name,
      delay: data.Delay,
      escalationOrder: data.EscalationOrder,
      members: mapDataUser({users: data.ResponseGroupsUsers})
    }];
  } else {
    return [];
  }
}

function mapDataUser(data: any): NewUser[] {
  if (data.users && data.users instanceof Array) {
    return  (data.users as Array<any>).map(obj => new NewUser().fromJson(obj.User));
  } else if (data.users) {
    return [ new NewUser().fromJson(data.users) ];
  } else {
    return [];
  }
}
