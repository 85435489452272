import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { Calendar } from './models';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  readonly crudClient = this.apiClient.getCrudClient(
    'calendarevents',
    true,
    (json) => new Calendar().fromJson(json),
    (calendar) => calendar.toParamObject(),
    'calendar'
  );

  constructor(private apiClient: ApiClientService) { }

}