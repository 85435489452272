<ngx-toasta></ngx-toasta>

<app-header
  [user]="user$ | async">
</app-header>

<div class="content-body">
  <section class="app-content container-fluid">
    <router-outlet></router-outlet>
  </section>
</div>

<app-footer *ngIf="showFooter"></app-footer>
<app-drawer></app-drawer>
