import { ServiceObject } from 'app/common/models/service-object';
import {dateFromJson, fromJson, initFromObject} from '../../../util/from-json';
import * as moment from 'moment/moment';

export class Article extends ServiceObject {

  id?: string;
  date: Date;
  countryCode: string;
  language: string;
  imageUrl: string;
  url: string;
  title: string;
  provider: string;
  providerKey: string;
  score: number;
  body?: string;
  _index?: any;
  _id?: any;

  toParamObject(): any {
    return {
      id: this.id,
      date: this.date,
      country_code: this.countryCode,
      language: this.language,
      image_url: this.imageUrl,
      title: this.title,
      provider: this.provider,
      providerKey: this.providerKey,
      score: this.score,
      body: this.body,
      url: this.url,
    };
  }

  fromJson(data: any) {
    this.id = data.id;
    this.date = data.date;
    this.countryCode = data.countryCode;
    this.language = data.language;
    this.imageUrl = data.imageUrl;
    this.title = data.title;
    this.provider = data.provider;
    this.providerKey = data.providerKey;
    this.score = data.score;
    this.body = data.body;
    this.url = data.url;
    return this;
  }
}
