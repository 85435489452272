import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../users/models/user';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
  styleUrls: ['./user-link.component.sass']
})
export class UserLinkComponent implements OnInit {

  @Input() user: User;

  constructor() { }

  ngOnInit() {

  }

}
