import { ServiceObject } from '../../common/models/service-object';
import {dateFromJson, fromJson, initFromObject} from 'util/from-json';

export class Group extends ServiceObject {

  id: string;
  customerId: string;
  name: string;
  contactUserId?: string;
  geoNameId: number;
  description: string;
  countryCode: string;
  userCount: number;
  modified: Date;
  created: Date;
  user: any;
  checkinNotifyTimezone: string;
  timezone: string;
  localTime: string;
  checkinNotifySun: boolean;
  checkinNotifyMon: boolean;
  checkinNotifyTue: boolean;
  checkinNotifyWed: boolean;
  checkinNotifyThu: boolean;
  checkinNotifyFri: boolean;
  checkinNotifySat: boolean;
  checkinNotifyTime: number;
  checkinNotifyMessage: string;
  groupUsers: any[];

  toParamObject(): any {
    return {
      id: this.id,
      name: this.name,
      customer_id: this.customerId,
      contact_user_id: this.contactUserId,
      geo_name_id: this.geoNameId,
      country_code: this.countryCode,
      description: this.description,
      checkin_notify_timezone: this.checkinNotifyTimezone,
      checkin_notify_sun: this.checkinNotifySun,
      checkin_notify_mon: this.checkinNotifyMon,
      checkin_notify_tue: this.checkinNotifyTue,
      checkin_notify_wed: this.checkinNotifyWed,
      checkin_notify_thu: this.checkinNotifyThu,
      checkin_notify_fri: this.checkinNotifyFri,
      checkin_notify_sat: this.checkinNotifySat,
      checkin_notify_time: this.checkinNotifyTime,
      checkin_notify_message: this.checkinNotifyMessage
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.name = data.Name;
    this.contactUserId = data.ContactUserId;
    this.geoNameId = data.GeoNameId;
    this.description = data.Description;
    this.countryCode = data.CountryCode;
    this.userCount = data.UserCount;
    this.modified = dateFromJson(data.Modified);
    this.created = dateFromJson(data.Created);
    this.checkinNotifyTimezone = data.CheckinNotifyTimezone;
    this.checkinNotifySun = data.CheckinNotifySun;
    this.checkinNotifyMon = data.CheckinNotifyMon;
    this.checkinNotifyTue = data.CheckinNotifyTue;
    this.checkinNotifyWed = data.CheckinNotifyWed;
    this.checkinNotifyThu = data.CheckinNotifyThu;
    this.checkinNotifyFri = data.CheckinNotifyFri;
    this.checkinNotifySat = data.CheckinNotifySat;
    this.checkinNotifyTime = data.CheckinNotifyTime;
    this.checkinNotifyMessage = data.CheckinNotifyMessage;
    this.user = data.User;
    this.groupUsers = data.GroupUsers;
    return this;
  }
}
