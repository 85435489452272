import { ServiceObject } from './service-object';
import { dateFromJson, fromJson, initFromObject } from 'util/from-json';

export class Feature extends ServiceObject {
  id: string;
  customerId: string;
  title: string;
  sortOrder: number;
  required: boolean;
  availableDaily: boolean;
  availableMonthly: boolean;
  availableAnnually: boolean;
  dailyPrice: number;
  monthlyPrice: number;
  annualPrice: number;
  created: Date;
  modified: Date;
  selected?: boolean;
  description?: string;
  settings?: any;

  toParamObject(): any {
    return {
      id: this.id,
      customer_id: this.customerId,
      title: this.title,
      sort_order: this.sortOrder,
      required: this.required,
      available_daily: this.availableDaily,
      available_monthly: this.availableMonthly,
      available_annually: this.availableAnnually,
      daily_price: this.dailyPrice,
      monthly_price: this.monthlyPrice,
      annual_price: this.annualPrice,
      created: this.created,
      modified: this.modified,
      description: this.description,
      settings: this.settings
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.title = data.Title;
    this.sortOrder = data.SortOrder;
    this.required = data.Required;
    this.availableDaily = data.AvailableDaily;
    this.availableMonthly = data.AvailableMonthly;
    this.availableAnnually = data.AvailableAnnually;
    this.dailyPrice = parseFloat(data.DailyPrice);
    this.monthlyPrice = parseFloat(data.MonthlyPrice);
    this.annualPrice = parseFloat(data.AnnualPrice);
    this.created = dateFromJson(data.Created);
    this.modified = dateFromJson(data.Modified);
    this.description = data.Description;
    this.settings = isJson(data.Settings) ? JSON.parse(data.Settings) : null;
    return this;
  }

  
}

function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
