
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
// Angular
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Models
import { Group } from '../../../groups/models/group';

// Dependencies
import { GroupsService } from '../../api/groups.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-group-picker',
  templateUrl: './group-picker.component.html',
  styleUrls: ['./group-picker.component.sass']
})
export class GroupPickerComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['SelectedGroups'] && changes['SelectedGroups'].currentValue) {
      this.addedGroups = changes['SelectedGroups'].currentValue;
    }
  }

  @Output() GroupChanged: EventEmitter<Group[]> = new EventEmitter();
  @Input() SelectedGroups: Group[];

  constructor(
    public http: HttpClient,
    public groupService: GroupsService,
    private builder: FormBuilder,
    private _sanitizer: DomSanitizer
  ) { }

  public myForm: FormGroup;

  ngOnInit() {
    this.myForm = this.builder.group({
      groups : '',
    });
  }

  public groupSearchFieldText: string;

  public addedGroups: Group[] = [];

  propagateChanges() {
    this.GroupChanged.emit(this.addedGroups);
  }

  onSubmit(value: any) {
    const group = value.group;
    this.addGroup(group);
  }

  autoCompleteListFormatter = (data: any) : SafeHtml => {
    const html = `<span>${data.name}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  private addGroup(group: Group) {
    if (group) {
      this.addedGroups.push(group);
      this.propagateChanges();
    }
    this.groupSearchFieldText = '';
  }

  public filteredGroupsObservable = (keyword: any): Observable<Group[]> => {
    if (!keyword) {
      return observableOf([]);
    }

    const addedGroupIds = this.addedGroups.map(group => group.id);

    return this.groupService.searchGroups(keyword).pipe(
    map(groups => groups.filter(group => addedGroupIds.indexOf(group.id) === -1)));

  }

  valueChanged(newVal) {
    this.addGroup(newVal);
  }

  removeGroup(addedGroup) {
    const index = this.addedGroups.findIndex((group: Group) => {
      return addedGroup === group;
    });
    this.addedGroups.splice(index, 1);
    this.propagateChanges();
  }
}
