import { ServiceObject } from './service-object';
import { LastPing } from './last-ping';


export class Device extends ServiceObject {

  appVersion: string;
  created: Date;
  customerId: string;
  deviceFingerprint: string;
  lastPing: LastPing[];
  id: string;
  mobileDeviceId: string;
  fcmToken: string;
  pushkitToken: string;
  model: string;
  modified: Date;
  osType: string;
  phoneNumber: string;
  serial: string;
  userId: string;
  active: boolean;
  notes: string;
  type: string;

  toParamObject(): any {
    return {
      customer_id: this.customerId,
      user_id: this.userId,
      phone_number: this.phoneNumber,
      device_fingerprint: this.deviceFingerprint,
      serial: this.serial,
      model: this.model,
      os_type: this.osType,
      app_version: this.appVersion,
      mobile_device_id: this.mobileDeviceId,
      fcm_token: this.fcmToken,
      pushkit_token: this.pushkitToken,
      active: this.active,
      id: this.id,
      notes: this.notes,
      type: this.type
    };
  }

  fromJson(data: any) {
    this.appVersion = data.AppVersion;
    this.created = new Date(data.Created);
    this.customerId = data.CustomerId;
    this.deviceFingerprint = data.DeviceFingerprint;
    if (data.LastPing && data.LastPing instanceof Array) {
        this.lastPing = data.LastPing.map(pingData => (new LastPing()).fromJson(pingData));
    } else {
        this.lastPing = [];
    }
    this.id = data.Id;
    this.mobileDeviceId = data.MobileDeviceId;
    this.fcmToken = data.FcmToken;
    this.pushkitToken = data.PushkitToken;
    this.model = data.Model;
    this.modified = data.Modified;
    this.osType = data.OsType;
    this.phoneNumber = data.PhoneNumber;
    this.serial = data.Serial;
    this.userId = data.UserId;
    this.notes = data.Notes;
    this.active = data.Active;
    this.type = data.Type;

    return this;
  }
}
