import { ServiceObject } from 'app/common/models/service-object';
import {dateFromJson, fromJson, initFromObject} from '../../../util/from-json';
import * as moment from 'moment/moment';

export class Calendar extends ServiceObject {

  id: string;
  customerId: string;
  title: string;
  description: string;
  geoNameId: number;
  geoJson: string;
  countryCode: string;
  startTime: Date;
  endTime: Date;
  modified: Date;
  created: Date;
  riskLevel: number;
  public: boolean;

  toParamObject(): any {
    return {
      id: this.id,
      customer_id: this.customerId,
      title: this.title,
      description: this.description,
      geo_name_id: this.geoNameId,
      geo_json: this.geoJson,
      start_time: encodeURIComponent(moment(this.startTime).utc().format('YYYY-MM-DD')),
      end_time: encodeURIComponent(moment(this.endTime).utc().format('YYYY-MM-DD')),
      country_code: this.countryCode,
      risk_level: this.riskLevel,
      public: this.public ? true : false,
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.title = data.Title;
    this.description = data.Description;
    this.geoNameId = data.GeoNameId;
    this.geoJson = data.GeoJson;
    this.countryCode = data.CountryCode;
    this.startTime = keepLocalDate(data.StartTime);
    this.endTime = keepLocalDate(data.EndTime);
    this.modified = dateFromJson(data.Modified);
    this.created = dateFromJson(data.Created);
    this.riskLevel = data.RiskLevel;
    this.public = data.Public ? true : false;
    return this;
  }
}

function keepLocalDate(dateTime: string) {
  const date = dateTime.split('T')[0];
  return moment(date).toDate();
}
