var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SessionService } from '../../session/services/session.service';
export class AuthService {
    constructor(sessionService) {
        this.sessionService = sessionService;
    }
    login(credentials) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.sessionService.login();
        });
    }
    logout() {
        localStorage.clear();
        window.location.reload();
    }
}
