import { ServiceObject } from './service-object';
import { dateFromJson, fromJson, initFromObject } from 'util/from-json';
import { Feature } from './feature';

export class Plan extends ServiceObject {

  id: string;
  title: string;
  type: string;
  featureIds: string;
  features?: any;
  sortOrder: number;
  customerId: string;
  description: string;
  discontinued: boolean;
  discontinuedReason: string;
  color: string;
  highlight: boolean;
  availableDaily: boolean;
  availableMonthly: boolean;
  availableAnnually: boolean;
  locked: boolean;
  dailyPrice: number;
  monthlyPrice: number;
  annualPrice: number;
  created: Date;
  modified: Date;


  toParamObject(): any {
    return {
      id: this.id,
      customer_id: this.customerId,
      title: this.title,
      description: this.description,
      discontinued: this.discontinued,
      discontinued_reason: this.discontinuedReason,
      features: this.features,
      color: this.color,
      highlight: this.highlight,
      available_daily: this.availableDaily,
      available_monthly: this.availableMonthly,
      available_annually: this.availableAnnually,
      locked: this.locked,
      daily_price: this.dailyPrice,
      montly_price: this.monthlyPrice,
      annual_price: this.annualPrice,
      type: this.type,
      feature_ids: this.featureIds,
      sort_order: this.sortOrder,
      created: this.created,
      modified: this.modified
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.title = data.Title;
    this.description = data.Description;
    this.discontinued = data.Discontinued;
    this.color = data.Color;
    this.discontinuedReason = data.DiscontinuedReason;
    this.availableDaily = data.AvailableDaily;
    this.availableMonthly = data.AvailableMonthly;
    this.availableAnnually = data.AvailableAnnually;
    this.locked = data.Locked;
    this.dailyPrice = data.DailyPrice;
    this.monthlyPrice = data.MonthlyPrice;
    this.annualPrice = data.AnnualPrice;
    this.features = JSON.parse(data.Features) as Feature[];
    this.type = data.Type;
    this.featureIds = data.FeatureIds;
    this.sortOrder = data.SortOrder;
    this.created = data.Created;
    this.modified = data.Modified;
    return this;
  }
}
