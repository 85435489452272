import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/guards/auth.guard';

import { CountryListComponent } from './components/country-list/country-list.component';
import { CompareCountriesComponent } from './components/compare-countries/compare-countries.component';

const routes: Routes = [
  {
    path: 'list',
    component: CountryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'compare/:countries',
    component: CompareCountriesComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AdvisoryRoutingModule { }
