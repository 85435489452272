import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {CustomEncoder} from '../util/as-http-params';

@Injectable()
export class ApiService {

  private token: string = '';
  private customerId: string = '';

  constructor(
    private http: HttpClient
  ) { }

  get(url: string, body: any = null): Observable<any> {
    return this.http
      .get<any>(url, this.getOptions(body))
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError),
      );
  }

  patch(url: string, body: any): Observable<any> {
    return this.http
      .patch(url, this.generateQueryParams(body), this.getOptions(null, true))
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError)
      );
  }

  post(url: string, body: any): Observable<any> {
    return this.http
      .post(url, this.generateQueryParams(body), this.getOptions(null, true))
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError)
      );
  }


  postJSON(url: string, body: any): Observable<any> {
    const options = this.getOptions(null, true);

    // Replace content-type header
    options.headers = options.headers
      .delete('content-type')
      .append('content-type', 'application/json; charset=utf-8');

    return this.http
      .post(url, body, options)
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError)
      );
  }

  put(url: string, body: any): Observable<any> {
    return this.http
      .put(url, this.generateQueryParams(body), this.getOptions(null, true))
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError),
      );
  }

  ['delete'](url: string): Observable<any> {
    return this.http
      .delete(url, this.getOptions())
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError),
      );
  }

  postRaw(url: string, body: any): Observable<any> {
    const options = this.getOptions(null, false);
    options.headers.append('Content-Type', 'text/plain');
    return this.http
      .post(url, body , options)
      .pipe(
        map((response: any) => this.extractData(response)),
        catchError(this.handleError),
      );
  }

  uploadFile(url: string, file: File, type: 'image' | 'video'): Promise<any> {
    return new Promise((resolve, reject) => {

        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject(xhr.response);
                }
            }
        };

        xhr.open('POST', url, true);

        const token = this.token;

        if (token) {
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        }

        const formData = new FormData();
        formData.append('data', file, file.name);
        formData.append('customer_id', this.customerId);
        formData.append('type', type);
        xhr.send(formData);
    });
  }

  private extractData(response: HttpResponse<any>): Object|Object[] {
    const body: any = response;

    // Assuming all responses should have a 'data' property
    if (!body.data) {
      throw new Error('Missing response data');
    }

    return body.data;
  }

  private handleError(response: Response) { // ErrorObservable
    let errorText = response.statusText;

    console.log(response);

    if (response.status === 401) {
      return observableThrowError('Authorization Error. Service returned 401 Unauthorized.');
    }

    const body: any = response;

    if (body && body.errors instanceof Array) {
      errorText = body.errors.map(e => e.message).join('\n');
    }

    return observableThrowError(errorText);
  }

  private getOptions(body: Object|null = null, post = false) {
    const headers = this.generateHeaders(post);

    return {
      headers: headers,
      params: this.generateQueryParams(body || {})
    };
  }

  private generateQueryParams(obj: Object): HttpParams {
    let params = new HttpParams({encoder: new CustomEncoder()});

    if (obj) {
      Object.keys(obj).forEach((k) => {
        let value = obj[k];

        if (typeof value === 'undefined') {
          return; // Skip undefined keys
        }

        if (value instanceof Date) {
          value = value.toISOString();
        }

        params = params.append(k, value);
      });
    }
    return params;
  }

  private generateHeaders(post = false): HttpHeaders {
    let headers = new HttpHeaders();

    const token = this.token;

    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }

    if (post) {
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    return headers;
  }

  public setSession(token: string, customerId: string) {
    this.token = token;
    this.customerId = customerId;
  }
}
