import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Article } from '../../../articles/models/article.interface';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.sass']
})
export class ImageSelectorComponent {

  @Input()
  public noImageURL = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg';

  @Input()
  public articles: Article[];

  @Output()
  public selected: EventEmitter<string> = new EventEmitter();

}
