import { ServiceObject } from './service-object';

export class Traveler extends ServiceObject {

  id: string;
  tripId: string;
  type: TravelerType;
  userId: string|null;
  firstName: string;
  lastName: string;
  email: string|null;
  approval: string|null;
  created: Date;
  modified: Date;

  // TODO: Remove
  // Not really part of the API Entity
  confirmationNo?: string;
  seatNo?: string;
  eTicket?: string;
  roomNo?: string;
  tmpId: string;
  name: string;

  // Formatting helpers
  get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  toParamObject(): TravelerEdit {
    return {
      id: this.id,
      trip_id: this.tripId,
      type: TravelerType[this.type],
      user_id: this.userId,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      // TODO: Trips - Having a traveler level approval makes little sense and just complicates things.
      approval: this.approval || 'WhatEven',
    };
  }

  fromJson(data: TravelerSource) {
    this.id = data.Id;
    this.tripId = data.TripId;
    this.type = data.Type === 'N/A' ? TravelerType.NotAvailable : TravelerType[data.Type];
    this.userId = data.UserId;
    this.firstName = data.FirstName;
    this.lastName = data.LastName;
    this.email = data.Email;
    this.approval = data.Approval;
    this.modified = new Date(data.Modified);
    this.created = new Date(data.Created);

    return this;
  }

}

export interface TravelerSource {
  Id: string;
  TripId: string;
  Type: string;
  UserId: string|null;
  FirstName: string;
  LastName: string;
  Approval: string|null;
  Modified: string;
  Created: string;
  Email: string|null;
}

export interface TravelerEdit {
  id: string;
  trip_id: string;
  type: string;
  user_id: string|null;
  first_name: string;
  last_name: string;
  approval: string|null;
  email: string|null;
}

export enum TravelerType {
  International = 'International',
  Domestic = 'Domestic',
  Expatriate = 'Expatriate',
  Volunteer = 'Volunteer',
  NotAvailable = 'Not Available',
}
