import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: true,
})
export class FilterByPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // No input specified
    if (!value) {
      return value;
    }

    if (!(value instanceof Array)) {
      throw new Error('Cannot filter non-array values');
    }

    // No filters specified
    if (!args) {
      return value;
    }

    // Keep truth-y values only
    const validKeys = Object.keys(args).filter(k => !!args[k]);

    // No values to filter by
    if (validKeys.length === 0) {
      return value;
    }

    return value.filter(v => {
      return validKeys // pass only keys with associated value
        .reduce((result, key) => {
          if (result) {
            return true; // Already validated by some other property, no need to keep checking
          }

          if (typeof args[key] !== 'string') {
            throw new Error(`Cannot filter using non-string value for key "${key}"`);
          }

          if (typeof v[key] === 'undefined') {
            throw new Error(`Missing field: ${key}`);
          }

          if (v[key] === null) {
            return result;
          }

          const target     = v[key].toString().toLowerCase();
          const comparison = args[key].toLowerCase();
          const included   = target.includes(comparison);

          return result || included;
      }, false);
    });
  }

}
