import { RiskType } from '../models/risk-type.interface';
import { RiskTypeSource } from '../models/risk-type-source.interface';

export const parse = (input: RiskTypeSource): RiskType => {
  return {
    id: input.Id,
    title: input.Title,
    color: input.Color || '',
    level: input.RiskLevel
  };
};

export const toApiPost = (risk: RiskType) => {
  return {
    id: risk.id,
    title: risk.title,
    color: risk.color,
    risk_level: risk.level || ''
  };
}
