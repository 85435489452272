// Angular
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Dependencies
import { SessionModule } from '../session/session.module';

// Services
import { ApiInterceptor } from './api.interceptor';
import { ApiService } from './services/api.service';
import { HttpService } from './services/http.service';

@NgModule({
  imports: [
    SessionModule,
    HttpClientModule
  ],
  providers: [
    ApiService,
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
})
export class ApiModule { }
