var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs';
import { User } from '../../users/models/user';
import { SSOService } from './sso.service';
import { ApiService } from '../../api/services/api.service';
import { environment } from '../../../environments/environment';
export class SessionService {
    constructor(ssoService, apiService) {
        this.ssoService = ssoService;
        this.apiService = apiService;
        this.user$ = new BehaviorSubject(null);
        this.ssoService.sso.on('token', () => {
            this.apiService.setSession(this.ssoService.token, this.ssoService.claims.customerId);
            return null;
        });
    }
    get url() {
        return `${environment.API_URL}/${this.version}/users`;
    }
    get version() {
        return '1.0';
    }
    login() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ssoService.login();
            const userId = this.getCurrentUserId();
            const user = yield this.fetchUser(userId);
            this.setCurrentUser(user);
            return userId;
        });
    }
    getCurrentToken() {
        return this.ssoService.token;
    }
    getCurrentCustomerId() {
        return this.ssoService.claims.customerId;
    }
    getCurrentUserId() {
        return this.ssoService.claims.userId;
    }
    getCurrentUser() {
        return this.user$.asObservable();
    }
    setCurrentUser(user) {
        this.user = user;
        this.user$.next(user);
    }
    isAuthenticated() {
        return this.ssoService.authenticated;
    }
    getUserRole() {
        return this.ssoService.claims.role;
    }
    setToken(token) { }
    setContextCustomer(id) { }
    switchCustomer(customerId) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    fetchUser(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const storageKey = `${environment.APP_NAMESPACE}:user`;
            const jsonUser = localStorage.getItem(storageKey);
            let user = null;
            try {
                if (jsonUser) {
                    user = JSON.parse(jsonUser);
                    if (typeof user !== 'object' || user.id !== userId) {
                        user = null;
                    }
                    else {
                        return user;
                    }
                }
            }
            catch (err) {
                console.error(err);
            }
            user = new User();
            const data = yield this.apiService.get(`${this.url}/${userId}`).toPromise();
            user.fromJson(data.users);
            return user;
        });
    }
}
