import { Component } from '@angular/core';
import { SessionService } from '../../../session/services/session.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent {
  customerId: string  = '';
  constructor(private sessionService: SessionService) {
    this.customerId = this.sessionService.getCurrentCustomerId();
  }
}
