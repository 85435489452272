import {Component, Input } from '@angular/core';

// 3rd party
import * as moment from 'moment/moment';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-date-link',
  templateUrl: './date-link.component.html',
  styleUrls: ['./date-link.component.sass']
})
export class DateLinkComponent {

  @Input() when: Date;

  public oldDate(date: Date) {
    const diff = moment(date).diff(moment(new Date()));
    const weeks = Math.abs(moment.duration(diff).asWeeks());

    return weeks > environment.RELATIVE_DATE_WEEKS;
  }

}
