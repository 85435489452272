import { ApiClientService } from './api-client.service';
import { Incident } from './models';
import * as i0 from "@angular/core";
import * as i1 from "./api-client.service";
export class IncidentsService {
    constructor(apiClient) {
        this.apiClient = apiClient;
        this.crudClient = this.apiClient.getCrudClient('incidents', true, (json) => new Incident().fromJson(json), (incident) => incident.toParamObject());
    }
}
IncidentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IncidentsService_Factory() { return new IncidentsService(i0.ɵɵinject(i1.ApiClientService)); }, token: IncidentsService, providedIn: "root" });
