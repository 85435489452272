/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 38, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 35, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We can't seem to find"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [["class", "hidden-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" the page you're looking for."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error Code: 404"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Here are some helpful links instead:"])), (_l()(), i1.ɵeld(13, 0, null, null, 24, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "a", [["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [["class", "ion-home text-center not-found-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "lspace"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "a", [["routerLink", "/support/center"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "div", [["class", "ion-help-circled text-center not-found-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "lspace"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support Center"])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 4, "a", [["routerLink", "/sitemap"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 0, "div", [["class", "ion-grid text-center not-found-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["class", "lspace"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sitemap"])), (_l()(), i1.ɵeld(32, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 4, "a", [["routerLink", "/support/contact"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(35, 0, null, null, 0, "div", [["class", "ion-email text-center not-found-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "span", [["class", "lspace"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(38, 0, null, null, 1, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 0, "img", [["class", "img-responsive"], ["src", "/assets/images/404d.jpg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/dashboard"; _ck(_v, 16, 0, currVal_2); var currVal_5 = "/support/center"; _ck(_v, 22, 0, currVal_5); var currVal_8 = "/sitemap"; _ck(_v, 28, 0, currVal_8); var currVal_11 = "/support/contact"; _ck(_v, 34, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).target; var currVal_1 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 22).target; var currVal_4 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 28).target; var currVal_7 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 34).target; var currVal_10 = i1.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_9, currVal_10); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i4.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
