import { share } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientConfigService } from '@navigo/navigo-services-client';
import { User } from '../../users/models/user';
import { SessionService } from '../../session/services/session.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationLayoutComponent implements OnInit {

  public user$: Observable<User> = this.sessionService.getCurrentUser().pipe(share());
  public showFooter: boolean;

  constructor(
    private sessionService: SessionService,
    private clientConfigService: ClientConfigService,
    private router: Router
  ) {
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd) {
        this.showFooter = e.url.startsWith('/auth');
      }
    });
  }

  ngOnInit(): void {
    this.clientConfigService.authToken = this.sessionService.getCurrentToken();
    this.clientConfigService.baseUri = environment.API_URL;
  }

}
