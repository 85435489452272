export interface Card {
  id: string;
  addressCity: string;
  addressCountry: string;
  addressPostalCode: string;
  addressStreet1: string;
  cardMask: string;
  cardType: string;
  gatewayReference: string;
  type: string;
  created: Date;
  cardExpiration: Date;
  isDefault: boolean;
}

export class Card  {
  constructor(
  ) {}

  id: string;
  addressCity: string;
  addressCountry: string;
  addressPostalCode: string;
  addressStreet1: string;
  cardMask: string;
  cardType: string;
  gatewayReference: string;
  type: string;
  created: Date;
  cardExpiration: Date;
  isDefault: boolean;

  toParamObject(): any {
    return {
      id: this.id,
      addressCity: this.addressCity,
      addressCountry: this.addressCountry,
      addressPostalCode: this.addressPostalCode,
      addressStreet1: this.addressStreet1,
      cardMask: this.cardMask,
      cardType: this.cardType,
      gatewayReference: this.gatewayReference,
      type: this.type,
      created: this.created,
      cardExpiration: this.cardExpiration,
      isDefault: this.isDefault
    };
  }

  fromJson(data: any) {
    this.id = data.Id;
    this.addressCity = data.AddressCity;
    this.addressCountry = data.AddressCountry;
    this.addressPostalCode = data.AddressPostalCode;
    this.addressStreet1 = data.AddressStreet1;
    this.cardMask = data.CardMask;
    this.cardType = data.CardType;
    this.gatewayReference = data.GatewayReference;
    this.type = data.Type;
    this.created = data.Created;
    this.cardExpiration = data.CardExpiration;
    this.isDefault = data.IsDefault;
    return this;
  }

}
