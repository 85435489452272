import { ServiceObject } from './service-object';
import {Address} from './address';

export class Waypoint extends ServiceObject implements Address {
  countryCode: string;
  address_city: string;
  address_postal_code: string;
  address_state: string;
  address_street1: string;
  address_street2: string;
  id: string;
  name: string;
  tripId: string;
  arrivalDate: Date;
  departureDate: Date;
  type: string;
  destinationCode: string;
  locationCode: string;
  latitude: number;
  longitude: number;
  metaData: any;
  created: Date;
  modified: Date;
  riskRating: number;
  timezone: string;
  title: string;

  toParamObject(): WaypointEdit {
    return {
      id: this.id,
      name: this.name,
      trip_id: this.tripId,
      arrival_date: this.arrivalDate.toISOString(),
      departure_date: this.departureDate.toISOString(),
      type: WaypointType[this.type],
      country_code: this.countryCode,
      destination_code: this.destinationCode,
      location_code: this.locationCode,
      latitude: this.latitude,
      longitude: this.longitude,
      meta_data: JSON.stringify(this.metaData),
      //TODO: Trips - Risk ratings managed by the UI is impractical... the should not be required by the api.
      risk_rating: this.riskRating || 3,
      timezone: this.timezone,
      title: this.title,
      address_city: this.address_city  || undefined,
      address_postal_code: this.address_postal_code  || undefined,
      address_state: this.address_state || undefined,
      address_street_1: this.address_street1 || undefined,
      address_street_2: this.address_street2 || undefined,
    };
  }

  fromJson(data: WaypointSource) {
    this.id = data.Id;
    this.name = data.Name;
    this.tripId = data.TripId;
    this.arrivalDate = new Date(data.ArrivalDate);
    this.departureDate = new Date(data.DepartureDate);
    this.type = WaypointType[data.Type];
    this.countryCode = data.CountryCode;
    this.locationCode = data.LocationCode;
    this.destinationCode = data.DestinationCode;
    this.latitude = data.Latitude;
    this.longitude = data.Longitude;
    this.modified = new Date(data.Modified);
    this.created = new Date(data.Created);
    this.riskRating = data.RiskRating;
    this.timezone = data.Timezone;
    this.title = data.Title;

    this.address_state = data.AddressState;
    this.address_postal_code = data.AddressPostalCode;
    this.address_city = data.AddressCity;
    this.address_street1 = data.AddressStreet1;
    this.address_street2 = data.AddressStreet2;

    if (data.MetaData) {
      try {
        this.metaData = JSON.parse(data.MetaData);
      } catch (e) {
        console.log('Could not parse Waypoint metadata as JSON', data);
      }
    }

    return this;
  }

}

export interface WaypointSource {
  AddressCity: string;
  AddressPostalCode: string;
  AddressState: string;
  AddressStreet1: string;
  AddressStreet2: string;
  ArrivalDate: string;
  CountryCode: string;
  Created: string;
  DepartureDate: string;
  DestinationCode: string;
  Id: string;
  Latitude: number;
  LocationCode: string;
  Longitude: number;
  MetaData: string;
  Modified: string;
  Name: string;
  RiskRating: number;
  Timezone: string;
  Title: string;
  TripId: string;
  Type: WaypointType;
}

export interface WaypointEdit {
  address_city: string;
  address_postal_code: string;
  address_state: string;
  address_street_1: string;
  address_street_2: string;
  arrival_date: string;
  country_code: string;
  departure_date: string;
  destination_code: string;
  id: string;
  latitude: number;
  location_code: string;
  longitude: number;
  meta_data: string;
  name: string;
  risk_rating: number;
  timezone: string;
  title: string;
  trip_id: string;
  type: string;
}

export enum WaypointType {
  Flight = 'Flight',
  Hotel = 'Hotel',
  Car = 'Car',
  Rail = 'Rail',
  Ship = 'Ship',
  Activity = 'Activity',
  Stop = 'Stop', // Just a stop, doesn't have any possible relation to a reservation in mind
}
