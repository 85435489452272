import {dateFromJson, fromJson, initFromObject} from '../../../util/from-json';

export class Alert  {
  constructor( ) {}

  id: string;
  customerId: string;
  userIds: string;
  senderId: string;
  reportId: string;
  eventId: string;
  message: string;
  description: string;
  alertLevel: number;
  resetAlertLevel: number;
  notificationType: number;
  timeToLive: number;
  cancelled: Date;
  modified: Date;
  created: Date;
  alertUsers: any[];
  alertSender: any[];
  image?: string;

  fromJson(data: any) {
    this.id = data.Id;
    this.customerId = data.CustomerId;
    this.userIds = data.UserIds;
    this.senderId = data.SenderId;
    this.reportId = data.ReportId;
    this.eventId = data.EventId;
    this.cancelled = data.Cancelled;
    this.message = data.Message;
    this.description = data.Description;
    this.alertLevel = data.AlertLevel;
    this.resetAlertLevel = data.ResetAlertLevel;
    this.notificationType = data.NotificationType;
    this.timeToLive = data.TimeToLive;
    this.created = data.Created;
    this.image = data.Image;
    this.alertUsers = (data.AlertUsers || []).map((user) => {
      return user.Users;
    });
    this.alertSender = data.Sender;
    return this;
  }

}
