import { environment } from './environments/environment';

export const API_URL = environment.API_URL;
export const MAPBOX_TOKEN = environment.MAPBOX_TOKEN;
export const UPLOAD_API_URL = environment.UPLOAD_API_URL;
export const APP_NAMESPACE = environment.APP_NAMESPACE;
export const IMPACTS_URL = environment.IMPACTS_URL;
export const GDACS_URL = environment.GDACS_URL;
export const USGS_URL = environment.USGS_URL;
export const IA_TERROR = environment.IA_TERROR;
export const IA_FOOD = environment.IA_FOOD;
export const IA_INDUSTRIAL = environment.IA_INDUSTRIAL;
export const IA_JOURNALISTS = environment.IA_JOURNALISTS;
export const IA_KIDNAPPING = environment.IA_KIDNAPPING;
export const IA_SPORTS = environment.IA_SPORTS;
export const IA_TRAFFICKING = environment.IA_TRAFFICKING;
export const IA_TRAVEL = environment.IA_TRAVEL;
export const IA_DISEASE = environment.IA_DISEASE;
export const IA_CHURCH = environment.IA_CHURCH;
export const IA_AVIATION = environment.IA_AVIATION;
export const LIVEUA = environment.LIVEUA;
export const STATS_API_URL = environment.STATS_API_URL;
export const API_POLLING_URL = environment.API_POLLING_URL;
