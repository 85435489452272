
/**
 * Enum-like class
 *
 * TODO, update names according to:
 * https://github.com/navigo-app/spec/blob/master/models/Reports.md#type
 */

export class RiskType {

  private static VALUES_BY_INT: { [intId: number]: RiskType } = {};

  public static CHECK_IN = new RiskType(0, '', 'Check-In');
  public static GENERAL_THREATS_RISKS = new RiskType(1, '', 'General Threats or Risks'); // TODO: name?
  public static UPCOMING_PROTESTS = new RiskType(2, 'unrest2.svg', 'Upcoming Protests');
  public static FOOD_WATER_SHORTAGES = new RiskType(3, '', 'Food or water shortages');
  public static SEVERE_WEATHER = new RiskType(4, 'weather.svg', 'Weather');
  public static DISEASE_CONTAGION = new RiskType(5, 'biohazard.svg', 'Disease or Contagion');
  public static CHEMICAL_TOXIC = new RiskType(6, 'biohazard.svg', 'Chemical or Toxic');
  public static FIRE = new RiskType(7, '', 'Fire');
  public static HEALTH_ALERT = new RiskType(8, 'biohazard.svg', 'Health Alert');
  public static TRAVEL_ACCIDENT = new RiskType(9, 'travelwarning.svg', 'Travel Alerts');
  public static ACTIVE_SHOOTER = new RiskType(10, '', 'Active Shooter');
  public static ACTIVE_TERROR_ATTACK = new RiskType(11, 'terrorism.svg', 'Terrorism');
  public static ACTIVE_RIOT = new RiskType(12, '', 'Active Riot');
  public static RELIGIOUS_ATTACK = new RiskType(13, '', 'Religious Attack');
  public static EXPULSION = new RiskType(14, '', 'Expulsion');
  public static SOS = new RiskType(31, '', 'SOS');

  public static fromInt(intId: number): RiskType {
    return RiskType.VALUES_BY_INT[intId];
  }

  public static from(id: number): RiskType {
    return RiskType.fromInt(<number>id); // just redirect for now
  }


  private constructor(
    public intId,
    public imageFile,
    public name,
  ) {
    RiskType.VALUES_BY_INT[intId] = this;
  }

}

export interface RiskTypeSummary {
  riskType: RiskType;
  count: number;
}

/**
 * Enum-like class
 *
 * https://docs.google.com/spreadsheets/d/1jvMLyY1ztZw3uycELPR_UmDkQqsT-vBvPiRnwsjnmQA/edit?ts=591cf736#gid=0
 *
 * */
export class RiskLevelContext {

  private static VALUES_BY_NAME: { [stringId: string]: RiskLevelContext } = {};

  public static REPORTS =
    new RiskLevelContext('REPORTS');

  public static IMPACTS =
    new RiskLevelContext('IMPACTS');

  public static ALERTS =
    new RiskLevelContext('ALERTS');

  public static ISSUES =
    new RiskLevelContext('ISSUES');

  public static RISK =
    new RiskLevelContext('RISK');

  public static from(name: string): RiskLevelContext {
    return RiskLevelContext.VALUES_BY_NAME[name];
  }

  private constructor(
    public name: string
  ) {
    RiskLevelContext.VALUES_BY_NAME[name] = this;
  }

}

export const RiskTypesList: RiskType[] = [
  RiskType.CHECK_IN,
  RiskType.GENERAL_THREATS_RISKS,
  RiskType.UPCOMING_PROTESTS,
  RiskType.FOOD_WATER_SHORTAGES,
  RiskType.SEVERE_WEATHER,
  RiskType.DISEASE_CONTAGION,
  RiskType.CHEMICAL_TOXIC,
  RiskType.FIRE,
  RiskType.HEALTH_ALERT,
  RiskType.TRAVEL_ACCIDENT,
  RiskType.ACTIVE_SHOOTER,
  RiskType.ACTIVE_TERROR_ATTACK,
  RiskType.ACTIVE_RIOT,
  RiskType.RELIGIOUS_ATTACK,
  RiskType.EXPULSION,
  RiskType.SOS
];
