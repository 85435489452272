import {Location} from '../location/location.interface';
import {PersonActivityType} from './user-activity-type.interface';
import {dateFromJson, fromJson, initFromObject, parseArray} from 'util/from-json';
import {Device, DeviceFromApi} from '../device/device.interface';
import {User} from '../user/user.interface';

export interface PingFromApi {

  Devices: DeviceFromApi[];
  BatteryLevel: number;
  CellSignalStrength: number;
  AwarenessActivity: number;
  Created: string;
  City: string;
  Distance: number;
  Country: string;
  Longitude: string; // TODO: should be number?
  Latitude: string;  // TODO: should be number?
}

export class Ping {

  when: Date;
  activityType?: PersonActivityType;
  location?: Location;
  batteryPercent?: number;
  signalPercent?: number;
  isCharging?: boolean;
  isSafe?: boolean;
  distance?: number;

  /** This is only used when getting pings nearby reports */
  devices?: Device[];

  getUser?(): User {
    return this.devices[0] ? this.devices[0].user : null;
  }

  public static fromJson(srcJson: PingFromApi): Ping {
    // console.log('Ping fromJson PingFromApi: srcJson: ', srcJson);
    return fromJson(srcJson, (src: PingFromApi) => new Ping({
      // when: dateFromJson(new Date('2017-01-15T15:40:18.403Z')), /* FIXME: check - last time it did not exist */
      when: dateFromJson(src.Created), /* FIXME: check - last time it did not exist */
      devices: parseArray(src.Devices, Device.fromJson),
      distance: src.Distance,
      activityType: PersonActivityType.fromInt(src.AwarenessActivity),
      // location: Location.fromJson(src.location),
      location: new Location({
        id: null, // FIXME
        name: src.City,
        coordinates: {
          latitude: parseFloat(src.Latitude),
          longitude: parseFloat(src.Longitude),
        }
  }),
      batteryPercent: src.BatteryLevel, // FIXME: check if percent
      signalPercent: src.CellSignalStrength, // FIXME: check if percent
      // isCharging: src.isCharging,
      // isSafe: src.isSafe,
    }));
  }

  public static fromJsonMock(srcJson): Ping {
    return fromJson(srcJson, (src) => new Ping({
      when: dateFromJson(src.when),
      activityType: PersonActivityType.valueOf(src.activityType),
      location: Location.fromJson(src.location),
      batteryPercent: src.batteryPercent,
      signalPercent: src.signalPercent,
      isCharging: src.isCharging,
      isSafe: src.isSafe,
      devices: [] /* FIXME */,
    }));
  }

  constructor(initFrom: Ping) {
    initFromObject(this, initFrom);
  }

}

export const EXAMPLE_PING_DRIVING_NOW = new Ping({
  when: new Date(),
  isSafe: false,
  activityType: PersonActivityType.DRIVING,
  batteryPercent: 37,
  signalPercent: 10,
  devices: [
    // new Device({
    //   user: EXAMPLE_USER_2
    // })
  ] /*FIXME*/,
});
