export interface Order {
  id: string;
  accountId: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingAddressPostalCode: string;
  billingAddressRegion: string;
  billingAddressStreet1: string;
  billingAddressStreet2: string;
  billingEmail: string;
  billingFirstName: string;
  billingLastName: string;
  billingOrganizationName: string;
  billingPhoneNumber: string;
  currency: string;
  extraParameters: any;
  number: string;
  shippingAddressCity: string;
  shippingAddressCountry: string;
  ShippingAddressPostalCode: string;
  shippingAddressRegion: string;
  shippingAddressStreet1: string;
  shippingAddressStreet2: string;
  shippingEmail: string;
  shippingLastName: string;
  shippingOrganizationName: string;
  shippingPhoneNumber: string;
  status: string;
  subAccountId: string;
  total: number;
}

export class Order  {
  constructor(
  ) {}

  id: string;
  accountId: string;
  billingAddressCity: string;
  billingAddressCountry: string;
  billingAddressPostalCode: string;
  billingAddressRegion: string;
  billingAddressStreet1: string;
  billingAddressStreet2: string;
  billingEmail: string;
  billingFirstName: string;
  billingLastName: string;
  billingOrganizationName: string;
  billingPhoneNumber: string;
  currency: string;
  extraParameters: any;
  number: string;
  shippingAddressCity: string;
  shippingAddressCountry: string;
  shippingAddressPostalCode: string;
  shippingAddressRegion: string;
  shippingAddressStreet1: string;
  shippingAddressStreet2: string;
  shippingEmail: string;
  shippingLastName: string;
  shippingOrganizationName: string;
  shippingPhoneNumber: string;
  status: string;
  subAccountId: string;
  total: number;

  toParamObject(): any {
    return {
      id: this.id,
      accountId: this.accountId,
      billingAddressCity: this.billingAddressCity,
      billingAddressCountry: this.billingAddressCountry,
      billingAddressPostalCode: this.billingAddressPostalCode,
      billingAddressRegion: this.billingAddressRegion,
      billingAddressStreet1: this.billingAddressStreet1,
      billingAddressStreet2: this.billingAddressStreet2,
      billingEmail: this.billingEmail,
      billingFirstName: this.billingFirstName,
      billingLastName: this.billingLastName,
      billingOrganizationName: this.billingOrganizationName,
      billingPhoneNumber: this.billingPhoneNumber,
      currency: this.currency,
      extraParameters: this.extraParameters,
      number: this.number,
      shippingAddressCity: this.shippingAddressCity,
      shippingAddressCountry: this.shippingAddressCountry,
      shippingAddressPostalCode: this.shippingAddressPostalCode,
      shippingAddressRegion: this.shippingAddressRegion,
      shippingAddressStreet1: this.shippingAddressStreet1,
      shippingAddressStreet2: this.shippingAddressStreet2,
      shippingEmail: this.shippingEmail,
      shippingLastName: this.shippingLastName,
      shippingOrganizationName: this.shippingOrganizationName,
      shippingPhoneNumber: this.shippingPhoneNumber,
      status: this.status,
      subAccountId: this.subAccountId,
      total: this.total
    };
  }

  fromJson(data: any) {
    this.id = data.id;
    this.accountId = data.AccountId;
    this.billingAddressCity = data.BillingAddressCity;
    this.billingAddressCountry = data.BillingAddressCountry;
    this.billingAddressPostalCode = data.BillingAddressPostalCode;
    this.billingAddressRegion = data.BillingAddressRegion;
    this.billingAddressStreet1 = data.BillingAddressStreet1;
    this.billingAddressStreet2 = data.BillingAddressStreet2;
    this.billingEmail = data.BillingEmail;
    this.billingFirstName = data.BillingFirstName;
    this.billingLastName = data.BillingLastName;
    this.billingOrganizationName = data.BillingOrganizationName;
    this.billingPhoneNumber = data.BillingPhoneNumber;
    this.currency = data.Currency;
    this.extraParameters = data.ExtraParameters;
    this.number = data.Number;
    this.shippingAddressCity = data.ShippingAddressCity;
    this.shippingAddressCountry = data.ShippingAddressCountry;
    this.shippingAddressPostalCode = data.ShippingAddressPostalCode;
    this.shippingAddressRegion = data.ShippingAddressRegion;
    this.shippingAddressStreet1 = data.ShippingAddressStreet1;
    this.shippingAddressStreet2 = data.ShippingAddressStreet2;
    this.shippingEmail = data.ShippingEmail;
    this.shippingLastName = data.ShippingLastName;
    this.shippingOrganizationName = data.ShippingOrganizationName;
    this.shippingPhoneNumber = data.ShippingPhoneNumber;
    this.status = data.Status;
    this.subAccountId = data.SubAccountId;
    this.total = data.Total;
    return this;
  }

}
