import { Point } from 'geojson';

export interface Location {
  Id: string;
  CustomerId: string;
  Title: string;
  ContactPhone?: string;
  ContactName?: string;
  ContactEmail?: string;
  ContactUserId?: string;
  ThreatLevel: number;
  RiskLevel: number;
  UserCount: number;
  IncidentCount: number;
  GeoNameId?: string;
  Location: Point;
  ZoomLevel: number;
  CountryCode: string;
  Timezone: string;
  Created?: Date;
  Modified?: Date;
}

export const parse = (input: any): Location => {
  let geoJson;
  try {
    geoJson = JSON.parse(input.Location);
  } catch (err) {
    geoJson = {};
    console.error(`Location ${input.Id} has bad GeoJson.`);
  }

  return { // Anything different from a string should be parsed
    ...input,
    ThreatLevel: parseFloat(input.ThreatLevel),
    UserCount: parseFloat(input.UserCount),
    IncidentCount: 0,
    Location: geoJson,
    RiskLevel: input.RiskLevel,
    ZoomLevel: parseFloat(input.ZoomLevel),
    Created: new Date(input.Created),
    Modified: new Date(input.Modified),
    ContactUserId: input.ContactUserId
  };
};


export const convertToParams = (input: Location): any => {
  return {
    'title': input.Title,
    'zoom_level': input.ZoomLevel,
    'geo_name_id': input.GeoNameId,
    'contact_name': input.ContactName,
    'contact_phone': input.ContactPhone,
    'contact_email': input.ContactEmail,
    'location': JSON.stringify(input.Location),
    'user_count': input.UserCount || 0,
    'threat_level': input.ThreatLevel || 0,
    'timezone': input.Timezone,
    'country_code': input.CountryCode,
    'contact_user_id': input.ContactUserId
  };
};
