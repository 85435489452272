import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Models
import { Impact } from '../../impacts/models/impact.interface';
import { Issue } from '../../common/models/issue';
import { Place } from '../../common/models/place';
import { Location } from '../../common/models/location.interface';
import { Report } from '../../../data/report/report.interface';
import { RiskType } from '../../risk-types/models/risk-type.interface';
import { User } from '../../users/models/user';
import { LastPing } from '../../common/models/last-ping';
import { UserPing } from '../models/user-ping';
import { Trip } from '../../common/models/trip';

// Parsers
import { parse as parseImpact } from '../../impacts/parsers/impact.parser';
import { parse as parseLocation } from '../../common/models/location.interface';
import { parse as parseRiskType } from '../../risk-types/parsers/risk-type.parser';


export enum EventTypes {
  PingsLatest = <any>'status.create',
  IssuesCreate = <any>'issues.create',
  ImpactsCreate = <any>'impacts.create',
  LocationsCreate = <any>'locations.create',
  LocationsDelete = <any>'locations.delete',
  ReportsCreate = <any>'reports.create',
  TripsCreate = <any>'trips.create',
  NotificationsCreate = <any>'notifications.create',
  RiskTypeCreate = <any> 'risktypes.create',
  PlacesCreate = <any> 'places.create',
  CronJson = <any> 'crons.json',
}

@Injectable()
export class RTDService {

  issuesCreate$ = new Subject<Issue>();
  pingLatest$ = new Subject<UserPing>();
  impactsCreate$ = new Subject<Impact>();
  locationsCreate$ = new Subject<Location>();
  locationsDelete$ = new Subject<{id: string}>();
  reportsCreate$ = new Subject<Report>();
  tripsCreate$ = new Subject<Trip>();
  notificationsCreate$ = new Subject<any>();
  riskTypeCreate$ = new Subject<RiskType>();
  placesCreate$ = new Subject<Place>();
  connected$ = new Subject<boolean>();
  cronJson$ = new Subject<any>();

  constructor() { }

  public next(data: {type: EventTypes, data: any}) {


    switch (data.type) {

      case EventTypes.IssuesCreate:
        this.issuesCreate$.next(new Issue().fromJson(data.data));
        break;

      case EventTypes.PingsLatest:
        const ping = this.parsePing(data.data);
        if (ping) {
          this.pingLatest$.next(ping);
        }
        break;

      case EventTypes.ImpactsCreate:
        this.impactsCreate$.next(parseImpact(data.data));
        break;

      case EventTypes.LocationsCreate:
        this.locationsCreate$.next(parseLocation(data.data));
        break;

      case EventTypes.LocationsDelete:
        this.locationsDelete$.next({id: data.data });
        break;

      case EventTypes.RiskTypeCreate:
        this.riskTypeCreate$.next(parseRiskType(data.data));
        break;

      case EventTypes.ReportsCreate:
        this.reportsCreate$.next(Report.fromJson(data.data));
        break;

      case EventTypes.TripsCreate:
        this.tripsCreate$.next(new Trip().fromJson(data.data));
        break;

      case EventTypes.NotificationsCreate:
        this.notificationsCreate$.next(data.data);
        break;

      case EventTypes.PlacesCreate:
        this.placesCreate$.next(new Place().fromJson(data.data));
        break;

      case EventTypes.CronJson:
        this.cronJson$.next(data);
        break;

      default:
        break;
    }
  }

  private parsePing(data: any) {
    const pingData: {
      Devices: Array<{
        User: User
      }>
    } = data;
    const device = pingData.Devices[0];

    if (!device) {
      return null;
    }

    const user = device.User;

    if (!user) {
      return null;
    }

    if (!data.Created) {
      data.Created = data.Timestamp;
    }

    const lastPing = new LastPing().fromJson(data);
    const userPing = new UserPing();
    Object.assign(userPing, lastPing);
    userPing.deviceId = device['Id'];
    userPing.userId = user['Id'];

    return userPing;
  }

}
