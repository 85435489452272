import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { Incident } from './models';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {
  readonly crudClient = this.apiClient.getCrudClient(
    'incidents',
    true,
    (json) => new Incident().fromJson(json),
    (incident) => incident.toParamObject()
  );

  constructor(private apiClient: ApiClientService) { }
}
