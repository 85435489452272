import { Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';

import { ImageLightboxService } from '../services/image-lightbox.service';

@Directive({
  selector: '[appImageLightbox]'
})
export class ImageLightboxDirective implements OnChanges, OnDestroy {

  @Input() appImageLightbox: string;

  private imageUrl: string;

  private activateEvent = () => this.activate();

  constructor(
    private ref: ElementRef,
    private imageLightboxService: ImageLightboxService
  ) {
    this.getImageUrl();
    this.ref.nativeElement.addEventListener('click', this.activateEvent);
    this.ref.nativeElement.style.cursor = 'pointer';
  }

  ngOnChanges() {
    this.getImageUrl();
  }

  ngOnDestroy() {
    this.ref.nativeElement.removeEventListener('click', this.activateEvent);
  }

  private getImageUrl() {
    setTimeout(() => {
      this.imageUrl = this.appImageLightbox || this.ref.nativeElement.src;
    }, 10);
  }

  private activate() {
    this.imageLightboxService.open(this.imageUrl);
  }

}
