// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NavigoServicesClientModule } from '@navigo/navigo-services-client';

// Services
import { SessionService } from './services/session.service';
import { SSOService } from './services/sso.service';

@NgModule({
  imports: [NavigoServicesClientModule]
})
export class SessionModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SessionModule,
      providers: [SessionService, SSOService],
    };
  }
}
