import { Injectable } from '@angular/core';

let baseUri: string = '';
let authToken: string = '';

@Injectable()
export class ClientConfigService {

  // Get/Sets/Global Vars because this service isn't always a singleton for some reason.. even it is ForRoot.
  get baseUri() {
    return baseUri;
  }

  set baseUri(v: string) {
    baseUri = v;
  }

  get authToken() {
    return authToken;
  }

  set authToken(v: string) {
    authToken = v;
  }

  constructor() { }
}
