import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ToastaModule } from 'ngx-toasta';
import { SharedModule } from '../../shared/shared.module';
import { HeaderModule } from '../../header/header.module';
import { SessionModule } from '../../session/session.module';
import { AdvisoryModule } from '../../advisory/advisory.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '../layout.module';

import { ApplicationLayoutComponent } from './application-layout.component';

@NgModule({
  declarations: [ApplicationLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    ToastaModule,
    SharedModule,
    HeaderModule,
    SessionModule,
    AdvisoryModule,
    NgbModule,
    LayoutModule
  ],
  exports: [
    ApplicationLayoutComponent
  ]
})
export class ApplicationLayoutModule { }
