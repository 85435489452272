var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { RiskIndexesService } from 'app/risk-indexes/services/risk-indexes.service';
import { DrawerService } from '../services/drawer.service';
import { ActivatedRoute, Router } from '@angular/router';
export class CompareCountriesComponent {
    constructor(riskIndexesService, drawerService, router, route) {
        this.riskIndexesService = riskIndexesService;
        this.drawerService = drawerService;
        this.router = router;
        this.route = route;
        this.loading = true;
        this.filterText = '';
        this.countries = [];
        this.countryCodes = [];
        this.overAllRisks = ['HazardAndExposure', 'Vulnerability', 'LackOfCopingCapacity', 'Human'];
        this.naturalDisasterRisks = ['Drought', 'Earthquake', 'TropicalCyclone', 'Tsunami'];
        this.maxPop = 1;
    }
    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['countries']) {
                this.loadCountries(params['countries']);
            }
        });
    }
    loadCountries(countries) {
        return __awaiter(this, void 0, void 0, function* () {
            this.countryCodes = countries.match(/.{1,2}/g);
            this.countryCodes.forEach(code => {
                const country = {};
                this.riskIndexesService.getFactbook(code).subscribe(result => {
                    country['factbook'] = result;
                });
                this.riskIndexesService.getInform(code).subscribe(result => {
                    country['inform'] = result;
                });
                this.riskIndexesService.getIndex(code).subscribe(result => {
                    country['index'] = result;
                });
                this.countries[code] = country;
            });
            this.loading = false;
        });
    }
    setMaxPop(pop) {
        if (!pop) {
            return;
        }
        if (pop > this.maxPop) {
            this.maxPop = pop;
        }
        return pop;
    }
    percentPop(pop) {
        return 100 * pop / this.maxPop;
    }
    inversePop(pop) {
        return 1 - (100 * pop / this.maxPop);
    }
    removeCountry(code) {
        const index = this.countryCodes.indexOf(code);
        if (index > -1) {
            this.countryCodes.splice(index, 1);
        }
    }
    loadDrawer(code) {
        this.drawerService.openDrawer(code);
    }
    splitTitle(title) {
        // custom overrides
        if (title === 'Human') {
            return 'Human Conflict Risk';
        }
        ;
        return title.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
    }
    calculateRiskLevel(level) {
        if (level > 9.0) {
            return 'critical';
        }
        if (level > 7.0) {
            return 'high';
        }
        if (level > 5.0) {
            return 'medium';
        }
        else {
            return 'lower';
        }
    }
    detectLevel(title) {
        if (!title) {
            return 'lower';
        }
        if (title.match('Level 2')) {
            return 'medium';
        }
        if (title.match('Level 3')) {
            return 'high';
        }
        if (title.match('Level 4')) {
            return 'critical';
        }
        return 'lower';
    }
    trimAdvisory(advisory) {
        if (!advisory) {
            return;
        }
        const s1 = advisory.substring(advisory.indexOf('Level'));
        return s1.trim();
    }
}
