
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../../api/services/api.service';
import { Observable } from 'rxjs';
import { Calendar } from '../../common/models/calendar';
import { API_URL } from '../../../app.constants';

@Injectable()
export class CalendarsService {

  get url() {
    return `${API_URL}/1.0/calendar`;
  }

  constructor(private apiService: ApiService) {}

  getCalendars(): Observable<Calendar[]> {
    return this.apiService.get(`${this.url}`).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  getCountryCalendars(country: string): Observable<Calendar[]> {
    return this.apiService.get(`${this.url}/upcoming?country_code=${country}`).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  getUserCalendar(userId: string): Observable<Calendar[]> {
    return this.apiService.get(`${this.url}?user_id=${userId}`).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  getCalendar(id: string) {
    return this.apiService.get(`${this.url}/${id}`).pipe(
    map(r => this.mapDataToCalendar(r)[0]));
  }

  createCalendar(calendar: Calendar) {
    debugger;
    return this.apiService.post(`${this.url}`, calendar.toParamObject()).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  updateCalendar(calendar: Calendar) {
    return this.apiService.patch(`${this.url}/${calendar.id}`, calendar.toParamObject()).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  deleteCalendar(id: string) {
    return this.apiService.delete(`${this.url}/${id}?id=${id}`).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  searchCalendars(query: string) {
    return this.apiService.get(`${this.url}/search?query=${query}`).pipe(
    map(r => this.mapDataToCalendar(r)));
  }

  private mapDataToCalendar(data: any): Calendar[] {
    if (data.calendarevents && data.calendarevents instanceof Array) {
      return  (data.calendarevents as Array<any>).map(obj => new Calendar().fromJson(obj));
    } else if (data.calendarevents) {
      return [ new Calendar().fromJson(data.calendarevents) ];
    } else {
      return [];
    }
  }

}
