import {fromJson, initFromObject, fieldOrNull} from 'util/from-json';
import { Coordinates } from './coordinates.interface';

export class Location {
  id: string;
  name: string;
  coordinates?: Coordinates;
  zoom?: number;
  timezone?: string;
  country?: string;

  public static fromJson(srcJson): Location {
    return fromJson(srcJson, (src) => {
      // debugger;
      return new Location({
        id: src.id,
        name: src.name,
        country: src.country,
        timezone: src.timezone, // See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        coordinates: src.coordinates || null,
      });
    });
  }

  constructor(initFrom?: Location) {
    if (initFrom) {
      initFromObject(this, initFrom);
    }
  }

}
