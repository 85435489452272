import { Injectable } from '@angular/core';
import {ToastaConfig, ToastaService, ToastOptions} from 'ngx-toasta';

@Injectable()
export class ToastService {

  private options: ToastOptions = new ToastOptions();

  constructor(
    private toastService: ToastaService,
    private toastConfig: ToastaConfig,
  ) {
    this.toastConfig.theme = 'bootstrap';
    this.toastConfig.timeout = 5000;
    this.toastConfig.limit = 10;

    this.options.title = 'Navigo';
  }

  public error(message, title = this.options.title) {
    return this.toastService.error(this.getOptions(title, message));
  }

  public info(message, title = this.options.title) {
    return this.toastService.info(this.getOptions(title, message));
  }

  public warning(message, title = this.options.title, onClick = () => null) {
    return this.toastService.warning(this.getOptions(title, message, onClick));
  }

  public success(message, title = this.options.title) {
    return this.toastService.success(this.getOptions(title, message));
  }

  public custom(type: 'warning', options) {

  }

  private getOptions(title: string, message, onClick = () => null) {
    const a =  {
      ...this.options,
      title,
      msg: message,
      onClick: onClick
    };

    console.log(a);

    return a;
  }
}
