// Angular
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DrawerService {
  private countryCode$: Subject<string> = new Subject();
  private section$: Subject<string> = new Subject();

   public openDrawer(countryCode: string, section?: string) {
    if (!countryCode || countryCode === 'Unknown') { return; }
    console.log('opening drawer for' + countryCode);
     this.countryCode$.next(countryCode);
     if (section) { this.section$.next(section); }
   }

   public getCurrentCountryCode(): Observable<string> {
    return this.countryCode$.asObservable();
   }

   public getCurrentSection(): Observable<string> {
    return this.section$.asObservable();
   }

}
