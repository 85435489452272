// Angular
import { Injectable } from '@angular/core';
import { ClientConfigService } from '@navigo/navigo-services-client';
import { environment } from '../../../environments/environment';
import { SSOClient } from '@navigo-app/sso-client';

@Injectable()
export class SSOService {

  public sso: SSOClient;
  
  authenticated: boolean;
  token: string;

  claims: {
    userId: string;
    customerId: string;
    firstName: string;
    lastName: string;
    role: number,
    email: string;
  } = {} as any;

  constructor(private servicesConfig: ClientConfigService) {
    this.sso = new SSOClient(environment.SSO_CONFIG);

    (this.sso as any).on('authSuccess', () => {
      this.authenticated = true;
    });

    (this.sso as any).on('authLogout', () => {
      this.authenticated = false;
    });

    (this.sso as any).on('authError', () => {
      this.authenticated = false;
      return false;
    });

    (this.sso as any).on('token', () => {
      this.fetchSessionData();  
    });
  }

  async login() {
    await this.sso.start();
    this.fetchSessionData();
  }

  public isAuthenticated(): boolean {
    return this.sso.getToken() ? true : false;
  }

  private fetchSessionData() {
    this.authenticated = this.sso.getToken() ? true : false;
    this.token = this.sso.getToken();
    this.claims.userId = this.sso.getTokenClaim('data.user');
    this.claims.customerId = this.sso.getTokenClaim('data.customer');
    this.claims.firstName = this.sso.getTokenClaim('given_name');
    this.claims.lastName = this.sso.getTokenClaim('family_name');
    this.claims.role = this.sso.getTokenClaim('data.permissions.role');
    this.claims.email = this.sso.getTokenClaim('email');
    this.servicesConfig.authToken = this.token;
  }

}
