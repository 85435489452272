import { Component, Input } from '@angular/core';
import { Location } from 'data/location/location.interface';
import { CountriesService } from '../../../countries/services/countries.service';
import { latitude, longitude } from 'geolib';

@Component({
  selector: 'app-location-link',
  templateUrl: './location-link.component.html',
  styleUrls: ['./location-link.component.sass']
})
export class LocationLinkComponent {

  @Input() location: Location;
  @Input() reverseCords = false;
  constructor(
    protected countriesService: CountriesService
  ) { }

  getCountryName(code: string): string {
    return this.countriesService.getCountryName(code);
  }

  getLocation(location: Location) {
    if (!!location && location.coordinates) {
      if (location.coordinates.latitude) {
        return [location.coordinates.latitude, location.coordinates.longitude].join(',');
      } else if (location.coordinates[1]) {
        return [location.coordinates[1], location.coordinates[0]].join(',');
      }
    } else {
      return '0,0';
    }
  }

}
